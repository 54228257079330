import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import 'Config/reactotron'
import Main from 'Containers/Main'
import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import createStore from 'Redux/index'
import 'Styles/main.css'
import HttpsRedirect from 'react-https-redirect'

const { store } = createStore()

Sentry.init({
  dsn: 'https://11c2600257a14c9f80b6593130f02631@o585271.ingest.sentry.io/5738186',
  integrations: [new Integrations.BrowserTracing()],
  environment:
    process.env.NODE_ENV === 'development' ? 'development' : 'production',
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
})

function App(props) {
  Sentry.setContext('shop', props)

  return (
    <HttpsRedirect>
      <Provider store={store}>
        <Router>
          <Main props={props} />
        </Router>
      </Provider>
    </HttpsRedirect>
  )
}

export default App
