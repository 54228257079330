import CryptoJS from 'crypto-js'
import store from 'store'

export const UpdateImage = async (productId, image) => {
  const authData = store.get('authData')
  const formData = new FormData()

  formData.append('image', image, image.name)

  // 3) confirm & create user with backend
  let usersPostOptions = {
    method: 'POST',
    headers: {
      ...authData,
    },
    body: formData,
  }
  let res = await fetch(
    `${process.env.REACT_APP_BASE_URL}/dashboard/products/${productId}/upsert_image`,
    usersPostOptions
  )
  if (res.status !== 200) return res
  return await res.json()
}

export const UpLoadThemeImage = async (image) => {
  const authData = store.get('authData')
  const formData = new FormData()

  formData.append('image', image, image.name)

  // 3) confirm & create user with backend
  let usersPostOptions = {
    method: 'POST',
    headers: {
      ...authData,
    },
    body: formData,
  }
  let res = await fetch(
    `${process.env.REACT_APP_BASE_URL}/dashboard/settings/upsert_image`,
    usersPostOptions
  )
  if (res.status !== 200) return res
  return await res.json()
}

export const RemoveImage = async (productId) => {
  const authData = store.get('authData')
  let usersPostOptions = {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...authData,
    },
  }

  let res = await fetch(
    `${process.env.REACT_APP_BASE_URL}/dashboard/products/${productId}/destroy_image`,
    usersPostOptions
  )
  return res
}
