import PropTypes from 'prop-types'
import React from 'react'

export default function CardHeading(props) {
  return (
    <div className="max-w-7xl mx-auto md:flex md:items-center md:justify-between">
      {props.leftSection && <div>{props.leftSection}</div>}
      <h3 className="text-lg leading-6 font-medium text-gray-900">
        {props.title}
      </h3>
      {props.subTitle && (
        <p className="mt-1 max-w-2xl text-sm text-gray-500">{props.subTitle}</p>
      )}
      <div className="mt-4 flex md:mt-0 md:ml-4">{props.rightSection}</div>
    </div>
  )
}

CardHeading.propTypes = {
  rightSection: PropTypes.any,
  subTitle: PropTypes.string,
  title: PropTypes.string,
}
