import React, { useState, useCallback, useEffect } from 'react'
import Modal from 'Components/Modal'
import Toast from 'Components/Toast'
import Spinner from 'Components/Spinner'
import { useSelector, useDispatch } from 'react-redux'
import ThinkificAction from 'Redux/ThinkificRedux'
import ServiceActions from 'Redux/ServiceRedux'
import CheckBoxGroup from 'Components/ChecboxGroup'
import store from 'store'

function AllCoursesModal(props) {
  const dispatch = useDispatch()
  const [products, setProducts] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectedProducts, setSelectedProducts] = useState([])
  const [showActivateToast, setShowActivateToast] = useState(false)

  useEffect(() => {
    if (props.showModal) {
      let thinkificSubdomain = store.get('thinkific_subdoamin')
      queryAppointment({ subdomain: thinkificSubdomain })
    }
  }, [props.showModal])

  const queryAppointment = (params = {}) => {
    dispatch(ThinkificAction.thinkificListRequest(params))
  }

  const coursesFetching = useSelector((state) => state.thinkific.listFetching)
  const coursesList = useSelector((state) => state.thinkific.list)
  const upsertFetching = useSelector((state) => state.thinkific.upsertFetching)

  useEffect(() => {
    if (coursesList.length > 0) {
      setProducts(coursesList)
    }
  }, [coursesList])

  const handleClose = useCallback(() => {
    props.setShowModal(false)
  }, [props])

  const servicesList = useSelector((state) => state.service.list)

  const limitReached = servicesList.length + selectedProducts.length > 1

  const handleSave = useCallback(() => {
    let prod = products.filter((p) => {
      return selectedProducts.includes(p.slug)
    })
    let params = {
      products: prod.map((s) => ({
        name: s.name,
        product_uuid: s.slug,
      })),
    }
    dispatch(
      ThinkificAction.thinkificUpsertRequest(params, () => {
        dispatch(ServiceActions.servicesListRequest())
        handleClose()
      })
    )
  }, [selectedProducts])

  return (
    <div>
      {showActivateToast && (
        <Toast
          content="Products activated successfully"
          onDismiss={() => setShowActivateToast(false)}
        />
      )}
      <Modal
        showModal={props.showModal}
        onClose={handleClose}
        title={props.title}
        primaryButton={{
          title: 'Save',
          onClick: handleSave,
          disabled: selectedProducts.length == 0 || limitReached,
          loading: upsertFetching,
        }}
        childrenClassName="p-0"
      >
        {coursesFetching ? (
          <div style={{ width: 300, height: 300 }}>
            <Spinner />
          </div>
        ) : products.length > 0 ? (
          <>
            <CheckBoxGroup
              selectedValues={selectedProducts}
              options={products.map((p) => {
                return { value: p.slug, label: p.name }
              })}
              onChange={(values) => {
                setSelectedProducts(values)
              }}
            />
            {limitReached && (
              <div>
                <p
                  style={{
                    fontSize: 14,
                    color: '#637381',
                    paddingLeft: 20,
                    paddingRight: 20,
                    marginTop: 20,
                    marginBottom: 20,
                  }}
                >
                  Please upgrade to add more courses from thinkific
                </p>
              </div>
            )}
          </>
        ) : (
          <div
            style={{
              display: 'flex',
              height: 300,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <p
              style={{
                fontSize: 16,
                color: '#637381',
                paddingLeft: 20,
                paddingRight: 20,
              }}
            >
              Uh-Oh! Looks like you do not have course. Please add courses in
              thinkific
            </p>
          </div>
        )}
      </Modal>
    </div>
  )
}

export default AllCoursesModal
