import React, { useEffect, useRef } from 'react'
import Litepicker from 'litepicker'
import 'litepicker/dist/css/litepicker.css'
import store from 'store'

const DatePicker = ({
  selected,
  onChange,
  nextMonthButtonLabel,
  previousMonthButtonLabel,
  inline = true,
}) => {
  const pickerRef = useRef(null)
  const settingsData = store.get('userData')

  useEffect(() => {
    const picker = new Litepicker({
      element: pickerRef.current,
      startDate: selected,
      inlineMode: inline,
      buttonText: {
        nextMonth: nextMonthButtonLabel,
        previousMonth: previousMonthButtonLabel,
      },
      lang:
        settingsData.shop.shopify_domain === 'amedcine.myshopify.com'
          ? 'fr-FR'
          : 'en-US',
      setup: (picker) => {
        picker.on('selected', (date1) => {
          onChange(date1.dateInstance)
        })
      },
    })

    return () => picker.destroy()
  }, [
    selected,
    onChange,
    nextMonthButtonLabel,
    previousMonthButtonLabel,
    inline,
  ])

  return (
    <div
      id="litepicker"
      ref={pickerRef}
      className="exclude-translation notranslate"
    ></div>
  )
}

export default DatePicker
