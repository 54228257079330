import Checkbox from 'Components/Checkbox'
import Input from 'Components/Input'
import RadioButton from 'Components/RadioButton'
import Select from 'Components/Select'
import React from 'react'

function AnswerCustomFields(props) {
  const changeValue = (c, value) => {
    let tempCfV = { ...props.customFieldsValues }
    tempCfV[c.id] = value
    props.setCustomFieldsValues(tempCfV)
  }

  const checkAllFieldsEntered = () => {
    if (!!props.customFields) {
      let rf = props.customFields.filter((c) => {
        return c.required
      })

      if (rf.length > 0) {
        let entered = true
        rf.map((r) => {
          if (!props.customFieldsValues[r.id]) {
            entered = false
          }
        })
        props.setAllFieldsEntered(entered)
      } else {
        props.setAllFieldsEntered(true)
      }
    }
  }

  checkAllFieldsEntered()

  const getViewByType = (c) => {
    let label = `${c.label} ${c.required ? `*` : ''}`
    switch (c.cf_type) {
      case 'text':
        return (
          <Input
            label={label}
            onChange={(value) => {
              changeValue(c, value)
            }}
            value={props.customFieldsValues[c.id]}
          />
        )
      case 'email':
        return (
          <Input
            label={label}
            onChange={(value) => {
              changeValue(c, value)
            }}
            value={props.customFieldsValues[c.id]}
          />
        )
      case 'dropdown':
        return (
          <Select
            label={label}
            options={[
              { label: '', value: null },
              ...c.options.map((o) => ({ label: o, value: o })),
            ]}
            onChange={(value) => {
              changeValue(c, value)
            }}
            value={props.customFieldsValues[c.id]}
          />
        )
      case 'radio':
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {/* <Label>{label}</Label> */}
            <p>{label}</p>
            {c.options.map((o, i) => {
              return (
                <RadioButton
                  label={o}
                  checked={props.customFieldsValues[c.id] === o}
                  id={`${o}_${c.id}`}
                  name={`${o}_${c.id}`}
                  onChange={(_, newValue) => {
                    console.log('newValue', newValue)
                    let values = newValue.split('_')
                    if (values.length > 0) {
                      let finalValue = values[0]
                      changeValue(c, finalValue)
                    }
                  }}
                />
              )
            })}
          </div>
        )
      case 'multi-select':
        return (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {/* <Label>{label}</Label> */}
            <p>{label}</p>
            {c.options.map((o) => {
              let checked = false
              if (props.customFieldsValues[c.id]) {
                let arr = props.customFieldsValues[c.id].split(',')
                checked = !!arr.find((a) => a === o)
              }

              return (
                <Checkbox
                  label={o}
                  checked={checked}
                  onChange={(newChecked) => {
                    let values = ''
                    if (newChecked) {
                      if (!!props.customFieldsValues[c.id]) {
                        values = `${props.customFieldsValues[c.id]},${o}`
                      } else {
                        values = o
                      }
                    } else {
                      let arr = props.customFieldsValues[c.id].split(',')
                      values = arr.filter((a) => a !== o).join(',')
                    }
                    changeValue(c, values)
                  }}
                />
              )
            })}
          </div>
        )
    }
  }

  return (
    <div>
      {!props.allFieldsEntered && (
        <p style={{ margin: props.fromView ? '20px 0px' : 20, color: 'red' }}>
          Please enter all the fields(You can click on next if you want to
          skip).
        </p>
      )}
      {!!props.customFields &&
        props.customFields.map((c) => {
          return (
            <div style={{ margin: props.fromView ? '20px 0px' : 20 }}>
              {getViewByType(c)}
            </div>
          )
        })}
    </div>
  )
}

export default AnswerCustomFields
