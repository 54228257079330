import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'
import {
  listRequest,
  listSuccess,
  listFailure,
  detailRequest,
  detailSuccess,
  detailFailure,
  deleteRequest,
  createRequest,
} from './genericReducers'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  bookingsListRequest: ['params', 'listDataOffset'],
  bookingsListSuccess: ['list'],
  bookingsListFailure: ['listError'],

  bookingsCalendarListRequest: ['params'],
  bookingsCalendarListSuccess: ['calendarList'],
  bookingsCalendarListFailure: ['calendarListError'],

  bookingsConflictsListRequest: ['params'],
  bookingsConflictsListSuccess: ['list'],
  bookingsConflictsListFailure: ['listError'],

  bookingsDetailRequest: ['id', 'cancelled'],
  bookingsDetailSuccess: ['detailData'],
  bookingsDetailFailure: ['detailError'],

  bookingsCancelRequest: ['params', 'callback'],
  bookingsCancelSuccess: ['data'],
  bookingsCancelFailure: ['error'],

  bookingsRescheduleRequest: ['params', 'callback'],
  bookingsRescheduleSuccess: ['data'],
  bookingsRescheduleFailure: ['error'],

  bookingsCancelListRequest: ['params'],
  bookingsCancelListSuccess: ['list'],
  bookingsCancelListFailure: ['listError'],

  refundAmount: ['params', 'callback'],

  conflictsRequest: ['params', 'listDataOffset'],
  conflictsSuccess: ['list'],
  conflictsFailure: ['listError'],

  bookingsWaitlistRequest: ['params', 'listDataOffset'],
  bookingsWaitlistSuccess: ['list'],
  bookingsWaitlistFailure: ['listError'],

  bookingsSubscriptionsListRequest: ['params'],
  bookingsSubscriptionsListSuccess: ['list'],
  bookingsSubscriptionsListFailure: ['listError'],

  bookingsSubscriptionRequest: ['id'],
  bookingsSubscriptionSuccess: ['detailData'],
  bookingsSubscriptionFailure: ['detailError'],

  updateSubscriptionDetails: ['params', 'callback'],

  bookingsSubscriptionCancelRequest: ['id', 'callback'],
  bookingsSubscriptionCancelSuccess: ['cancelFetching'],
  bookingsSubscriptionCancelFailure: ['cancelError'],

  deleteConflict: ['id', 'callback'],

  bookingsByCustomer: ['params', 'callback'],

  connectBookingToSubscription: ['params', 'callback'],

  bookingsKeyReset: ['key'],

  bookingsEditRequest: ['id', 'params', 'callback'],
  bookingsEditSuccess: ['data'],
  bookingsEditFailure: ['error'],

  bookingsRestoreRequest: ['id', 'params', 'callback'],
  bookingsRestoreSuccess: ['data'],
  bookingsRestoreFailure: ['error'],

  bookingNoteCreateRequest: ['id', 'params', 'callback'],
  bookingNoteDeleteRequest: ['id', 'params', 'callback'],

  bookingsSubscriptionPlansRequest: ['params'],
  bookingsSubscriptionPlansSuccess: ['list'],
  bookingsSubscriptionPlansFailure: ['listError'],

  generateBookingLink: ['params', 'callback'],

  updateCustomer: ['id', 'params', 'callback'],
  createCustomer: ['params', 'callback'],

  createManualSubscription: ['params', 'callback'],

  bookingsActivitiesListRequest: ['params'],
  bookingsActivitiesListSuccess: ['activitiesList'],
  bookingsActivitiesListFailure: ['activitiesListError'],

  bookingsActivitiesCancelledListRequest: ['params'],
  bookingsActivitiesCancelledListSuccess: ['activitiesCancelledList'],
  bookingsActivitiesCancelledListFailure: ['activitiesCancelledListError'],
})

export const BookingTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  list: [],
  listFetching: null,
  listError: null,
  listDataOffset: 0,

  calendarList: [],
  calendarListFetching: null,
  calendarListError: null,

  detailData: {},
  detailError: null,
  detailFetching: false,

  cancelError: null,
  cancelFetching: false,

  activitiesList: [],
  activitiesListFetching: null,
  activitiesListError: null,

  activitiesCancelledList: [],
  activitiesCancelledListFetching: null,
  activitiesCancelledListError: null,
})

/* ------------- Reducers ------------- */

export const reset = (state) => state.merge(INITIAL_STATE)

export const resetBasedOnKey = (state, { key }) => {
  return state.merge({
    [key]: INITIAL_STATE[key],
  })
}

export const cancelRequest = (state) => {
  return state.merge({ cancelFetching: true, cancelError: null })
}

export const cancelSuccess = (state, { data }) => {
  return state.merge({ data, cancelFetching: false })
}

export const cancelFailure = (state, { cancelError }) => {
  return state.merge({ cancelError, cancelFetching: false })
}

export const calendarListRequest = (state, { calendarListParams }) => {
  return state.merge({
    calendarListParams,
    calendarListFetching: true,
    calendarListError: null,
  })
}

export const calendarListSuccess = (state, { calendarList }) => {
  return state.merge({
    calendarList,
    calendarListFetching: false,
  })
}

export const calendarListFailure = (state, { calendarListError }) => {
  return state.merge({ calendarListError, calendarListFetching: false })
}
export const activitiesListRequest = (state, { activitiesListParams }) => {
  return state.merge({
    activitiesListParams,
    activitiesListFetching: true,
    activitiesListError: null,
  })
}

export const activitiesListSuccess = (state, { activitiesList }) => {
  return state.merge({
    activitiesList,
    activitiesListFetching: false,
  })
}

export const activitiesListFailure = (state, { activitiesListError }) => {
  return state.merge({ activitiesListError, activitiesListFetching: false })
}
export const activitiesCancelledListRequest = (
  state,
  { activitiesCancelledListParams }
) => {
  return state.merge({
    activitiesCancelledListParams,
    activitiesCancelledListFetching: true,
    activitiesCancelledListError: null,
  })
}

export const activitiesCancelledListSuccess = (
  state,
  { activitiesCancelledList }
) => {
  return state.merge({
    activitiesCancelledList,
    activitieCancelledsListFetching: false,
  })
}

export const activitiesCancelledListFailure = (
  state,
  { activitiesCancelledListError }
) => {
  return state.merge({
    activitiesCancelledListError,
    activitiesCancelledListFetching: false,
  })
}

/* ------------- Selectors ------------- */

export const BookingSelectors = {
  listDataOffset: (state) => state.booking.listDataOffset,
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.BOOKINGS_LIST_REQUEST]: listRequest,
  [Types.BOOKINGS_LIST_SUCCESS]: listSuccess,
  [Types.BOOKINGS_LIST_FAILURE]: listFailure,

  [Types.BOOKINGS_CALENDAR_LIST_REQUEST]: calendarListRequest,
  [Types.BOOKINGS_CALENDAR_LIST_SUCCESS]: calendarListSuccess,
  [Types.BOOKINGS_CALENDAR_LIST_FAILURE]: calendarListFailure,

  [Types.BOOKINGS_DETAIL_REQUEST]: detailRequest,
  [Types.BOOKINGS_DETAIL_SUCCESS]: detailSuccess,
  [Types.BOOKINGS_DETAIL_FAILURE]: detailFailure,

  [Types.BOOKINGS_CANCEL_REQUEST]: cancelRequest,
  [Types.BOOKINGS_CANCEL_SUCCESS]: cancelSuccess,
  [Types.BOOKINGS_CANCEL_FAILURE]: cancelFailure,

  [Types.BOOKINGS_RESCHEDULE_REQUEST]: cancelRequest,
  [Types.BOOKINGS_RESCHEDULE_SUCCESS]: cancelSuccess,
  [Types.BOOKINGS_RESCHEDULE_FAILURE]: cancelFailure,

  [Types.BOOKINGS_CANCEL_LIST_REQUEST]: listRequest,
  [Types.BOOKINGS_CANCEL_LIST_SUCCESS]: listSuccess,
  [Types.BOOKINGS_CANCEL_LIST_FAILURE]: listFailure,

  [Types.CONFLICTS_REQUEST]: listRequest,
  [Types.CONFLICTS_SUCCESS]: listSuccess,
  [Types.CONFLICTS_FAILURE]: listFailure,

  [Types.BOOKINGS_WAITLIST_REQUEST]: listRequest,
  [Types.BOOKINGS_WAITLIST_SUCCESS]: listSuccess,
  [Types.BOOKINGS_WAITLIST_FAILURE]: listFailure,

  [Types.BOOKINGS_SUBSCRIPTIONS_LIST_REQUEST]: listRequest,
  [Types.BOOKINGS_SUBSCRIPTIONS_LIST_SUCCESS]: listSuccess,
  [Types.BOOKINGS_SUBSCRIPTIONS_LIST_FAILURE]: listFailure,

  [Types.BOOKINGS_SUBSCRIPTION_REQUEST]: detailRequest,
  [Types.BOOKINGS_SUBSCRIPTION_SUCCESS]: detailSuccess,
  [Types.BOOKINGS_SUBSCRIPTION_FAILURE]: detailFailure,

  [Types.BOOKINGS_SUBSCRIPTION_CANCEL_REQUEST]: cancelRequest,
  [Types.BOOKINGS_SUBSCRIPTION_CANCEL_SUCCESS]: cancelSuccess,
  [Types.BOOKINGS_SUBSCRIPTION_CANCEL_FAILURE]: cancelFailure,

  [Types.BOOKINGS_EDIT_REQUEST]: cancelRequest,
  [Types.BOOKINGS_EDIT_SUCCESS]: cancelSuccess,
  [Types.BOOKINGS_EDIT_FAILURE]: cancelFailure,

  [Types.DELETE_CONFLICT]: createRequest,

  [Types.BOOKINGS_KEY_RESET]: resetBasedOnKey,

  [Types.BOOKINGS_RESTORE_REQUEST]: cancelRequest,
  [Types.BOOKINGS_RESTORE_SUCCESS]: cancelSuccess,
  [Types.BOOKINGS_RESTORE_FAILURE]: cancelFailure,

  [Types.BOOKINGS_SUBSCRIPTION_PLANS_REQUEST]: listRequest,
  [Types.BOOKINGS_SUBSCRIPTION_PLANS_SUCCESS]: listSuccess,
  [Types.BOOKINGS_SUBSCRIPTION_PLANS_FAILURE]: listFailure,

  [Types.BOOKINGS_ACTIVITIES_LIST_REQUEST]: activitiesListRequest,
  [Types.BOOKINGS_ACTIVITIES_LIST_SUCCESS]: activitiesListSuccess,
  [Types.BOOKINGS_ACTIVITIES_LIST_FAILURE]: activitiesListFailure,

  [Types.BOOKINGS_ACTIVITIES_CANCELLED_LIST_REQUEST]:
    activitiesCancelledListRequest,
  [Types.BOOKINGS_ACTIVITIES_CANCELLED_LIST_SUCCESS]:
    activitiesCancelledListSuccess,
  [Types.BOOKINGS_ACTIVITIES_CANCELLED_LIST_FAILURE]:
    activitiesCancelledListFailure,
})
