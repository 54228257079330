/* This example requires Tailwind CSS v2.0+ */
import { CheckIcon } from '@heroicons/react/solid'
import { useDispatch } from 'react-redux'
import SettingsActions from 'Redux/SettingsRedux'
import store from 'store'
import React, { useEffect, useState, useSelector } from 'react'
import AuthAction from 'Redux/AuthRedux'
import Heading from 'Components/Heading'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import Step4 from './Step4'

let steps = {
  step1: {
    id: 'step1',
    component: Step1,
    header: 'Hey there! 👋',
    subHeader:
      'In this step please select the timezone and click on "Get Started"',
  },
  step2: {
    id: 'step2',
    component: Step2,
    header: 'Create a Service and Variant',
    subHeader:
      'Here you will create a new service. Just add a service name, variant name and/or price',
  },
  step3: {
    id: 'step3',
    component: Step3,
    header: 'Configure your variant',
    subHeader: 'Here you will configure the calendar for that variant',
  },
  step4: {
    id: 'step4',
    component: Step4,
    header: 'View Calendar',
    subHeader:
      'Click on preview to view the calendar or click Finish Onboarding',
  },
}

export default function Onboarding() {
  const dispatch = useDispatch()
  const [data, setData] = useState({})
  let settingsData = store.get('userData')
  const [step, setStep] = useState('step1')
  useEffect(() => {
    let st = settingsData.setting.step || 1
    if (st == 0) {
      st = 1
    }

    setStep(`step${st}`)
  }, [])

  let SelectedStep = steps[step].component
  let stepSelected = Math.ceil(step.split('step')[1])
  let totalSteps = Object.keys(steps).length

  return <SelectedStep setStep={setStep} {...data} setData={setData} />
}
