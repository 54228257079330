import React from 'react'
import Loader from 'react-loader-spinner'

export default function Spinner(props) {
  return (
    <div
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        WebkitTransform: 'translate(-50%, -50%)',
        zIndex: 9999,
      }}
    >
      <Loader type="ThreeDots" color="#10B981" height={100} width={100} />
    </div>
  )
}
