import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SettingsActions from 'Redux/SettingsRedux'

import { selectOptions, TrackEvent } from 'Themes/constants'
import Select from 'Components/Select'
import Button from 'Components/Button'
import Container from 'Components/Container'
import styles from 'Styles/Onboarding.module.css'

const Step1 = (props) => {
  const dispatch = useDispatch()

  return (
    <div className="flex items-center justify-center">
      <div
        className="bg-white p-6  rounded-lg ring-1 ring-gray-900 ring-opacity-5 overflow-hidden"
        style={{ width: '60%' }}
      >
        <div className="flex items-center justify-center flex-col">
          <img
            className="w-24 h-24 bg-gray-300 rounded-full flex-shrink-0 object-cover my-6"
            src="https://appointment-booking-shopify.s3.ap-south-1.amazonaws.com/onboarding-independent.svg"
            alt=""
          />
          <p className={styles.header}>Hey There,</p>
          <p className={styles.header} style={{ marginTop: 8 }}>
            Welcome To Appointo
          </p>
          <p
            style={{
              marginTop: 20,
              marginBottom: 20,
              fontSize: 16,
              textAlign: 'center',
            }}
          >
            Create services effortlessly with our user-friendly platform for
            seamless planning and execution.
          </p>
          <div className="my-4">
            <Button
              title="Create a Service"
              onClick={() => {
                props.setStep('step2')
                TrackEvent('OB Get Started', {})
                dispatch(
                  SettingsActions.settingsUpdateRequest(
                    {
                      setting: { step: 2 },
                    },
                    () => {}
                  )
                )
              }}
              type="primary"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Step1
