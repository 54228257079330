import React, { useState, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import BookingActions from 'Redux/BookingRedux'
import Input from 'Components/Input'
import Modal from 'Components/Modal'

function NoteModal(props) {
  const [note, setNote] = useState('')
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const addNote = () => {
    setLoading(true)
    dispatch(
      BookingActions.bookingNoteCreateRequest(
        props.bookingId,
        { note },
        (resp) => {
          setLoading(false)
          if (!!props.setShowNoteToast) {
            props.setShowNoteToast(true)
          }

          props.handleClose()
          dispatch(BookingActions.bookingsDetailRequest(props.bookingId))
        }
      )
    )
  }

  return (
    <div>
      <Modal
        showModal={props.showModal}
        onClose={props.handleClose}
        title="Add Note"
        primaryButton={{
          title: 'Save',
          onClick: addNote,
          disabled: !note,
          loading: loading,
        }}
      >
        <div style={{ padding: 20 }}>
          <Input
            label="Note"
            onChange={(value) => setNote(value)}
            value={note}
            multiline={4}
            placeholder=""
          />
        </div>
      </Modal>
    </div>
  )
}

export default NoteModal
