import React from 'react'
import { useLiquid } from 'react-liquid'
import $ from 'jquery'

const LiquidComponent = ({ template, data, file }) => {
  const { status, markup } = useLiquid(template, data)
  console.log('data is', data)

  if (!!markup && file.slug !== 'header' && file.slug !== 'footer') {
    setTimeout(() => {
      $('#header').load('http://localhost:3000/themes/header.html')
      $('#footer').load('http://localhost:3000/themes/footer.html')
      $('[data-id]').addClass('hover-show cursor-pointer')
      $('#html-body a').click(function (e) {
        let id = e.target.getAttribute('data-id')
        if (!!id) {
          document.getElementById(id).scrollIntoView({
            behavior: 'smooth',
          })
        }
        e.preventDefault()
      })
      $('#html-body').on('click', function (e) {
        let id = e.target.getAttribute('data-id')
        if (!!id) {
          document.getElementById(id).scrollIntoView({
            behavior: 'smooth',
          })
        }
      })
    }, 50)
  }

  return <div dangerouslySetInnerHTML={{ __html: markup }} />
}

export default LiquidComponent
