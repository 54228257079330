import React, {
  useState,
  useCallback,
  useEffect,
  useRef,
  Fragment,
} from 'react'

import ThemesActions from 'Redux/ThemesRedux'
import { useDispatch } from 'react-redux'
import Select from 'Components/Select'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import Button from 'Components/Button'
import Spinner from 'Components/Spinner'
import Input from 'Components/Input'
import Label from 'Components/Label'
import LiquidComponent from 'Components/LiquidComponent'
import Dropzone from 'react-dropzone'
import { UpLoadThemeImage } from 'Utils/CreatePresignedUrl'

function ManageThemeModal(props) {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const [themeFiles, setThemeFiles] = useState([])
  const [currentFile, setCurrentFile] = useState({})
  const [filesOptions, setFilesOptions] = useState([])
  const [selectedOption, setSelectedOption] = useState('')
  const cancelButtonRef = useRef(null)

  const onDrop = (key) => async (acceptedFiles) => {
    const formData = new FormData()
    formData.append('image', acceptedFiles[0])
    setLoading(true)
    try {
      let resp = await UpLoadThemeImage(acceptedFiles[0])
      let tempFile = { ...currentFile }
      tempFile.locale_payload[key] = resp.image_url
      setCurrentFile((currentFile) => tempFile)
      handleSave()
    } catch (error) {
      console.log('error', error)
    }
  }

  useEffect(() => {
    setLoading(true)
    dispatch(
      ThemesActions.themesFilesRequest({}, (data) => {
        setThemeFiles(data)
        setFilesOptions(
          data.map((d) => {
            return { label: `${d.name}.${d.file_type}`, value: d.name }
          })
        )
        setSelectedOption(data[0].name)
        dispatch(
          ThemesActions.themesFileRequest(data[0].id, (data) => {
            setCurrentFile(data)
            setLoading(false)
          })
        )
      })
    )
  }, [])

  const handleClose = useCallback(() => {
    props.setShowModal(false)
  }, [props])

  const handleSave = async () => {
    let params = {
      theme_file_id: currentFile.id,
    }

    if (currentFile.file_type === 'js') {
      params.locale_payload = {}
      params.content = currentFile.content
    } else {
      params.locale_payload = currentFile.locale_payload
    }

    setLoading(true)
    dispatch(
      ThemesActions.themesFileUpdateRequest(params, (data) => {
        setLoading(false)
      })
    )
  }

  const generateDiv = (key, value) => {
    const label = extractLabel(key)

    if (key.includes('img')) {
      return (
        <div id={key}>
          <Label title={label} labelClass="mx-4" />
          <Dropzone
            onDrop={onDrop(key)}
            maxFiles={1}
            accept="image/jpeg, image/png"
          >
            {({ getRootProps, getInputProps }) => (
              <section className="container">
                <div {...getRootProps({ className: 'dropzone' })}>
                  <input {...getInputProps()} />
                  <img src={value} style={{ height: '100px' }} />
                  <p className="mt-2">
                    Drag 'n' drop a file here, or click to select the file
                  </p>
                </div>
              </section>
            )}
          </Dropzone>
        </div>
      )
    }

    return (
      <Input
        id={key}
        label={label}
        placeholder="Enter text"
        containerClass="col-span-full sm:col-span-2 mx-4 mb-4"
        value={value}
        onChange={(value) => {
          onChangeValue(value, key)
        }}
      />
    )
  }

  const onChangeValue = (value, key) => {
    let tempFile = { ...currentFile }
    tempFile.locale_payload[key] = value
    setCurrentFile((currentFile) => tempFile)
  }

  const extractLabel = (key) => {
    let textArr = key.split('_')
    let uprArr = textArr.map((t) => {
      return t.charAt(0).toUpperCase() + t.slice(1)
    })
    return uprArr.join(' ')
  }

  const getDataForFile = (currentFile) => {
    console.log(currentFile)
    let data = currentFile.locale_payload
    switch (currentFile.slug_url) {
      case '':
        return {
          ...data,
          products: currentFile.metadata.products,
        }
      case 'collection':
        return {
          ...data,
          products: currentFile.metadata.products,
        }
      case 'products':
        return {
          ...data,
          product: currentFile.metadata.product,
        }
      default:
        return data
    }
  }

  return (
    <div>
      <Transition.Root show={props.showModal} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          open={props.showModal}
          onClose={handleClose}
        >
          <div
            className="flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center"
            id="modal-example-regular"
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div
                className={`relative w-auto my-6 mx-auto w-full`}
                style={{
                  height: '100vh',
                }}
              >
                <div className="border-0  shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                  <div
                    className="flex items-start justify-between p-5 border-b border-solid border-gray-200 rounded-t"
                    style={{ height: 70 }}
                  >
                    <h3 className="text-3xl font-semibold">Dawn</h3>
                    <Select
                      label=""
                      options={filesOptions}
                      selected={selectedOption}
                      setSelected={(value) => {
                        setLoading(true)
                        let find = themeFiles.find((t) => t.name === value)
                        dispatch(
                          ThemesActions.themesFileRequest(find.id, (data) => {
                            setCurrentFile(data)
                            setLoading(false)
                          })
                        )
                        setSelectedOption(value)
                      }}
                      containerClass="flex justify-center items-end mb-4 w-52"
                    />
                    <button
                      className="p-1 bg-transparent border-0 text-gray-300 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={handleClose}
                      ref={cancelButtonRef}
                    >
                      <span className="bg-transparent h-6 w-6 text-2xl block outline-none focus:outline-none">
                        <XIcon className="w-6" />
                      </span>
                    </button>
                  </div>

                  {/* main content area */}
                  {loading ? (
                    <div
                      style={{ width: '100%', height: 'calc(100vh - 130px)' }}
                    >
                      <Spinner />
                    </div>
                  ) : currentFile.file_type === 'js' ? (
                    <div
                      className={`flex relative flex-auto bg-gray-100 flex-row w-full overflow-y-scroll`}
                      style={{ height: 'calc(100vh - 130px)' }}
                    >
                      <Input
                        label=""
                        placeholder=""
                        containerClass="col-span-full sm:col-span-2 w-full"
                        value={currentFile.content}
                        onChange={(value) => {
                          let tempFile = { ...currentFile }
                          tempFile.content = value
                          setCurrentFile((currentFile) => tempFile)
                        }}
                        lines={100}
                      />
                    </div>
                  ) : (
                    <div
                      className={`flex relative flex-auto bg-gray-100 flex-row`}
                      style={{ height: 'calc(100vh - 130px)' }}
                    >
                      <div className="border-r border-gray-200 bg-white h-full w-1/4 overflow-y-scroll">
                        <div className="mt-4">
                          <Label
                            title="Website Details"
                            labelClass="ml-4 mb-4 text-xl font-semibold"
                          />
                          {currentFile.content &&
                            Object.keys(currentFile.locale_payload).map((k) => {
                              return generateDiv(
                                k,
                                currentFile.locale_payload[k]
                              )
                            })}
                        </div>
                      </div>
                      <div className="h-full w-3/4 overflow-y-scroll">
                        {currentFile.content && (
                          <LiquidComponent
                            template={currentFile.content}
                            data={getDataForFile(currentFile)}
                            file={currentFile}
                          />
                        )}
                      </div>
                    </div>
                  )}

                  <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse rounded-b border-t border-solid border-gray-200">
                    <Button
                      title={'Save'}
                      onClick={handleSave}
                      loading={loading}
                      type={'primary'}
                    />
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
        </Dialog>
      </Transition.Root>
    </div>
  )
}

export default ManageThemeModal
