import React, { useState, useCallback, useEffect } from "react";
import Input from "Components/Input";
import AuthAction from "Redux/AuthRedux";
import Button from "Components/Button";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { logo_url } from "Themes/constants";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [helpText, setHelpText] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();

  const signIn = () => {
    setLoading(true);
    dispatch(
      AuthAction.forgotPasswordRequest(
        { email, redirect_url: "/dashboard" },
        (resp, err) => {
          setLoading(false);
          setHelpText(
            "Password Request Link has been send succesfully. Please check your email"
          );
        }
      )
    );
  };

  const checkDisabled = () => {
    return !email;
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-16 w-auto" src={logo_url} alt="Appointo" />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Send verification email
        </h2>
        <p className="mt-2 text-center text-sm text-gray-600">
          Or{" "}
          <a
            href="/register"
            className="font-medium text-green-600 hover:text-green-500"
          >
            create a new account
          </a>
        </p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <div className="space-y-6">
            <div>
              <Input
                label="Email"
                placeholder="Enter your email"
                containerClass="col-span-full sm:col-span-2"
                value={email}
                onChange={(value) => {
                  setEmail(value);
                }}
                helpText={helpText}
              />
            </div>

            <div>
              <Button
                title="Send email"
                onClick={signIn}
                type="primary"
                className="w-full justify-center"
                disabled={checkDisabled()}
                loading={loading}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
