import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'
import {
  listRequest,
  listSuccess,
  listFailure,
  detailRequest,
  detailSuccess,
  detailFailure,
  upsertRequest,
  upsertSuccess,
  upsertFailure,
} from './genericReducers'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  waitlistListRequest: ['params', 'listDataOffset'],
  waitlistListSuccess: ['list'],
  waitlistListFailure: ['listError'],

  waitlistUpsertRequest: ['params', 'callback'],
  waitlistUpsertSuccess: [''],
  waitlistUpsertFailure: ['error'],
})

export const WaitlistTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  list: [],
  listFetching: null,
  listError: null,
  listDataOffset: 0,

  upsertData: {},
  upsertError: null,
  upsertFetching: false,
})

/* ------------- Reducers ------------- */

export const reset = (state) => state.merge(INITIAL_STATE)

/* ------------- Selectors ------------- */

export const WaitlistSelectors = {
  listDataOffset: (state) => state.waitlist.listDataOffset,
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.WAITLIST_LIST_REQUEST]: listRequest,
  [Types.WAITLIST_LIST_SUCCESS]: listSuccess,
  [Types.WAITLIST_LIST_FAILURE]: listFailure,

  [Types.WAITLIST_UPSERT_REQUEST]: upsertRequest,
  [Types.WAITLIST_UPSERT_SUCCESS]: upsertSuccess,
  [Types.WAITLIST_UPSERT_FAILURE]: upsertFailure,
})
