import { PlusIcon, TrashIcon } from '@heroicons/react/outline'
import OverrideModal from 'Components/Modals/OverrideModal'
import React, { useState } from 'react'
import styles from 'Styles/DateOverrides.module.css'

const DateOverrides = ({ override, setOverride, disabled }) => {
  const [showModal, setShowModal] = useState(false)

  const setOverrides = (ovrds) => {
    let filteredOvrd = override.filter(
      (o) => !ovrds.find((ov) => ov.day === o.day)
    )
    setOverride([...filteredOvrd, ...ovrds])
    setShowModal(false)
  }

  const removeOption = (i) => () => {
    let tempOverride = [...override]
    tempOverride = tempOverride.filter((_, index) => index !== i)
    setOverride(tempOverride)
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.weeklyContainer}>
          <p className={styles.weeklyText}>Add date overrides</p>
          {!disabled && (
            <div
              className={styles.addContainer}
              onClick={() => {
                setShowModal(true)
              }}
            >
              <PlusIcon className="w-5" />
            </div>
          )}
        </div>
        <div className={styles.weekdaysContainer}>
          <div className={styles.headerContainer}>
            <p className={styles.dates}>DATES</p>
            <p className={styles.availability}>AVAILABILITY</p>
          </div>
          {override.map((o, i) => {
            return (
              <div className={styles.bodyContainer}>
                <p className={styles.day}>{o.day}</p>
                <div className={styles.startContainer}>
                  <>
                    {o.slots.map((s) => (
                      <div className={styles.iconContainer}>
                        <p className={styles.time}>
                          {s.start_time} - {s.end_time}
                        </p>
                      </div>
                    ))}
                    {o.slots.length === 0 && (
                      <p className={styles.unavailable}>Unavailable</p>
                    )}
                  </>
                </div>
                {!disabled && (
                  <div
                    className={styles.removeButton}
                    onClick={removeOption(i)}
                  >
                    <TrashIcon />
                  </div>
                )}
              </div>
            )
          })}
        </div>
      </div>
      <OverrideModal
        showModal={showModal}
        setShowModal={setShowModal}
        handleClose={() => {
          setShowModal(false)
        }}
        onClick={setOverrides}
        override={override}
      />
    </>
  )
}

export default DateOverrides
