import React from 'react'
import moment from 'moment'
import Modal from 'Components/Modal'

function OrderSchedule({ showModal, setShowModal, schedule }) {
  const formatItems = (list) => {
    if (list) {
      return list.map((l, i) => {
        let date = moment(l.billing_date).format('MMM Do YYYY, h:mm:ss a')
        return (
          <h3>
            #{i + 1} {date}
          </h3>
        )
      })
    } else {
      return <></>
    }
  }

  return (
    <Modal
      showModal={showModal}
      onClose={() => {
        setShowModal(false)
      }}
      title="Order Schedule"
      primaryButton={{
        title: 'Close',
        onClick: () => {
          setShowModal(false)
        },
      }}
      size="large"
    >
      <p>{formatItems(schedule)}</p>
    </Modal>
  )
}
export default OrderSchedule
