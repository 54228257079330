import React, { useState } from 'react'
import BookingActions from 'Redux/BookingRedux'
import { useDispatch } from 'react-redux'
import Modal from 'Components/Modal'
import Input from 'Components/Input'

function EditBookingCountModal(props) {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [bookingCount, setBookingCount] = useState(`${props.bookingCount}`)
  const updateBookingCount = () => {
    setLoading(true)
    let params = {
      id: props.id,
    }

    if (props.subscriptionType === 'fixed') {
      params.one_time_booking_count = bookingCount
    } else {
      params.recurring_booking_count = bookingCount
    }

    dispatch(
      BookingActions.updateSubscriptionDetails(params, () => {
        setLoading(false)
        dispatch(BookingActions.bookingsSubscriptionRequest(props.id))
        props.handleClose()
      })
    )
  }

  return (
    <>
      <Modal
        open={props.showModal}
        onClose={props.handleClose}
        showModal={props.showModal}
        title="Update Booking Count"
        primaryButton={{
          title: 'Update',
          onClick: updateBookingCount,
          loading: loading,
        }}
      >
        <div style={{ padding: 20 }}>
          <Input
            id={`bookingCount`}
            label="Booking Count"
            onChange={(value) => {
              setBookingCount(value)
            }}
            value={bookingCount}
            type="number"
          />
        </div>
      </Modal>
    </>
  )
}

export default EditBookingCountModal
