import { all, call, put, takeLatest } from 'redux-saga/effects'
import VariantAction, { VariantTypes } from 'Redux/VariantRedux'

export function* detailsRequest(api, action) {
  const resp = yield call(api.getVariantDetails, action.id, action.productId)
  if (resp.ok) {
    yield put(VariantAction.variantsDetailSuccess(resp.data))
  } else {
    yield put(VariantAction.variantsDetailFailure('something went wrong'))
  }
}

export function* upsertVariant(api, action) {
  const resp = yield call(api.upsertVariant, action.params)
  if (resp.ok) {
    yield put(VariantAction.variantsUpsertSuccess(resp.data))
    action.callback()
  } else {
    yield put(VariantAction.variantsUpsertFailure('something went wrong'))
    action.callback()
  }
}

export function* deleteCustomField(api, action) {
  const resp = yield call(
    api.deleteCustomField,
    action.variantId,
    action.productId,
    {
      id: action.customFieldId,
    }
  )
  if (resp.ok) {
    yield put(VariantAction.customFieldDeleteSuccess())
    action.callback()
  } else {
    yield put(VariantAction.customFieldDeleteFailure())
    action.callback()
  }
}

export function* deleteVariant(api, action) {
  const resp = yield call(api.deleteVariant, action.productId, action.variantId)
  if (resp.ok) {
    yield put(VariantAction.variantsDeleteSuccess())
    action.callback()
  } else {
    yield put(VariantAction.variantsDeleteFailure())
    action.callback()
  }
}

export function* variantsSearchRequest(api, action) {
  const resp = yield call(api.searchVariants, action.params)
  if (resp.ok) {
    action.callback(resp.data)
  }
}

export default function* variantSagas(api) {
  yield all([
    takeLatest(VariantTypes.VARIANTS_DETAIL_REQUEST, detailsRequest, api),
    takeLatest(VariantTypes.VARIANTS_UPSERT_REQUEST, upsertVariant, api),
    takeLatest(
      VariantTypes.CUSTOM_FIELD_DELETE_REQUEST,
      deleteCustomField,
      api
    ),
    takeLatest(VariantTypes.VARIANTS_DELETE_REQUEST, deleteVariant, api),
    takeLatest(
      VariantTypes.VARIANTS_SEARCH_REQUEST,
      variantsSearchRequest,
      api
    ),
  ])
}
