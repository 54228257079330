import Stepper, { StepperItem } from 'Components/Stepper'
import { logsMap } from '../../Themes/constants'
import { useSelector, useDispatch } from 'react-redux'
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import BookingActions from 'Redux/BookingRedux'
import Modal from 'Components/Modal'

function BookingTimelineModal({ showModal, setShowModal, bookingId }) {
  const dispatch = useDispatch()
  const [items, setItems] = useState([])
  let activitiesList = useSelector((state) => state.booking.activitiesList)
  const formatItems = (list) => {
    return list.map((l, i) => {
      let date = moment(l.log_time).format('MMM Do YYYY, h:mm:ss a')
      let logsMapData = logsMap[l.scenario]
      if (!!logsMapData) {
        let header = logsMapData.header
        let body = logsMapData.body
        if (body.includes('{{')) {
          const startIndexes = [...body.matchAll(new RegExp('{{', 'gi'))].map(
            (a) => a.index
          )
          const endIndexes = [...body.matchAll(new RegExp('}}', 'gi'))].map(
            (a) => a.index
          )
          body = [...Array(startIndexes.length).keys()].map((k) => {
            let sI = startIndexes[k]
            let eI = endIndexes[k]
            return (
              <>
                {body.substring(0, sI)}
                <strong>{l.metadata[body.substring(sI + 2, eI)] || ''}</strong>
                {body.substring(eI + 2, body.length)}
              </>
            )
          })
        }
        return (
          <StepperItem key={i} header={header} body={body} subText={date} />
        )
      }
      return null
    })
  }
  console.log(items, 'asdfghjkl')
  useEffect(() => {
    let items = formatItems(activitiesList)
    setItems(items)
  }, [activitiesList])

  useEffect(() => {
    {
      dispatch(
        BookingActions.bookingsActivitiesListRequest({ booking_id: bookingId })
      )
    }
  }, [])

  return (
    <Modal
      showModal={showModal}
      onClose={() => {
        setShowModal(false)
      }}
      title="Booking Logs"
      primaryAction={{
        content: 'Close',
        onAction: () => {
          setShowModal(false)
        },
      }}
    >
      <div style={{ color: '#202223' }}>
        <Stepper>{items}</Stepper>
      </div>
    </Modal>
  )
}

export default BookingTimelineModal
