import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import BookingActions from 'Redux/BookingRedux'
import moment from 'moment'
import Card from 'Components/Card'
import Modal from 'Components/Modal'
import Checkbox from 'Components/Checkbox'

function ConnectBookingToSubscriptionModal(props) {
  const dispatch = useDispatch()
  const [selectedCustomer, setSelectedCustomer] = useState({})
  const [loading, setLoading] = useState(false)
  const [bookings, setBookings] = useState([])

  useEffect(() => {
    if (props.showModal) {
      setLoading(true)
      dispatch(
        BookingActions.bookingsByCustomer({ email: props.email }, (data) => {
          setLoading(false)
          setBookings(data)
        })
      )
    }
  }, [props.showModal])

  const connectBooking = async () => {
    let params = {
      customer_id: selectedCustomer.id,
      subscription_contract_id: props.id,
    }
    setLoading(true)

    dispatch(
      BookingActions.connectBookingToSubscription(params, () => {
        setLoading(false)
        props.setShowModal(false)
        dispatch(BookingActions.bookingsSubscriptionRequest(props.id))
      })
    )
  }

  const checkDisabled = () => {
    return !selectedCustomer.id
  }

  const onSelectionChange = (item) => (value) => {
    if (value) {
      setSelectedCustomer(item)
    } else {
      setSelectedCustomer({})
    }
  }

  return (
    <div>
      <Modal
        onClose={() => {
          props.setShowModal(false)
        }}
        showModal={props.showModal}
        title={props.title}
        primaryButton={{
          title: 'Connect Booking',
          onClick: connectBooking,
          loading: loading,
          disabled: checkDisabled(),
        }}
      >
        <Card
          style={{
            padding: 0,
          }}
        >
          <p className="text-sm pb-2">
            Select one of the booking you want to add to this subscription
          </p>
          {bookings
            .filter((b) => !b.subscription_contract_id)
            .map((item) => {
              const { id, order_name, booking } = item
              let content =
                booking.scenario === 'multi_day' ? (
                  <p className="text-xs">{`${moment(booking.start_date).format(
                    'MMM Do YYYY'
                  )} - ${moment(booking.end_date).format('MMM Do YYYY')}`}</p>
                ) : (
                  <p className="text-xs">
                    {moment(booking.selected_time).format('llll')}
                  </p>
                )
              return (
                <div className="flex gap-1">
                  <div>
                    {(!selectedCustomer.id || selectedCustomer.id === id) && (
                      <Checkbox
                        label=""
                        checked={selectedCustomer.id === id}
                        onClick={onSelectionChange(item)}
                      />
                    )}
                  </div>

                  <div className={StyleSheet.textContainer}>
                    <h3 className="text-sm">
                      Booking for {booking.product_name} -{' '}
                      {booking.variant_name}
                    </h3>
                    <h5 className="texts-xs">{content}</h5>
                  </div>
                </div>
              )
            })}
        </Card>
      </Modal>
    </div>
  )
}

export default ConnectBookingToSubscriptionModal
