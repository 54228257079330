import { ChevronLeftIcon } from '@heroicons/react/solid'
import PropTypes from 'prop-types'
import React from 'react'
import { useHistory } from 'react-router-dom'
import Container from './Container'
export default function Heading(props) {
  const history = useHistory()
  return (
    <Container>
      <nav aria-label="Back">
        {props.backAction && (
          <div
            onClick={props.backAction.onClick || history.goBack}
            className="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 cursor-pointer"
          >
            <ChevronLeftIcon
              className="flex-shrink-0 -ml-1 mr-1 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
            {props.backAction.title}
          </div>
        )}
      </nav>
      <div className="max-w-7xl mx-auto">
        <div className="md:flex md:items-center md:justify-between">
          <div className="flex-1 min-w-0">
            <h2 className="text-2xl font-semibold leading-7 text-gray-900 sm:text-3xl sm:truncate">
              {props.title}
            </h2>
            {props.subTitle && (
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                {props.subTitle}
              </p>
            )}
          </div>
          <div className="mt-4 flex md:mt-0 md:ml-4">{props.rightSection}</div>
        </div>
      </div>
    </Container>
  )
}

Heading.propTypes = {
  rightSection: PropTypes.any,
  subTitle: PropTypes.string,
  title: PropTypes.string,
}
