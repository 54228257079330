// import { Modal } from '@shopify/polaris'
import React, { useState } from 'react'
// import HourMinutePicker from './HourMinutesPicker'
import { useDispatch } from 'react-redux'
import BookingActions from 'Redux/BookingRedux'
import Modal from 'Components/Modal'
import Select from 'Components/Select'
import HourMinutePicker from 'Components/HourMinutePicker'

const EditDurationModal = (props) => {
  const dispatch = useDispatch()
  const [duration, setDuration] = useState(props.defaultValue)
  const handleDurationChange = (value) => {
    setDuration(value)
  }

  const handleDurationSave = () => {
    props.setLoading(true)
    dispatch(
      BookingActions.bookingsEditRequest(
        props.bookingId,
        { duration: duration },
        () => {
          dispatch(BookingActions.bookingsDetailRequest(props.bookingId))
          props.handleClose()
        }
      )
    )
  }

  return (
    <div>
      <Modal
        showModal={props.showModal}
        onClose={props.handleClose}
        title={props.title}
        primaryButton={{
          title: props.buttonText ?? 'Save Duration',
          onClick: handleDurationSave,
          loading: props.loading,
        }}
      >
        {props.body ? <div style={{ padding: 20 }}>{props.body}</div> : null}
        <div style={{ padding: 20 }}>
          <HourMinutePicker
            id={`${props.bookingId}_textfield`}
            onChange={handleDurationChange}
            label="Duration (min: 10)"
            value={duration}
            hideDays
          />
        </div>
      </Modal>
    </div>
  )
}

export default EditDurationModal
