export const DASHBOARD = '/dashboard'

// SERVICES ROUTES
export const SERVICES = `${DASHBOARD}/services`
export const CREATE_SERVICE = `${DASHBOARD}/services/create`
export const EDIT_SERVICE = `${DASHBOARD}/services/edit`

// BOOKINGS ROUTES
export const BOOKINGS = `${DASHBOARD}/bookings`
export const MANAGE_BOOKING = `${DASHBOARD}/bookings`

// SUBSCRIPTION ROUTES
export const SUBSCRIPTIONS = `${DASHBOARD}/subscriptions`

// CUSTOMER ROUTES
export const CUSTOMERS = `${DASHBOARD}/customers`
export const MANAGE_CUSTOMER = `${CUSTOMERS}/details`

// WAITLIST ROUTES
export const WAITLIST = `${DASHBOARD}/waitlist`

// CONFLICTS ROUTES
export const CONFLICT = `${DASHBOARD}/conflicts`

// INTEGRATIONS Routes
export const INTEGRATIONS = `${DASHBOARD}/integrations`
export const SETTINGS = `${DASHBOARD}/settings`
export const CALENDAR = `${DASHBOARD}/calendar`
export const TEAMS = `${DASHBOARD}/teams`
export const THEMES = `${DASHBOARD}/themes`
export const MANAGE_THEME = `${DASHBOARD}/themes/manage`
export const PLANS = `${DASHBOARD}/plans`
export const WORKFLOWS = `${DASHBOARD}/workflows`
export const TRANSLATIONS = `${DASHBOARD}/translations`
export const ONBOARDING = `/onboarding`
export const CHANGELOG = `https://headwayapp.co/appointo-app-changelog`
