import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import store from 'store'
import SettingsActions from 'Redux/SettingsRedux'
import Button from 'Components/Button'
import Container from 'Components/Container'
import Input from 'Components/Input'
import ServiceActions from 'Redux/ServiceRedux'
import CardHeading from 'Components/CardHeading'
import { TrackEvent, selectOptions } from 'Themes/constants'
import Select from 'Components/Select'
import RadioButton from 'Components/RadioButton'

const Step2 = (props) => {
  const dispatch = useDispatch()
  let settingsData = store.get('userData')
  const [productName, setProductName] = useState('Test Product')
  const [loading, setLoading] = useState(false)
  const [price, setPrice] = useState(0)
  const [duration, setDuration] = useState(30)
  const [timezone, setTimezone] = useState(selectOptions[0].value)
  const [eventType, setEventType] = useState('single')
  const [maxCapacity, setMaxCapacity] = useState('2')

  const onCreate = () => {
    let params = {
      product_name: productName,
      activate: true,
      variants: [
        {
          name: 'Default',
          price: price || 0,
          currency: settingsData.shop.currency
            ? settingsData.shop.currency.toUpperCase()
            : 'USD',
          config: {
            timezone: timezone,
            duration: duration,
            max_capacity: eventType === 'group' ? maxCapacity : 1,
          },
        },
      ],
    }
    setLoading(true)
    TrackEvent('OB Add Product', {})
    dispatch(
      ServiceActions.servicesUpsertRequest(params, (data) => {
        setLoading(false)
        props.setStep('step3')
        dispatch(
          SettingsActions.settingsUpdateRequest(
            {
              setting: { step: 3 },
              shop: { store_timezone: timezone },
            },
            () => {}
          )
        )
      })
    )
  }

  return (
    <Container>
      <h2 className="text-2xl font-semibold leading-7 text-gray-900 sm:text-3xl sm:truncate">
        Create an event
      </h2>

      <RadioButton
        containerClass="flex"
        optionsClass="w-40 rounded-md"
        label="Type of Event"
        selected={eventType}
        setSelected={(value) => {
          setEventType(value)
        }}
        data={[
          {
            name: 'One on One',
            value: 'single',
          },
          {
            name: 'Group',
            value: 'group',
          },
        ]}
      />

      {eventType === 'group' && (
        <div className="grid grid-cols-4 gap-6" style={{ marginBottom: 16 }}>
          <Input
            label="Max number of People in this appointment"
            placeholder="Enter the number"
            containerClass="col-span-full sm:col-span-2"
            value={maxCapacity}
            onChange={(value) => {
              setMaxCapacity(value)
            }}
            type="number"
          />
        </div>
      )}

      <div className="grid grid-cols-4 gap-6">
        <Input
          label="Service Name"
          placeholder="Enter service name"
          containerClass="col-span-full sm:col-span-2"
          value={productName}
          onChange={(value) => {
            setProductName(value)
          }}
        />
        <Input
          label="Price"
          placeholder="Enter price"
          containerClass="col-span-full sm:col-span-2"
          type="currency"
          value={price}
          onChange={(value) => {
            setPrice(value)
          }}
        />
      </div>

      <div className="grid grid-cols-4 gap-6 my-6">
        <Input
          label="Duration (in mins) (min: 10)"
          placeholder="Enter the duration in mins"
          containerClass="col-span-full sm:col-span-2"
          value={duration}
          onChange={(value) => {
            setDuration(value)
          }}
          type="number"
        />
        <Select
          label="Timezone"
          options={selectOptions}
          selected={timezone}
          setSelected={setTimezone}
          containerClass="col-span-full sm:col-span-2"
        />
      </div>

      <div className="my-6">
        <Button
          title="Next Step"
          onClick={onCreate}
          type="primary"
          disabled={!productName || !timezone}
          loading={loading}
        />
      </div>
    </Container>
  )
}

export default Step2
