import Input from 'Components/Input'
import Modal from 'Components/Modal'
import React, { useCallback, useState } from 'react'
import store from 'store'
import { useDispatch, useSelector } from 'react-redux'
import ServiceActions from 'Redux/ServiceRedux'

function AddVariantModal(props) {
  const dispatch = useDispatch()
  let settingsData = store.get('userData')
  const [name, setName] = useState('60 minutes')
  const [price, setPrice] = useState(0)
  const upsertFetching = useSelector((state) => state.service.upsertFetching)

  const checkDisabled = () => {
    if (!name) {
      return true
    }
    return false
  }

  const onChangeName = (value) => {
    setName(value)
  }

  const onChangePrice = (value) => {
    setPrice(value)
  }

  const onSave = () => {
    let params = {
      id: props.serviceId,
      variants: [
        {
          name: name,
          price: price || 0,
          currency: settingsData.shop.currency ? settingsData.shop.currency.toUpperCase()  : 'USD',
        },
      ],
    }

    dispatch(
      ServiceActions.servicesUpsertRequest(params, (data) => {
        dispatch(ServiceActions.servicesDetailRequest(props.serviceId))
        props.handleClose()
      })
    )
  }

  return (
    <div>
      <Modal
        showModal={props.showModal}
        onClose={props.handleClose}
        title={props.title}
        primaryButton={{
          title: 'Save',
          onClick: onSave,
          disabled: checkDisabled(),
          loading: upsertFetching,
        }}
      >
        <div className=" grid grid-cols-1 gap-y-6 gap-x-4">
          <Input
            label="Name"
            placeholder="Enter variant name"
            value={name}
            onChange={onChangeName}
          />

          {/* <Input
            label="Price"
            placeholder="Enter price"
            type="currency"
            value={price}
            onChange={onChangePrice}
          /> */}
        </div>
      </Modal>
    </div>
  )
}

export default AddVariantModal
