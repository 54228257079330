import Modal from 'Components/Modal'
import React from 'react'
import store from 'store'

const MobileAppModal = (props) => {
  const userData = store.get('userData')

  const handleClose = () => {
    props.setShowModal(false)
  }

  return (
    <>
      <Modal
        showModal={props.showModal}
        onClose={() => props.setShowModal(false)}
        title={props.mobileAppKey == 'android_app' ? 'Android App' : 'IOS App'}
        primaryButton={{
          title: 'Close',
          onClick: handleClose,
        }}
      >
        <div className="flex flex-col gap-4 items-center justify-center text-center">
          <p className="text-sm">
            Use this token in the mobile app to login and use the mobile app to
            manage your availabilities (Admin & Team Members Only)
          </p>
          {userData?.shop?.mobile_token && (
            <p className="text-sm">
              Token{' '}
              <span className="font-bold">{userData?.shop?.mobile_token}</span>
            </p>
          )}
          <p className="text-sm">
            {props.mobileAppKey == 'android_app'
              ? 'Use the QR Code below to scan and download the app from Google Play Store'
              : 'Use the QR Code below to scan and download the app from iOS App Store'}
          </p>
          <div className="flex flex-col items-center justify-center gap-1">
            <img
              src={
                props.mobileAppKey == 'android_app'
                  ? 'https://appointment-booking-shopify.s3.ap-south-1.amazonaws.com/qr-code-android.svg'
                  : 'https://appointment-booking-shopify.s3.ap-south-1.amazonaws.com/qr-code-ios.svg'
              }
              alt="mobile app qr code"
              width={150}
              height={150}
            />

            <p className="text-sm">Download Android App</p>
          </div>
          <p className="text-sm">Download link</p>
          <a
            className="text-sm underline text-blue-500"
            href={
              props.mobileAppKey === 'android_app'
                ? 'https://play.google.com/store/apps/details?id=com.appointo.sidepanda&hl=en&gl=US'
                : 'https://apps.apple.com/us/app/appointment-booking-appointo/id1634579092'
            }
            target="_blank"
          >
            {props.mobileAppKey === 'android_app'
              ? 'https://play.google.com/store/apps/details?id=com.appointo.sidepanda&hl=en&gl=US'
              : 'https://apps.apple.com/us/app/appointment-booking-appointo/id1634579092'}
          </a>
        </div>
      </Modal>
    </>
  )
}

export default MobileAppModal
