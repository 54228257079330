import React, { useState, useCallback, useEffect } from 'react'
import Modal from 'Components/Modal'
import axios from 'axios'
import moment from 'moment'
import { CSVLink } from 'react-csv'
import store from 'store'
import RangeDatePicker from './RangeDatePicker'

let defaultHeaders = [
  { label: 'Order No.', key: 'order_name' },
  { label: 'Appointment Name', key: 'product_name' },
  { label: 'Variant Name', key: 'variant_name' },
  { label: 'Appointment Time', key: 'selected_time' },
  { label: 'Duration', key: 'duration' },
  { label: 'Customer Name', key: 'customer_name' },
  { label: 'Customer Email', key: 'customer_email' },
  { label: 'Team Member Email', key: 'team_member_email' },
  { label: 'Team Member Name', key: 'team_member_name' },
]

function CSVModal(props) {
  const [{ month, year }, setDate] = useState({
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  })
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(null)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [headers, setHeaders] = useState(defaultHeaders)

  const handleClose = useCallback(() => {
    props.setShowModal(false)
  }, [props])

  const onChange = (dates) => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
  }

  const handleSave = async () => {
    let authData = store.get('authData')

    let url = `${process.env.REACT_APP_BASE_URL}`
    setLoading(true)
    setHeaders(defaultHeaders)
    setData([])
    let resp = await axios.get(`${url}/dashboard/appointments/bookings`, {
      params: {
        start_date: moment(startDate).format('YYYY-MM-DD'),
        end_date: moment(endDate).format('YYYY-MM-DD'),
        fetch_custom_field: true,
      },
      headers: {
        ...authData,
      },
    })

    let maxCustomFieldsLength = 0
    resp.data.map((r) => {
      r.customers.map((c) => {
        if (
          c.custom_field_values &&
          c.custom_field_values.length > maxCustomFieldsLength
        ) {
          maxCustomFieldsLength = c.custom_field_values.length
        }
      })
    })

    let maxNotesLength = 0
    resp.data.map((r) => {
      if (r.notes && r.notes.length > 0 && r.notes.length > maxNotesLength) {
        maxNotesLength = r.notes.length
      }
    })

    if (maxCustomFieldsLength > 0) {
      let newHeaders = [...defaultHeaders]
      for (let i = 0; i < maxCustomFieldsLength; i++) {
        newHeaders.push({
          label: `Custom Field Label ${i}`,
          key: `custom_field_label_${i}`,
        })
        newHeaders.push({
          label: `Custom Field Value ${i}`,
          key: `custom_field_value_${i}`,
        })
      }
      setHeaders(newHeaders)
    }

    if (maxNotesLength > 0) {
      let newHeaders = [...defaultHeaders]
      for (let i = 0; i < maxNotesLength; i++) {
        newHeaders.push({
          label: `Note ${i}`,
          key: `note_${i}`,
        })
      }
      setHeaders(newHeaders)
    }

    let d = resp.data.map((r) => {
      return r.customers.map((c) => {
        let params = {
          order_name: c.order_name || '-',
          product_name: r.product_name,
          variant_name: r.variant_name,
          selected_time: moment(r.selected_time).format('YYYY-MM-DD HH:mm'),
          duration: r.duration,
          customer_name: c.name,
          customer_email: c.email,
          team_member_email: r.team_member ? r.team_member.email : 'Admin',
          team_member_name: r.team_member ? r.team_member.name : 'Admin',
        }

        if (c.custom_field_values) {
          c.custom_field_values.map((c, i) => {
            params[`custom_field_label_${i}`] = c.label || ''
            params[`custom_field_value_${i}`] = c.value || ''
          })
        }

        if (r.notes && r.notes.length > 0) {
          r.notes.map((n, i) => {
            params[`note_${i}`] = n.note || ''
          })
        }

        return params
      })
    })

    setLoading(false)

    if (d.length === 0) {
      alert('No Booking Available in the selected date range')
    }

    setData(d.flat())
  }

  const handleMonthChange = useCallback(
    (month, year) => setDate({ month, year }),
    []
  )

  useEffect(() => {
    setEndDate(moment(startDate).add(1, 'week').toDate())
  }, [])

  return (
    <div>
      <Modal
        showModal={props.showModal}
        onClose={handleClose}
        title="Export CSV"
        primaryButton={{
          title: 'Generate CSV',
          onClick: handleSave,
          loading: loading,
        }}
        secondaryButton={
          data.length > 0
            ? {
                title: (
                  <CSVLink
                    data={data}
                    headers={headers}
                    filename={`bookings_${moment(startDate).format(
                      'YYYY-MM-DD'
                    )}_${moment(endDate).format('YYYY-MM-DD')}`}
                  >
                    Download CSV
                  </CSVLink>
                ),
              }
            : ''
        }
      >
        <div style={{ maxHeight: '60vh', padding: 20 }}>
          <p style={{ fontSize: 14, color: '#637381', marginBottom: 10 }}>
            Please select the start and end date to export your bookings
          </p>

          <RangeDatePicker
            selected={startDate}
            onChange={onChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            inline
            nextMonthButtonLabel=">"
            previousMonthButtonLabel="< "
          />

          <div style={{ marginTop: 10, fontSize: 14, color: '#637381' }}>
            <p>
              Start Date:{' '}
              <span className="weglot-exclude">
                {moment(startDate).format('YYYY-MM-DD')}
              </span>
            </p>
            <p>
              End Date:{' '}
              <span className="weglot-exclude">
                {moment(endDate).format('YYYY-MM-DD')}
              </span>
            </p>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default CSVModal
