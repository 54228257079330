import { MenuIcon, PencilIcon, TrashIcon } from '@heroicons/react/outline'
import Button from 'Components/Button'
import CardHeading from 'Components/CardHeading'
import React, { useCallback, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useDispatch } from 'react-redux'
import VariantActions from 'Redux/VariantRedux'
import styles from 'Styles/CustomFields.module.css'
import AddCustomFieldModal from './Modals/AddCustomFieldModal'
import EmptyState from './EmptyState'

const CustomFields = ({
  customFields,
  setCustomFields,
  productId,
  variantId,
  setShowPaidToast,
}) => {
  const [showModal, setShowModal] = useState(false)
  const [selectedCustomField, setSelectedCustomField] = useState(null)
  const dispatch = useDispatch()

  const setCustomField = (cf) => {
    let tempCf = customFields
    if (!!selectedCustomField) {
      if (selectedCustomField.id) {
        cf.id = selectedCustomField.id
      }
      tempCf = customFields.filter((c) => c.label !== selectedCustomField.label)
    }
    setCustomFields([...tempCf, cf])
  }

  const editCustomField = (c) => () => {
    setSelectedCustomField(c)
    setShowModal(true)
  }

  const deleteCustomField = (c) => () => {
    console.log(c)
    if (!!c.id) {
      dispatch(
        VariantActions.customFieldDeleteRequest(
          variantId,
          productId,
          c.id,
          () => {}
        )
      )
      let tempCf = customFields.filter((cf) => cf.id !== c.id)
      setCustomFields(tempCf)
    } else {
      let tempCf = customFields.filter((cf) => cf.label !== c.label)
      setCustomFields(tempCf)
    }
  }

  const addQuestion = () => {
    setSelectedCustomField(null)
    setShowModal(true)
  }

  const handleDragEnd = useCallback(({ source, destination }) => {
    setCustomFields((oldItems) => {
      let oI = [...oldItems]
      const newItems = oI.slice() // Duplicate
      const [temp] = newItems.splice(source.index, 1)
      newItems.splice(destination.index, 0, temp)
      return newItems
    })
  }, [])

  return (
    <>
      <form className="space-y-6">
        <CardHeading
          title="Custom Questions"
          rightSection={
            <Button
              title="+ Custom Questions"
              onClick={addQuestion}
              type="default"
            />
          }
        />

        <div style={{ marginTop: 0 }}>
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="root">
              {(provided) => {
                return (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {customFields?.length > 0 ? (
                      customFields.map((c, i) => (
                        <Draggable
                          key={c.label}
                          draggableId={c.label}
                          index={i}
                        >
                          {(provided, snapshot) => {
                            return (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                style={
                                  snapshot.isDragging
                                    ? {
                                        background: 'white',
                                        ...provided.draggableProps.style,
                                      }
                                    : provided.draggableProps.style
                                }
                              >
                                <div className={styles.questions}>
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <div {...provided.dragHandleProps}>
                                      <MenuIcon className="w-5 " />
                                    </div>
                                    <p className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 px-3">
                                      {c.label}{' '}
                                      {c.admin_created
                                        ? '(Created by Admin)'
                                        : ''}
                                    </p>
                                  </div>

                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                    }}
                                  >
                                    <div
                                      className={styles.editButton}
                                      onClick={editCustomField(c)}
                                    >
                                      <PencilIcon className="w-5 " />
                                    </div>
                                    {!c.admin_created && (
                                      <div
                                        className={styles.removeButton}
                                        onClick={deleteCustomField(c)}
                                      >
                                        <TrashIcon className="w-5 " />
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )
                          }}
                        </Draggable>
                      ))
                    ) : (
                      <div className="-mt-4">
                        <EmptyState
                          header="No questions added"
                          subHeader="Please add new questions to continue"
                          btnTitle={'Add new Questions'}
                          onClick={addQuestion}
                        />
                      </div>
                    )}
                    {provided.placeholder}
                  </div>
                )
              }}
            </Droppable>
          </DragDropContext>
        </div>
      </form>
      <AddCustomFieldModal
        showModal={showModal}
        setShowModal={setShowModal}
        handleClose={() => {
          setShowModal(false)
        }}
        setCustomField={setCustomField}
        selectedCustomField={selectedCustomField}
        title={selectedCustomField ? 'Edit Custom Field' : 'Add Custom Field'}
      />
    </>
  )
}

export default CustomFields
