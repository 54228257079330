import React from 'react'

const TabView = (props) => {
  return (
    <div className="inline-flex justify-between w-full overflow-x-scroll mb-6 whitespace-nowrap">
      {props.tabs.map((tab, index) => (
        <div
          key={`${tab.id}${index}`}
          style={{ paddingBottom: 10 }}
          className={`
                    inline-flex justify-around
                    rounded-t border-b-2 w-full
                    border-gray-200 cursor-pointer px-10
    
                    ${
                      props.selectedTab === tab.id
                        ? 'text-green-600 border-b-2 border-green-600'
                        : ''
                    }
                `}
          onClick={() => props.setSelectedTab(tab.id)}
        >
          {tab.name}
        </div>
      ))}
    </div>
  )
}

export default TabView
