import React, { useState, useCallback, useEffect } from 'react'
import Input from 'Components/Input'
import Button from 'Components/Button'
import Modal from 'Components/Modal'
import TeamActions from 'Redux/TeamRedux'
import { useDispatch, useSelector } from 'react-redux'
import { TrashIcon } from '@heroicons/react/outline'
import store from 'store'

function AddTeamMemberModal(props) {
  const dispatch = useDispatch()
  const createFetching = useSelector((state) => state.team.createFetching)
  const settingsData = store.get('userData')
  const [teamMembers, setTeamMembers] = useState([
    { name: '', email: '', index: 0 },
  ])

  const onSave = () => {
    dispatch(
      TeamActions.teamCreateRequest({ team_members: teamMembers }, () => {
        props.handleClose()
        dispatch(TeamActions.teamsListRequest())
      })
    )
  }

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  const addTeamMember = () => {
    setTeamMembers([
      ...teamMembers,
      {
        name: '',
        index:
          teamMembers.length > 0
            ? teamMembers[teamMembers.length - 1].index + 1
            : 0,
        email: '',
      },
    ])
  }

  const setEmail = (index) => (value) => {
    setTeamMembers(
      teamMembers.map((o) => {
        if (o.index === index) {
          return {
            ...o,
            email: value,
          }
        }
        return o
      })
    )
  }

  const setName = (index) => (value) => {
    setTeamMembers(
      teamMembers.map((o) => {
        if (o.index === index) {
          return {
            ...o,
            name: value,
          }
        }
        return o
      })
    )
  }

  const checkDisabled = () => {
    let userData = store.get('userData')
    let emptyValue = teamMembers.find((t) => !t.email || !t.name)
    if (emptyValue) {
      return true
    }

    let avaliableSeats =
      settingsData.shop.seats - settingsData.shop.team_members_count

    if (
      avaliableSeats < teamMembers.length &&
      userData.setting.plan !== 'lifetime'
    ) {
      return true
    }

    let invalidEmail = teamMembers.find((t) => !validateEmail(t.email))
    if (invalidEmail) {
      return true
    }

    return false
  }

  const getFooter = () => {
    let userData = store.get('userData')

    let avaliableSeats =
      settingsData.shop.seats - settingsData.shop.team_members_count

    if (
      avaliableSeats < teamMembers.length &&
      userData.setting.plan !== 'lifetime'
    ) {
      return (
        <p style={{ color: 'red' }}>Please upgrade to add more team members</p>
      )
    }

    let emptyValue = teamMembers.find((t) => !t.email || !t.name)
    if (emptyValue) {
      return (
        <p style={{ color: 'red' }}>
          Please enter correct values in email or name field
        </p>
      )
    }

    let invalidEmail = teamMembers.find((t) => !validateEmail(t.email))
    if (invalidEmail) {
      return (
        <p style={{ color: 'red' }}>
          Please enter a valid email in one of the fields
        </p>
      )
    }

    let currentSeats = avaliableSeats - teamMembers.length
    return (
      <p>
        {currentSeats} {currentSeats > 1 ? 'seats' : 'seat'} available
      </p>
    )
  }

  const removeTeamMember = (index) => () => {
    setTeamMembers(teamMembers.filter((o) => o.index !== index))
  }

  return (
    <div>
      <Modal
        showModal={props.showModal}
        onClose={props.handleClose}
        title={props.title}
        primaryButton={{
          title: 'Save',
          onClick: onSave,
          disabled: checkDisabled(),
          loading: createFetching,
        }}
      >
        {teamMembers.map((t) => {
          return (
            <div className=" grid grid-cols-9 gap-y-6 gap-x-4 mb-4">
              <Input
                id={`teamMember`}
                label="Email"
                onChange={(value) => {
                  setEmail(t.index)(value)
                }}
                value={t.email}
                placeholder="Enter email"
                containerClass="col-span-4"
              />

              <Input
                id={`name`}
                label="Name"
                onChange={(value) => {
                  setName(t.index)(value)
                }}
                value={t.name}
                containerClass="col-span-4"
              />

              <div
                style={{
                  marginTop: '24px',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                className="col-span-1"
                onClick={removeTeamMember(t.index)}
              >
                <TrashIcon className="w-5" />
              </div>
            </div>
          )
        })}
        <div>
          <Button
            title="Add Team Member"
            onClick={addTeamMember}
            type="primary"
          />
        </div>
        <div style={{ marginTop: '10px' }}>{getFooter()}</div>
      </Modal>
    </div>
  )
}

export default AddTeamMemberModal
