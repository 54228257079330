import React from 'react'
import Modal from 'Components/Modal'

const ConfirmationModal = (props) => {
  return (
    <Modal
      open={props.showModal}
      showModal={props.showModal}
      onClose={props.onClose}
      title={props.title}
      primaryButton={{
        title: props.buttonText,
        onClick: props.onClick,
        loading: props.loading,
        type: props.buttonType || 'destructive',
      }}
    >
      <p className="text-sm">{props.text}</p>
    </Modal>
  )
}

export default ConfirmationModal
