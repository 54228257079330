import React from 'react'
import Button from './Button'

const AppointoPagination = ({
  offset,
  list,
  onPrevious,
  onNext,
  itemsPerPage,
}) => {
  const hasPrevious = offset > 0
  const hasNext = list.length === itemsPerPage

  return (
    <div className="flex items-center justify-center pt-5 pb-5 gap-5">
      <Button
        onClick={onPrevious}
        disabled={!hasPrevious}
        className={`mr-[10] text-sm rounded-sm  ${
          hasPrevious ? 'cursor-pointer' : 'cursor-not-allowed'
        } bg-gray-300 p-2`}
        title="Previous"
      />

      <Button
        onClick={onNext}
        disabled={!hasNext}
        className={`mr-[10] text-sm rounded-sm  ${
          hasNext ? 'cursor-pointer' : 'cursor-not-allowed'
        } bg-gray-300 p-2`}
        title="Next"
      />
    </div>
  )
}

export default AppointoPagination
