import { all, call, put, takeLatest, select } from 'redux-saga/effects'
import SettingsAction, {
  SettingsTypes,
  SettingsSelectors,
} from 'Redux/SettingsRedux'
import store from 'store'

export function* onUpdate(api, action) {
  const resp = yield call(api.updateSettings, action.params)
  if (resp.ok) {
    yield put(SettingsAction.settingsUpdateSuccess(resp.data))
    store.set('userData', resp.data)
    action.callback('success')
  } else {
    yield put(SettingsAction.settingsUpdateFailure('something went wrong'))
    action.callback('failure')
  }
}

export function* onUpgrade(api, action) {
  const resp = yield call(api.upgradePlan, action.params)
  if (resp.ok) {
    action.callback(resp.data, resp.data.shop)
  }
}

export function* onCancel(api, action) {
  console.log(action.params)
  const resp = yield call(api.cancelPlan, action.params)
  if (resp.ok) {
    action.callback()
  }
}

export function* onConnectCalendar(api, action) {
  const resp = yield call(api.connectGoogleCalendar, { code: action.code })
  if (resp.ok) {
    if (resp.data.status) {
      let userData = store.get('userData')
      store.set('userData', {
        ...userData,
        cal_connection: resp.data.status,
      })
      action.callback('success')
    } else {
      action.callback('failure')
    }
  }
}

export function* disconnectStripeAccount(api, action) {
  const resp = yield call(api.disconnectStripeAccount)
  if (resp.ok && resp.status) {
    action.callback('success')
  } else {
    action.callback('failure')
  }
}

export function* onRemoveZoom(api, action) {
  const resp = yield call(api.removeZoom)
  if (resp.ok) {
    let userData = store.get('userData')
    store.set('userData', {
      ...userData,
      zoom_detail: null,
    })
    action.callback('success')
  } else {
    action.callback('failure')
  }
}

export function* onTextSettingUpdate(api, action) {
  const resp = yield call(api.updateTextSetting, action.params)
}

export function* onRemoveCalendar(api, action) {
  const resp = yield call(api.removeGoogleCalendar)
  if (resp.ok) {
    let userData = store.get('userData')
    store.set('userData', {
      ...userData,
      cal_connection: {},
    })
    action.callback('success')
  } else {
    action.callback('failure')
  }
}

export function* onGenerateZapierApiToken(api, action) {
  const resp = yield call(api.onGenerateZapierApiToken)
  action.callback(resp.data)
}

export function* onGenerateApiToken(api, action) {
  const resp = yield call(api.onGenerateApiToken)
  action.callback(resp.data)
}

export function* connectAppleCalendarRequest(api, action) {
  const resp = yield call(api.connectAppleCalendar, action.params)
  if (resp.ok) {
    yield put(SettingsAction.connectAppleCalendarSuccess(resp.data))
    let userData = store.get('userData')
    store.set('userData', {
      ...userData,
      cal_connection: resp.data,
    })
  } else {
    yield put(
      SettingsAction.connectAppleCalendarFailure('something went wrong')
    )
  }
  action.callback(resp)
}

export function* disconnectAppleCalendarRequest(api, action) {
  const resp = yield call(api.disconnectAppleCalendarRequest)
  if (resp.ok) {
    yield put(SettingsAction.disconnectAppleCalendarSuccess(resp.data))
    let userData = store.get('userData')
    store.set('userData', {
      ...userData,
      cal_connection: {},
    })
  } else {
    yield put(
      SettingsAction.disconnectAppleCalendarFailure('something went wrong')
    )
  }
  action.callback(resp)
}

export function* onTeamMemberUpdate(api, action) {
  const resp = yield call(api.updateTeamMemberSetting, action.params)
  if (resp.ok) {
    yield put(SettingsAction.settingsUpdateSuccess(resp.data))
    action.callback(resp.data)
  } else {
    yield put(SettingsAction.settingsUpdateFailure('something went wrong'))
    action.callback()
  }
}

export function* downgradePlan(api, action) {
  const resp = yield call(api.downgradePlan)
  if (resp.ok) {
    action.callback('success')
  } else {
    yield put(SettingsAction.settingsPlanCancelFailure('something went wrong'))
    action.callback('failure')
  }
}

export default function* authSagas(api) {
  yield all([
    takeLatest(SettingsTypes.SETTINGS_UPDATE_REQUEST, onUpdate, api),
    takeLatest(SettingsTypes.SETTINGS_PLAN_UPGRADE_REQUEST, onUpgrade, api),
    takeLatest(SettingsTypes.SETTINGS_PLAN_CANCEL_REQUEST, onCancel, api),
    takeLatest(SettingsTypes.CONNECT_GOOGLE_CALENDAR, onConnectCalendar, api),
    takeLatest(SettingsTypes.DISCONNECT_GOOGLE_CALENDAR, onRemoveCalendar, api),
    takeLatest(SettingsTypes.DISCONNECT_ZOOM, onRemoveZoom, api),
    takeLatest(
      SettingsTypes.DISCONNECT_STRIPE_ACCOUNT,
      disconnectStripeAccount,
      api
    ),
    takeLatest(
      SettingsTypes.TEXT_SETTING_UPDATE_REQUEST,
      onTextSettingUpdate,
      api
    ),
    takeLatest(
      SettingsTypes.GENERATE_ZAPIER_API_TOKEN,
      onGenerateZapierApiToken,
      api
    ),
    takeLatest(SettingsTypes.GENERATE_API_TOKEN, onGenerateApiToken, api),
    takeLatest(
      SettingsTypes.CONNECT_APPLE_CALENDAR_REQUEST,
      connectAppleCalendarRequest,
      api
    ),
    takeLatest(
      SettingsTypes.DISCONNECT_APPLE_CALENDAR_REQUEST,
      disconnectAppleCalendarRequest,
      api
    ),
    takeLatest(
      SettingsTypes.SETTINGS_TEAM_MEMBER_UPDATE_REQUEST,
      onTeamMemberUpdate,
      api
    ),
    takeLatest(SettingsTypes.SETTINGS_PLAN_CANCEL_REQUEST, downgradePlan, api),
  ])
}
