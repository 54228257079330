import Card from 'Components/Card'
import Checkbox from 'Components/Checkbox'
import React from 'react'

function SelectActivator({
  selectedVariant,
  setSelectedVariant,
  connectedVariants,
}) {
  const Item = ({ item }) => {
    const { id, product_name, variant_name } = item
    return (
      <div>
        {(!selectedVariant.id || selectedVariant.id === id) && (
          <Checkbox
            title={`${product_name}-${variant_name}`}
            value={selectedVariant.id === id}
            onClick={onSelectionChange(item)}
          />
        )}
      </div>
    )
  }

  const onSelectionChange = (item) => (value) => {
    if (value) {
      setSelectedVariant(item)
    } else {
      setSelectedVariant({})
    }
  }

  return (
    <div style={{ maxHeight: '60vh' }}>
      <Card>
        {connectedVariants.length > 0 ? (
          <p>
            Select one of the variant for which you want to create the
            subscription booking
          </p>
        ) : (
          <p>
            Please choose another subscription. There are no activators for this
            subscription
          </p>
        )}

        <div className="mt-3">
          {connectedVariants.map((item) => (
            <div className="my-2">
              <Item item={item} />
            </div>
          ))}
        </div>
      </Card>
    </div>
  )
}

export default SelectActivator
