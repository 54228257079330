import Input from "Components/Input";
import Modal from "Components/Modal";
import React from "react";

function RemoveModal(props) {
  return (
    <div>
      <Modal
        showModal={props.showModal}
        onClose={props.handleClose}
        title={props.title}
        primaryButton={{
          title: props.btnTitle,
          onClick: props.onClick,
          loading: props.loading,
          type: "destructive",
        }}
      >
        {props.body}
      </Modal>
    </div>
  );
}

export default RemoveModal;
