import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'
import {
  listRequest,
  listSuccess,
  listFailure,
  detailRequest,
  detailSuccess,
  detailFailure,
} from './genericReducers'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  themesListRequest: ['params'],
  themesListSuccess: ['list'],
  themesListFailure: ['listError'],

  themesDetailRequest: ['id'],
  themesDetailSuccess: ['detailData'],
  themesDetailFailure: ['detailError'],

  themesFilesRequest: ['params', 'callback'],
  themesFileRequest: ['id', 'callback'],
  themesFileUpdateRequest: ['params', 'callback'],

  themesKeyReset: ['key'],
})

export const ThemesTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  list: [],
  listFetching: null,
  listError: null,
  listDataOffset: 0,

  calendarList: [],
  calendarListFetching: null,
  calendarListError: null,

  detailData: {},
  detailError: null,
  detailFetching: false,

  cancelError: null,
  cancelFetching: false,
})

/* ------------- Reducers ------------- */

export const reset = (state) => state.merge(INITIAL_STATE)

export const resetBasedOnKey = (state, { key }) => {
  return state.merge({
    [key]: INITIAL_STATE[key],
  })
}

/* ------------- Selectors ------------- */

export const ThemesSelectors = {}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.THEMES_LIST_REQUEST]: listRequest,
  [Types.THEMES_LIST_SUCCESS]: listSuccess,
  [Types.THEMES_LIST_FAILURE]: listFailure,

  [Types.THEMES_DETAIL_REQUEST]: detailRequest,
  [Types.THEMES_DETAIL_SUCCESS]: detailSuccess,
  [Types.THEMES_DETAIL_FAILURE]: detailFailure,

  [Types.THEMES_KEY_RESET]: resetBasedOnKey,
})
