import React from 'react'
import Modal from 'Components/Modal'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'

function BillingHistory({ showModal, setShowModal, billingHistory }) {
  const formatItems = (list) => {
    return list.map((l, i) => {
      let date = moment(l.billing_date).format('MMM Do YYYY, h:mm:ss a')
      let header = l.paid
        ? l.metadata.subscriptionBillingAttempt.order.name
        : 'Billing Attempt Failed'
      let body = l.paid
        ? 'Status: Successful'
        : `Status: Failed with error message ${l.error_message}`
      return (
        <>
          <h3>{header}</h3>
          <p>{body}</p>
          <p>{date}</p>
        </>
      )
    })
  }

  return (
    <Modal
      showModal={showModal}
      onClose={() => {
        setShowModal(false)
      }}
      title="Billing History"
      primaryButton={{
        title: 'Close',
        onClick: () => {
          setShowModal(false)
        },
      }}
      size="large"
    >
      <div style={{ color: '#202223' }}>{formatItems(billingHistory)}</div>
    </Modal>
  )
}
export default BillingHistory
