import Checkbox from 'Components/Checkbox'
import Input from 'Components/Input'
import Label from 'Components/Label'
import Modal from 'Components/Modal'
import Select from 'Components/Select'
import React, { useCallback, useState } from 'react'
import MultipleValueTextInput from 'react-multivalue-text-input'

let selectOptions = [
  { label: 'text', value: 'text' },
  { label: 'dropdown', value: 'dropdown' },
  { label: 'radio', value: 'radio' },
  { label: 'email', value: 'email' },
  { label: 'multi-select', value: 'multi-select' },
  { label: 'file-upload', value: 'file-upload' },
  { label: 'date', value: 'date' },
  { label: 'date-time', value: 'date-time' },
]

function AddCustomFieldModal(props) {
  let cf = props.selectedCustomField || {
    label: '',
    required: false,
    type: 'text',
    options: [],
  }
  const [question, setQuestion] = useState(cf.label)
  const [required, setRequired] = useState(cf.required)
  const [type, setType] = useState(cf.cf_type || selectOptions[0].value)
  const [options, setOptions] = useState(cf.options)

  const handleRequired = useCallback((newChecked) => {
    setRequired(newChecked)
  }, [])

  const handleSelectChange = (value) => {
    setType(value)
  }

  const checkDisabled = () => {
    if (!question) {
      return true
    }

    if (type === 'radio' || type === 'dropdown' || type === 'multi-select') {
      return options.length === 0
    }
    return false
  }

  return (
    <div>
      <Modal
        showModal={props.showModal}
        onClose={props.handleClose}
        title={props.title}
        primaryButton={{
          title: 'Save',
          onClick: () => {
            props.setCustomField({
              label: question,
              required: required,
              cf_type: type,
              options: options,
            })
            props.handleClose()
          },
          disabled: checkDisabled(),
        }}
      >
        <div className="gap-6">
          <Input
            label="Question"
            value={question}
            onChange={(value) => {
              setQuestion(value)
            }}
          />
        </div>
        <div className="my-4">
          <Select
            label="Custom Question Type"
            options={selectOptions}
            selected={type}
            setSelected={handleSelectChange}
            containerClass="col-span-full sm:col-span-2"
          />
        </div>

        {(type === 'radio' ||
          type === 'dropdown' ||
          type === 'multi-select') && (
          <div className="my-4">
            <MultipleValueTextInput
              onItemAdded={(item, allItems) => setOptions([...options, item])}
              onItemDeleted={(item, allItems) =>
                setOptions(options.filter((o) => o !== item))
              }
              label={<Label title="Options" />}
              name="item-input"
              placeholder="Enter whatever items you want; separate them with COMMA or ENTER."
              className="shadow-sm focus:ring-green-500 focus:border-green-500 block w-full sm:text-sm border-gray-300 rounded-md"
              values={options}
            />
          </div>
        )}

        <div className="my-4">
          <Checkbox
            name="required"
            title="Required"
            onClick={handleRequired}
            value={required}
          />
        </div>
      </Modal>
    </div>
  )
}

export default AddCustomFieldModal
