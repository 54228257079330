import { all, call, put, takeLatest, select } from 'redux-saga/effects'
import BookingAction, {
  BookingSelectors,
  BookingTypes,
} from '../Redux/BookingRedux'
import { ItemsPerPage } from '../Redux/genericReducers'

export function* getAll(api, action) {
  let params = action.params || {}

  if (!params.start_date || !params.end_date) {
    if (typeof action.listDataOffset !== 'undefined') {
      params.offset = action.listDataOffset
    } else {
      const listDataOffset = yield select(BookingSelectors.listDataOffset)
      params.offset = listDataOffset
    }
    params.limit = ItemsPerPage
  }

  const resp = yield call(api.getBookings, action.params)
  if (resp.ok) {
    yield put(BookingAction.bookingsListSuccess(resp.data))
  } else {
    yield put(BookingAction.bookingsListFailure('something went wrong'))
  }
}

export function* detailsRequest(api, action) {
  let apiCall = api.getBookingDetails
  if (!!action.cancelled) {
    apiCall = api.getCancelledBookingDetails
  }

  const resp = yield call(apiCall, {
    booking_id: action.id,
  })
  if (resp.ok) {
    let customers = []
    if (!!action.cancelled) {
      customers = resp.data.customers
    } else {
      customers = resp.data.customers.map((c) => {
        return {
          ...c,
          custom_fields: resp.data.custom_fields.filter(
            (cf) => cf.customer_id === c.id
          ),
        }
      })
    }

    yield put(
      BookingAction.bookingsDetailSuccess({
        ...resp.data,
        customers,
      })
    )
  } else {
    yield put(BookingAction.bookingsDetailFailure('something went wrong'))
  }
}

export function* onCancel(api, action) {
  const resp = yield call(api.cancelBooking, action.params)
  if (resp.ok) {
    yield put(BookingAction.bookingsCancelSuccess(resp.data))
    action.callback('success')
  } else {
    yield put(BookingAction.bookingsCancelFailure('something went wrong'))
  }
}

export function* onReschedule(api, action) {
  const resp = yield call(api.rescheduleBooking, action.params)
  if (resp.ok) {
    yield put(BookingAction.bookingsRescheduleSuccess(resp.data))
    action.callback(resp.data)
  } else {
    yield put(BookingAction.bookingsRescheduleFailure('something went wrong'))
  }
}

export function* getAllCalendarData(api, action) {
  const resp = yield call(api.getCalendarBookings, action.params)
  if (resp.ok) {
    yield put(BookingAction.bookingsCalendarListSuccess(resp.data))
  } else {
    yield put(BookingAction.bookingsCalendarListFailure('something went wrong'))
  }
}

export function* refundAmount(api, action) {
  const resp = yield call(api.refundAmount, action.params)
  if (resp.ok) {
    action.callback('success')
  } else {
    action.callback()
  }
}

export function* getCancelledBookings(api, action) {
  const resp = yield call(api.getCancelledBookings, action.params)
  if (resp.ok) {
    yield put(BookingAction.bookingsCancelListSuccess(resp.data))
  } else {
    yield put(BookingAction.bookingsCancelListFailure('something went wrong'))
  }
}

export function* getConflicts(api, action) {
  const resp = yield call(api.conflictsRequest, action.params)
  if (resp.ok) {
    yield put(BookingAction.conflictsSuccess(resp.data))
  } else {
    yield put(BookingAction.conflictsFailure('something went wrong'))
  }
}

export function* getWaitlist(api, action) {
  let params = action.params || {}

  if (!params.start_date || !params.end_date) {
    if (typeof action.listDataOffset !== 'undefined') {
      params.offset = action.listDataOffset
    } else {
      const listDataOffset = yield select(BookingSelectors.listDataOffset)
      params.offset = listDataOffset
    }
    params.limit = ItemsPerPage
  }
  const resp = yield call(api.getWaitlist, action.params)
  if (resp.ok) {
    yield put(BookingAction.bookingsWaitlistSuccess(resp.data))
  } else {
    yield put(BookingAction.bookingsWaitlistFailure('something went wrong'))
  }
}

export function* getAllSubscriptions(api, action) {
  const resp = yield call(api.getSubscriptions, action.params)
  if (resp.ok) {
    yield put(BookingAction.bookingsSubscriptionsListSuccess(resp.data))
  } else {
    yield put(
      BookingAction.bookingsSubscriptionsListFailure('something went wrong')
    )
  }
}

export function* deleteConflict(api, action) {
  const resp = yield call(api.deleteConflict, action.id)
  if (resp.ok) {
    action.callback()
  }
}

export function* subscriptionRequest(api, action) {
  const resp = yield call(api.getBookingSubscription, action.id)
  if (resp.ok) {
    yield put(BookingAction.bookingsSubscriptionSuccess(resp.data))
  } else {
    yield put(BookingAction.bookingsSubscriptionFailure('something went wrong'))
  }
}

export function* updateSubscriptionDetails(api, action) {
  const resp = yield call(api.updateSubscriptionDetails, action.params)
  if (resp.ok) {
    action.callback()
  } else {
    action.callback('failure')
  }
}

export function* subscriptionCancel(api, action) {
  const resp = yield call(api.deleteSubscription, action.id)
  if (resp.ok) {
    yield put(BookingAction.bookingsSubscriptionCancelSuccess(resp.data))
    action.callback()
  } else {
    yield put(
      BookingAction.bookingsSubscriptionCancelFailure('something went wrong')
    )
    action.callback()
  }
}

export function* bookingsByCustomer(api, action) {
  const resp = yield call(api.bookingsByCustomer, action.params)
  if (resp.ok) {
    action.callback(resp.data)
  } else {
    action.callback('failure')
  }
}

export function* connectBookingToSubscription(api, action) {
  const resp = yield call(api.connectBookingToSubscription, action.params)
  if (resp.ok) {
    action.callback()
  } else {
    action.callback('failure')
  }
}

export function* onEditBooking(api, action) {
  const resp = yield call(api.editBooking, action.id, action.params)
  if (resp.ok) {
    yield put(BookingAction.bookingsEditSuccess(resp.data))
    action.callback(resp.data)
  } else {
    yield put(BookingAction.bookingsEditFailure('something went wrong'))
  }
}

export function* onRestore(api, action) {
  const resp = yield call(api.restoreBooking, action.id, action.params)
  if (resp.ok) {
    yield put(BookingAction.bookingsRestoreSuccess(resp.data))
    action.callback(resp.data)
  } else {
    yield put(BookingAction.bookingsRestoreFailure('something went wrong'))
  }
}

export function* createNote(api, action) {
  const resp = yield call(api.createNote, action.id, action.params)
  if (resp.ok) {
    action.callback()
  } else {
    action.callback('failure')
  }
}

export function* deleteNote(api, action) {
  const resp = yield call(api.deleteNote, action.id, action.params)
  if (resp.ok) {
    action.callback()
  } else {
    action.callback('failure')
  }
}

export function* getAllSubscriptionPlans(api, action) {
  const resp = yield call(api.getSubscriptionPlans, action.params)
  if (resp.ok) {
    yield put(BookingAction.bookingsSubscriptionPlansSuccess(resp.data))
  } else {
    yield put(
      BookingAction.bookingsSubscriptionPlansFailure('something went wrong')
    )
  }
}

export function* generateBookingLink(api, action) {
  const resp = yield call(api.generateBookingLink, action.params)
  if (resp.ok) {
    action.callback(resp.data)
  }
}

export function* updateCustomer(api, action) {
  const resp = yield call(api.updateCustomer, action.id, action.params)
  if (resp.ok) {
    action.callback(action.params.count)
  } else {
    action.callback('failure')
  }
}

export function* createManualSubscription(api, action) {
  const resp = yield call(api.createManualSubscription, action.params)
  if (resp.ok) {
    action.callback(resp.data)
  }
}
export function* createCustomer(api, action) {
  const resp = yield call(api.createCustomer, action.params)
  if (resp.ok) {
    action.callback(resp.data)
  }
}
export function* getAllActivities(api, action) {
  const resp = yield call(api.getActivities, action.params)
  if (resp.ok) {
    yield put(BookingAction.bookingsActivitiesListSuccess(resp.data))
  } else {
    yield put(
      BookingAction.bookingsActivitiesListFailure('something went wrong')
    )
  }
}
export function* getCancelledActivities(api, action) {
  const resp = yield call(api.getCancelledActivities, action.params)
  if (resp.ok) {
    yield put(BookingAction.bookingsActivitiesCancelledListSuccess(resp.data))
  } else {
    yield put(
      BookingAction.bookingsActivitiesCancelledListFailure(
        'something went wrong'
      )
    )
  }
}

export default function* authSagas(api) {
  yield all([
    takeLatest(BookingTypes.BOOKINGS_LIST_REQUEST, getAll, api),
    takeLatest(BookingTypes.BOOKINGS_DETAIL_REQUEST, detailsRequest, api),
    takeLatest(BookingTypes.BOOKINGS_CANCEL_REQUEST, onCancel, api),
    takeLatest(BookingTypes.BOOKINGS_RESCHEDULE_REQUEST, onReschedule, api),
    takeLatest(
      BookingTypes.BOOKINGS_CALENDAR_LIST_REQUEST,
      getAllCalendarData,
      api
    ),
    takeLatest(BookingTypes.REFUND_AMOUNT, refundAmount, api),
    takeLatest(
      BookingTypes.BOOKINGS_CANCEL_LIST_REQUEST,
      getCancelledBookings,
      api
    ),
    takeLatest(BookingTypes.CONFLICTS_REQUEST, getConflicts, api),
    takeLatest(BookingTypes.BOOKINGS_WAITLIST_REQUEST, getWaitlist, api),
    takeLatest(
      BookingTypes.BOOKINGS_SUBSCRIPTIONS_LIST_REQUEST,
      getAllSubscriptions,
      api
    ),
    takeLatest(BookingTypes.DELETE_CONFLICT, deleteConflict, api),
    takeLatest(
      BookingTypes.BOOKINGS_SUBSCRIPTION_REQUEST,
      subscriptionRequest,
      api
    ),
    takeLatest(
      BookingTypes.UPDATE_SUBSCRIPTION_DETAILS,
      updateSubscriptionDetails,
      api
    ),
    takeLatest(
      BookingTypes.BOOKINGS_SUBSCRIPTION_CANCEL_REQUEST,
      subscriptionCancel,
      api
    ),
    takeLatest(BookingTypes.BOOKINGS_BY_CUSTOMER, bookingsByCustomer, api),
    takeLatest(
      BookingTypes.CONNECT_BOOKING_TO_SUBSCRIPTION,
      connectBookingToSubscription,
      api
    ),
    takeLatest(BookingTypes.BOOKINGS_EDIT_REQUEST, onEditBooking, api),
    takeLatest(BookingTypes.BOOKINGS_RESTORE_REQUEST, onRestore, api),
    takeLatest(BookingTypes.BOOKING_NOTE_CREATE_REQUEST, createNote, api),
    takeLatest(BookingTypes.BOOKING_NOTE_DELETE_REQUEST, deleteNote, api),

    takeLatest(
      BookingTypes.BOOKINGS_SUBSCRIPTION_PLANS_REQUEST,
      getAllSubscriptionPlans,
      api
    ),
    takeLatest(BookingTypes.GENERATE_BOOKING_LINK, generateBookingLink, api),
    takeLatest(BookingTypes.UPDATE_CUSTOMER, updateCustomer, api),
    takeLatest(
      BookingTypes.CREATE_MANUAL_SUBSCRIPTION,
      createManualSubscription,
      api
    ),
    takeLatest(
      BookingTypes.BOOKINGS_ACTIVITIES_LIST_REQUEST,
      getAllActivities,
      api
    ),
    takeLatest(
      BookingTypes.BOOKINGS_ACTIVITIES_CANCELLED_LIST_REQUEST,
      getCancelledActivities,
      api
    ),
    takeLatest(BookingTypes.CREATE_CUSTOMER, createCustomer, api),
  ])
}
