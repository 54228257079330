import React, { useState, useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import CustomerActions from 'Redux/CustomerRedux'
import Spinner from 'Components/Spinner'
import Checkbox from 'Components/Checkbox'
import useDebounce from 'Hooks/debounce'
import Card from 'Components/Card'
import CardSection from 'Components/CardSection'
import Input from 'Components/Input'

function SelectCustomer({ selectedCustomer, setSelectedCustomer }) {
  const dispatch = useDispatch()
  const [queryValue, setQueryValue] = useState('')
  const customersFetching = useSelector((state) => state.customer.listFetching)
  const customersList = useSelector((state) => state.customer.list)

  const filters = []
  const appliedFilters = []

  const debouncedQueryValue = useDebounce(queryValue, 300)

  const handleQueryValueRemove = useCallback(() => {
    setQueryValue('')
    dispatch(CustomerActions.customersListRequest({}, 0))
  }, [dispatch])

  const handleFiltersClearAll = useCallback(() => {
    handleQueryValueRemove()
  }, [handleQueryValueRemove])

  const handleFiltersQueryChange = useCallback((value) => {
    setQueryValue(value)
  }, [])

  useEffect(() => {
    if (debouncedQueryValue.length >= 3) {
      dispatch(
        CustomerActions.customersListRequest(
          {
            search_term: debouncedQueryValue,
          },
          0
        )
      )
    }
    if (debouncedQueryValue.length === 0) {
      dispatch(CustomerActions.customersListRequest({}, 0))
    }
  }, [debouncedQueryValue, dispatch])

  useEffect(() => {
    dispatch(CustomerActions.customersListRequest({}, 0))
  }, [dispatch])

  const Item = ({ item }) => {
    const { id, name, email } = item
    return (
      <div className="my-2">
        <Checkbox
          title={name}
          value={selectedCustomer.id === id}
          onClick={onSelectionChange(item)}
        />
        <p className="pl-6">{email}</p>
      </div>
    )
  }

  const onSelectionChange = (item) => (value) => {
    if (value) {
      setSelectedCustomer(item)
    } else {
      setSelectedCustomer({})
    }
  }

  return (
    <div style={{ maxHeight: '60vh' }}>
      <Card>
        <CardSection>
          <Input
            label=""
            value={queryValue}
            placeholder="Filter items by customer name or email"
            onChange={(value) => handleFiltersQueryChange(value)}
          />
          {customersList && customersList.length > 0 && (
            <p className="my-2">
              Showing top {customersList.length} customer. Please use{' '}
              <strong>Search</strong> to get your desired customer
            </p>
          )}
        </CardSection>
        {customersFetching ? (
          <div>
            <Spinner
              accessibilityLabel="Fetching customers"
              size="large"
              color="teal"
              style={{ height: 100 }}
            />
          </div>
        ) : customersList && customersList.length > 0 ? (
          <>
            <div className="max-h-80 overflow-y-scroll">
              {customersList.map((item) => (
                <Item key={item.id} item={item} />
              ))}
            </div>
          </>
        ) : queryValue.length > 0 ? (
          <p>
            No Customer Found. Please add a customer for manual bookings or
            change the search term
          </p>
        ) : (
          <p>
            Uh-Oh! Looks like you do not have any customers. Please add a
            customer to your shopify store{' '}
          </p>
        )}
      </Card>
    </div>
  )
}

export default SelectCustomer
