import { PlusIcon, TrashIcon } from '@heroicons/react/outline'
import Modal from 'Components/Modal'
import moment from 'moment'
import React, { useState } from 'react'
import Select from 'react-select'
import styles from 'Styles/WeeklyHours.module.css'
import { DefaultTimeSlot, generateTimeOptions } from 'Themes/constants'
import DatePicker from './DatePicker'

const selectStyles = {
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
  singleValue: (provided, state) => {
    return { ...provided, fontSize: 13 }
  },
  option: (base) => ({
    ...base,
    fontSize: 12,
  }),
}

function BreakOverrideModal(props) {
  let timeOptions = generateTimeOptions(15)

  const [startDate, setStartDate] = useState(new Date())
  const [breakOverrides, setBreakOverrides] = useState([])

  const onChange = (selectedDates) => {
    const start = selectedDates
    setStartDate(selectedDates)

    let selectedOverride = props.breakOverride.find(
      (o) => o.day === moment(start).format('YYYY-MM-DD')
    )

    if (!!selectedOverride) {
      setBreakOverrides(selectedOverride.slots)
    } else if (breakOverrides.length == 0) {
      setBreakOverrides([DefaultTimeSlot])
    }
  }

  const handleStartSelectChange = (i) => (option) => {
    let tempOverride = [...breakOverrides]
    tempOverride[i] = {
      start_time: option.value,
      end_time: tempOverride[i].end_time,
    }
    setBreakOverrides(tempOverride)
  }

  const handleEndSelectChange = (i) => (option) => {
    let tempOverride = [...breakOverrides]
    tempOverride[i] = {
      start_time: tempOverride[i].start_time,
      end_time: option.value,
    }
    setBreakOverrides(tempOverride)
  }

  const addOption = () => {
    let tempOverride = [...breakOverrides, DefaultTimeSlot]
    setBreakOverrides(tempOverride)
  }

  const removeOption = (i) => () => {
    let tempOverride = [...breakOverrides]
    tempOverride = tempOverride.filter((_, index) => index !== i)
    setBreakOverrides(tempOverride)
  }

  return (
    <Modal
      showModal={props.showModal}
      onClose={props.handleClose}
      title="Select the date you want to assign specific hours"
      primaryButton={{
        title: 'Apply',
        onClick: () => {
          let ovrds = []

          ovrds.push({
            day: moment(startDate).format('YYYY-MM-DD'),
            slots: breakOverrides,
          })

          props.onClick(ovrds)
        },
        disabled: !startDate,
      }}
    >
      <div style={{ padding: 20 }}>
        <DatePicker
          selected={startDate}
          onChange={onChange}
          nextMonthButtonLabel=">"
          previousMonthButtonLabel="< "
          inline
        />
        {startDate && !!breakOverrides && (
          <>
            <div
              className={styles.timeContainer}
              style={{ alignItems: 'flex-start' }}
            >
              <p style={{ marginTop: 20, marginBottom: 10 }}>
                Add break hours below :
              </p>
              {breakOverrides.length === 0 && (
                <p className={styles.unavailable}>Unavailable</p>
              )}
              {breakOverrides.map((s, i) => {
                return (
                  <div key={`${s}_${i}`} className={styles.selectContainer}>
                    <Select
                      label=""
                      className={styles.select}
                      classNamePrefix="select"
                      isSearchable
                      options={timeOptions}
                      onChange={handleStartSelectChange(i)}
                      value={timeOptions.find((t) => s.start_time === t.value)}
                      defaultValue={DefaultTimeSlot.start_time}
                      menuPortalTarget={document.body}
                      styles={selectStyles}
                    />
                    <p className={styles.dash}>-</p>
                    <Select
                      label=""
                      className={styles.select}
                      classNamePrefix="select"
                      isSearchable
                      options={timeOptions}
                      onChange={handleEndSelectChange(i)}
                      value={timeOptions.find((t) => s.end_time === t.value)}
                      defaultValue={DefaultTimeSlot.end_time}
                      menuPortalTarget={document.body}
                      styles={selectStyles}
                    />
                    <div
                      className={styles.removeButton}
                      onClick={removeOption(i)}
                    >
                      <TrashIcon className="w-5" />
                    </div>
                  </div>
                )
              })}
              <div
                style={{ position: 'absolute', right: 26 }}
                className={styles.addContainer}
                onClick={addOption}
              >
                <PlusIcon className="w-5" />
              </div>
            </div>
          </>
        )}
      </div>
    </Modal>
  )
}

export default BreakOverrideModal
