import Modal from 'Components/Modal'
import React from 'react'

function ViewBillingInfo(props) {
  let c = props.customer || {}
  let headerClass =
    'block text-sm font-medium text-gray-500 sm:mt-px sm:pt-2 break-words'
  let valueClass = 'block text-sm  text-gray-900 sm:mt-px sm:pt-2 break-words'
  return (
    <div>
      <Modal
        showModal={props.showModal}
        onClose={props.handleClose}
        title="Billing Info"
      >
        {!!c.stripe_payload && !!c.stripe_payload.data && (
          <div style={{ marginTop: 30 }}>
            <div style={{ marginBottom: 10 }}>
              <p className={headerClass}>Stripe Billing Details</p>
              <p className={valueClass}>
                Billing Email and Name :{' '}
                {c.stripe_payload.data.object.customer_details.name} (
                {c.stripe_payload.data.object.customer_details.email}
                <br />
                {!!c.stripe_payload && !!c.stripe_payload.data
                  ? `Amount Paid:  ${c.stripe_payload.data.object.currency.toUpperCase()} ${
                      c.stripe_payload.data.object.amount_total / 100
                    }`
                  : ''}
                )
              </p>
            </div>
          </div>
        )}
      </Modal>
    </div>
  )
}

export default ViewBillingInfo
