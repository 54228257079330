import { all, call, put, takeLatest } from "redux-saga/effects";
import ServiceAction, { ServiceTypes } from "Redux/ServiceRedux";

export function* getAll(api, action) {
  let params = {};
  const resp = yield call(api.getServices, params);
  if (resp.ok) {
    yield put(ServiceAction.servicesListSuccess(resp.data));
  } else {
    yield put(ServiceAction.servicesListFailure("something went wrong"));
  }
}

export function* upsertService(api, action) {
  const resp = yield call(api.upsertServices, action.params);
  if (resp.ok) {
    yield put(ServiceAction.servicesUpsertSuccess(resp.data));
    action.callback(resp.data);
  } else {
    yield put(ServiceAction.servicesUpsertFailure("something went wrong"));
    action.callback();
  }
}

export function* detailsRequest(api, action) {
  const resp = yield call(api.getServiceDetails, action.id);
  if (resp.ok) {
    yield put(ServiceAction.servicesDetailSuccess(resp.data));
  } else {
    yield put(ServiceAction.servicesDetailFailure("something went wrong"));
  }
}

export function* deleteService(api, action) {
  const resp = yield call(api.deleteService, action.productId);
  if (resp.ok) {
    yield put(ServiceAction.servicesDeleteSuccess());
    action.callback();
  } else {
    yield put(ServiceAction.servicesDeleteFailure());
    action.callback();
  }
}

export default function* appointmentSagas(api) {
  yield all([
    takeLatest(ServiceTypes.SERVICES_LIST_REQUEST, getAll, api),
    takeLatest(ServiceTypes.SERVICES_UPSERT_REQUEST, upsertService, api),
    takeLatest(ServiceTypes.SERVICES_DETAIL_REQUEST, detailsRequest, api),
    takeLatest(ServiceTypes.SERVICES_DELETE_REQUEST, deleteService, api),
  ]);
}
