import Modal from 'Components/Modal'
import React from 'react'

function ViewQuestionsModal(props) {
  return (
    <div>
      <Modal
        showModal={props.showModal}
        onClose={props.handleClose}
        title="View Questions"
      >
        {props.customer &&
          props.customer.custom_fields &&
          props.customer.custom_fields.map((cf, i) => {
            if (!cf.value || !cf.label) return null
            return (
              <div
                className={
                  'block text-sm  text-gray-900 sm:mt-px sm:pt-2 break-words'
                }
              >
                <p>
                  {i + 1}. {cf.label}
                </p>
                <p style={{ margin: '6px 14px' }}>{cf.value}</p>
              </div>
            )
          })}
      </Modal>
    </div>
  )
}

export default ViewQuestionsModal
