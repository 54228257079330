import Button from 'Components/Button'
import CardHeading from 'Components/CardHeading'
import Container from 'Components/Container'
import Heading from 'Components/Heading'
import Input from 'Components/Input'
import Select from 'Components/Select'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import GeneralSettings from './GeneralSettings'
import store from 'store'
import { bundleUrl, getPublicUrl } from 'Themes/constants'
import SettingsActions from 'Redux/SettingsRedux'
import Checkbox from 'Components/Checkbox'
import DateOverrides from 'Components/DateOverrides'
import WeeklyHours from 'Components/WeeklyHours'
import Toast from 'Components/Toast'
import axios from 'axios'
import BreakOverrides from 'Components/BreakOverrides'

const tabs = [{ name: 'General', component: GeneralSettings, current: true }]

export const selectOptions = [
  { label: 'Australia', value: 'AU' },
  { label: 'Austria', value: 'AT' },
  { label: 'Belgium', value: 'BE' },
  { label: 'Brazil', value: 'BR' },
  { label: 'Bulgaria', value: 'BG' },
  { label: 'Canada', value: 'CA' },
  { label: 'Croatia', value: 'HR' },
  { label: 'Cyprus', value: 'CY' },
  { label: 'Czech Republic', value: 'CZ' },
  { label: 'Denmark', value: 'DK' },
  { label: 'Estonia', value: 'EE' },
  { label: 'Finland', value: 'FI' },
  { label: 'France', value: 'FR' },
  { label: 'Germany', value: 'DE' },
  { label: 'Gibraltar', value: 'GI' },
  { label: 'Greece', value: 'GR' },
  { label: 'Hong Kong', value: 'HK' },
  { label: 'Hungary', value: 'HU' },
  { label: 'India', value: 'IN' },
  { label: 'Indonesia', value: 'ID' },
  { label: 'Ireland', value: 'IE' },
  { label: 'Italy', value: 'IT' },
  { label: 'Japan', value: 'JP' },
  { label: 'Latvia', value: 'LV' },
  { label: 'Liechtenstein', value: 'LI' },
  { label: 'Lithuania', value: 'LT' },
  { label: 'Luxembourg', value: 'LU' },
  { label: 'Malaysia', value: 'MY' },
  { label: 'Malta', value: 'MT' },
  { label: 'Mexico ', value: 'MX' },
  { label: 'Netherlands', value: 'NL' },
  { label: 'New Zealand', value: 'NZ' },
  { label: 'Norway', value: 'NO' },
  { label: 'Poland', value: 'PL' },
  { label: 'Portugal', value: 'PT' },
  { label: 'Romania', value: 'RO' },
  { label: 'Singapore', value: 'SG' },
  { label: 'Slovakia', value: 'SK' },
  { label: 'Slovenia', value: 'SI' },
  { label: 'Spain', value: 'ES' },
  { label: 'Sweden', value: 'SE' },
  { label: 'Switzerland', value: 'CH' },
  { label: 'Thailand', value: 'TH' },
  { label: 'United Arab Emirates', value: 'AE' },
  { label: 'United Kingdom', value: 'GB' },
  { label: 'United States', value: 'US' },
]

const Settings = (props) => {
  const dispatch = useDispatch()
  const [selectedTab, setSelectedTab] = useState(tabs[0])
  const [name, setName] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [logo, setLogo] = useState('')
  const [subdomain, setSubdomain] = useState('')
  const [desc, setDesc] = useState('')
  const [email, setEmail] = useState('')
  const [demoLink, setDemoLink] = useState('')
  const [shopUuid, setShopUuid] = useState('')
  const [role, setRole] = useState('')
  const [shopifyUser, setShopifyUser] = useState(false)
  const [activateTextNotification, setActivateTextNotification] =
    useState(false)
  const [activateWhatsappNotification, setActivateWhatsappNotification] =
    useState(false)
  const [enableBusinessHours, setEnableBusinessHours] = useState(false)
  const [override, setOverride] = useState([])
  const [breakOverride, setBreakOverride] = useState([])
  const [weeklyHours, setWeeklyHours] = useState([])
  const [color, setColor] = useState('')
  const [showPaidToast, setShowPaidToast] = useState(false)
  const [showToast, setShowToast] = useState(false)
  const [content, setContent] = useState('')
  const [country, setCountry] = useState('US')
  const [markOnline, setMarkOnline] = useState(false)
  const [phone, setPhone] = useState('')

  const updateFetching = useSelector((state) => state.setting.updateFetching)
  let userData = store.get('userData')
  useEffect(() => {
    let userData = store.get('userData')

    if (userData.shop.flow === 'shopify') {
      setMarkOnline(userData.online)
      setWeeklyHours(userData.team_member_setting.weekly_availability)
      setOverride(userData.team_member_setting.overridden_availability)
      setBreakOverride(userData.team_member_setting.break_availability)
      setEnableBusinessHours(
        userData.team_member_setting.enable_global_availability
      )
      setPhone(
        userData.team_member_setting ? userData.team_member_setting.phone : ''
      )
    } else {
      setShopUuid(userData.shop.shop_uuid)
      setDemoLink(getPublicUrl())
      setName(userData.name || '')
      setEmail(userData.email || '')
      setCompanyName(userData.shop.owner_name || '')
      setLogo(userData.shop.logo)
      setSubdomain(userData.shop.subdomain)
      setDesc(userData.shop.description || '')
      setRole(userData.role || '')
      setWeeklyHours(userData.setting.weekly_availability)
      setOverride(userData.setting.overridden_availability)
      setBreakOverride(userData.setting.break_availability)
      setEnableBusinessHours(userData.setting.enable_global_availability)
      setActivateTextNotification(userData.text_setting.activate)
      setActivateWhatsappNotification(userData.text_setting.activate_whatsapp)
      setColor(userData.shop.color)
      setMarkOnline(userData.online)
    }
    setShopifyUser(userData.shop.flow === 'shopify')
  }, [])

  const onSave = () => {
    let userData = store.get('userData')
    if (shopifyUser) {
      dispatch(
        SettingsActions.settingsTeamMemberUpdateRequest(
          {
            setting: {
              weekly_availability: weeklyHours,
              overridden_availability: override,
              enable_global_availability: enableBusinessHours,
              break_availability: breakOverride,
              phone: phone,
            },
            online: markOnline,
          },
          () => {}
        )
      )
    } else {
      dispatch(
        SettingsActions.settingsUpdateRequest(
          {
            shop: {
              owner_name: companyName,
              description: desc,
              color: color,
              logo: logo,
              subdomain: subdomain,
            },
            tm: {
              name: name,
              online: markOnline,
            },
            setting: {
              weekly_availability: weeklyHours,
              overridden_availability: override,
              enable_global_availability: enableBusinessHours,
              break_availability: breakOverride,
            },
            text_setting: {
              id: userData.text_setting.id,
              activate: activateTextNotification,
              activate_whatsapp: activateWhatsappNotification,
            },
          },
          () => {}
        )
      )
    }
  }

  return (
    <div>
      <Toast
        show={showPaidToast}
        setShow={setShowPaidToast}
        title="Please upgrade your plan to use this feature"
        type="info"
      />

      <Toast
        show={showToast}
        setShow={setShowToast}
        title={content}
        type="error"
      />

      <Heading
        title="Settings"
        rightSection={
          <Button
            title={'Save'}
            onClick={onSave}
            type="primary"
            loading={updateFetching}
          />
        }
      />

      {!shopifyUser && (
        <>
          <Container containerClass="my-6">
            <form className="space-y-6">
              <CardHeading title="General Profile" />
            </form>

            <div className="grid grid-cols-4 gap-6 my-6">
              <Input
                label="Name"
                placeholder="Enter your name"
                containerClass="col-span-full sm:col-span-2"
                value={name}
                onChange={(value) => {
                  setName(value)
                }}
              />
            </div>

            <div className="grid grid-cols-4 gap-6 my-6">
              <Input
                label="Email"
                placeholder="Enter your email"
                containerClass="col-span-full sm:col-span-2"
                value={email}
                onChange={(value) => {
                  setEmail(value)
                }}
              />
            </div>

            <div className="grid grid-cols-4 gap-6 my-6">
              <Input
                label="Company Name"
                placeholder="Enter your company name"
                containerClass="col-span-full sm:col-span-2"
                value={companyName}
                onChange={(value) => {
                  setCompanyName(value)
                }}
                disabled={role !== 'admin'}
              />
            </div>

            <div className="grid grid-cols-4 gap-6 my-6">
              <Input
                label="Logo"
                placeholder="Enter your logo"
                containerClass="col-span-full sm:col-span-2"
                value={logo}
                onChange={(value) => {
                  setLogo(value)
                }}
                disabled={role !== 'admin'}
              />
            </div>

            <div className="grid grid-cols-4 gap-6 my-6">
              <Input
                label="Subdomain"
                placeholder="Enter your subdomain"
                containerClass="col-span-full sm:col-span-2"
                value={subdomain}
                onChange={(value) => {
                  setSubdomain(value)
                }}
                disabled={role !== 'admin'}
              />
            </div>

            <div className="grid grid-cols-4 gap-6 my-6">
              <Input
                label="Company Description"
                placeholder="Enter your company description"
                containerClass="col-span-full sm:col-span-2"
                value={desc}
                onChange={(value) => {
                  setDesc(value)
                }}
                lines={5}
                disabled={role !== 'admin'}
              />
            </div>
          </Container>
        </>
      )}

      {!shopifyUser && (
        <Container containerClass="my-6">
          <form className="space-y-6">
            <CardHeading title="Global Settings" />
          </form>

          <div className="grid grid-cols-4 gap-6 my-6">
            <Checkbox
              name="text-notification"
              title="Activate Text Notifications"
              onClick={(checked) => {
                setActivateTextNotification(checked)
              }}
              value={activateTextNotification}
            />
          </div>

          <div className="grid grid-cols-4 gap-6 my-6">
            <Checkbox
              name="whatsapp-notification"
              title="Activate Whatsapp Notifications"
              onClick={(checked) => {
                setActivateWhatsappNotification(checked)
              }}
              value={activateWhatsappNotification}
            />
          </div>

          <div className="grid grid-cols-4 gap-6 my-6">
            <Input
              label="Theme color"
              placeholder="Enter the theme color you want to show in the preview link"
              containerClass="col-span-full sm:col-span-2"
              value={color}
              onChange={(value) => {
                setColor(value)
              }}
              disabled={role !== 'admin'}
              helpText="Only available in paid plans"
            />
          </div>
        </Container>
      )}

      <Container containerClass="my-6">
        <form className="space-y-6">
          <CardHeading
            title={
              shopifyUser
                ? 'Working Hours'
                : role === 'member'
                ? 'Team Member Working Hours'
                : 'Business Hours'
            }
          />

          <div className="grid sm:grid-cols-2 gap-6 my-6">
            {shopifyUser ? (
              <Checkbox
                name="working-hours"
                title={
                  'Enable Working Hours for all services (Only applicable for Single Day Booking)'
                }
                subTitle={
                  'Please note: Enabling working hours will enable it for all your services'
                }
                onClick={(checked) => {
                  setEnableBusinessHours(checked)
                }}
                value={enableBusinessHours}
              />
            ) : (
              <Checkbox
                name="business-hours"
                title={
                  role === 'member'
                    ? 'Enable Team Member Working Hours'
                    : 'Enable Business Hours'
                }
                subTitle={`Please note: Enabling ${
                  role === 'member' ? 'team member' : 'business'
                } hours will enable it for all your services. All your existing schedule will be converted to ${
                  role === 'member' ? 'team member' : 'business'
                } hours`}
                onClick={(checked) => {
                  setEnableBusinessHours(checked)
                }}
                value={enableBusinessHours}
              />
            )}
          </div>

          <div className="flex md:flex-row flex-col">
            <WeeklyHours
              setWeeklyHours={setWeeklyHours}
              weeklyHours={weeklyHours}
              config={{}}
            />
            <DateOverrides setOverride={setOverride} override={override} />
          </div>
        </form>
      </Container>
      <Container containerClass="my-6">
        <CardHeading title="Add Break Hours" />

        <div className="my-5">
          <BreakOverrides
            setBreakOverride={setBreakOverride}
            breakOverride={breakOverride}
          />
        </div>
      </Container>

      {!shopifyUser && (
        <Container containerClass="my-6">
          <form className="space-y-6">
            <CardHeading
              title="Code Snippet"
              subTitle="Copy this snippet in your website to get started right away"
            />
          </form>

          <div className="my-6">
            <div className="w-full md:max-w-3xl mx-auto px-4 sm:px-6">
              <div
                className="overflow-hidden shadow rounded-lg"
                style={{ backgroundColor: 'rgb(31, 45, 61)' }}
              >
                <div className="px-4 py-5 sm:px-6">
                  <h3 className="text-xl leading-6 font-medium text-white">
                    Try our sample embed code
                  </h3>
                  <div className="mt-2 max-w-xl text-sm text-gray-100">
                    <p>
                      Embed appointment scheduling in your website in 1 minute
                    </p>
                  </div>
                  <div className="my-5 rounded-lg bg-gray-900 border border-gray-700 text-base">
                    <pre
                      className="text-sm"
                      style={{
                        color: 'rgb(197, 200, 198)',
                        textShadow: 'rgba(0, 0, 0, 0.3) 0px 1px',
                        fontFamily:
                          'Inconsolata, Monaco, Consolas, "Courier New", Courier, monospace',
                        direction: 'ltr',
                        textAlign: 'left',
                        whiteSpace: 'pre',
                        wordSpacing: 'normal',
                        wordBreak: 'normal',
                        lineHeight: '1.5',
                        tabSize: 4,
                        hyphens: 'none',
                        padding: '1em',
                        margin: '0.5em 0px',
                        overflow: 'auto',
                        borderRadius: '0.3em',
                        background: 'rgb(29, 31, 33)',
                      }}
                    >
                      <code
                        className="language-javascript"
                        style={{
                          color: 'rgb(197, 200, 198)',
                          textShadow: 'rgba(0, 0, 0, 0.3) 0px 1px',
                          fontFamily:
                            'Inconsolata, Monaco, Consolas, "Courier New", Courier, monospace',
                          direction: 'ltr',
                          textAlign: 'left',
                          whiteSpace: 'pre',
                          wordSpacing: 'normal',
                          wordBreak: 'normal',
                          lineHeight: '1.5',
                          tabSize: 4,
                          hyphens: 'none',
                        }}
                      >
                        <span
                          className="token"
                          style={{ color: 'rgb(237, 237, 237)' }}
                        >
                          &lt;
                        </span>
                        <span>script type</span>
                        <span
                          className="token"
                          style={{ color: 'rgb(237, 237, 237)' }}
                        >
                          =
                        </span>
                        <span
                          className="token"
                          style={{ color: 'rgb(168, 255, 96)' }}
                        >
                          "text/javascript"
                        </span>
                        <span
                          className="token"
                          style={{ color: 'rgb(237, 237, 237)' }}
                        >
                          &gt;
                        </span>
                        <span>{'\n'}</span> var div =
                        document.createElement('div');
                        <span>{'\n'}</span> div.id = 'appointo';
                        <span>{'\n'}</span> document.body.appendChild(div);
                        <span>{'\n'}</span> window.appointo.config ={' '}
                        <span>{`{ shop_uuid: '${shopUuid}', type: 'embed', querySelector: '#appointo'}`}</span>
                        <span>{'\n'}</span>
                        <span
                          className="token"
                          style={{ color: 'rgb(237, 237, 237)' }}
                        >
                          &lt;
                        </span>
                        <span
                          className="token"
                          style={{ color: 'rgb(237, 237, 237)' }}
                        >
                          /
                        </span>
                        <span>script</span>
                        <span
                          className="token"
                          style={{ color: 'rgb(237, 237, 237)' }}
                        >
                          &gt;
                        </span>
                        <span>{'\n'}</span>
                        <span
                          className="token"
                          style={{ color: 'rgb(237, 237, 237)' }}
                        >
                          &lt;
                        </span>
                        <span>script type</span>
                        <span
                          className="token"
                          style={{ color: 'rgb(237, 237, 237)' }}
                        >
                          =
                        </span>
                        <span
                          className="token"
                          style={{ color: 'rgb(168, 255, 96)' }}
                        >
                          "text/javascript"
                        </span>
                        <span> src</span>
                        <span
                          className="token"
                          style={{ color: 'rgb(237, 237, 237)' }}
                        >
                          =
                        </span>
                        <span
                          className="token"
                          style={{ color: 'rgb(168, 255, 96)' }}
                        >
                          "{bundleUrl}"
                        </span>
                        <span
                          className="token"
                          style={{ color: 'rgb(237, 237, 237)' }}
                        >
                          &gt;
                        </span>
                        <span
                          className="token"
                          style={{ color: 'rgb(237, 237, 237)' }}
                        >
                          &lt;
                        </span>
                        <span
                          className="token"
                          style={{ color: 'rgb(237, 237, 237)' }}
                        >
                          /
                        </span>
                        <span>script</span>
                        <span
                          className="token"
                          style={{ color: 'rgb(237, 237, 237)' }}
                        >
                          &gt;
                        </span>
                      </code>
                    </pre>
                  </div>
                  <div className="mt-5 flex">
                    <a
                      href={demoLink}
                      target="_blank"
                      className="inline-flex items-center justify-center px-4 py-2 border border-transparent font-medium rounded-md text-yellow-700 bg-yellow-100 hover:bg-yellow-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500 sm:text-sm ml-2"
                    >
                      Live demo
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      )}
      {shopifyUser && (
        <Container containerClass="my-6">
          <div>
            <CardHeading title="Mark Yourself Online/Offline" />
            <div className="mt-5">
              <Checkbox
                name="mark-online"
                title="Mark Online"
                onClick={(checked) => {
                  setMarkOnline(checked)
                }}
                value={markOnline}
              />
            </div>
            <p className="text-sm text-gray-400 my-2">
              Enabling this will mark yourself as online and will show to your
              clients that you are online to take appointments right now
            </p>
          </div>
          <div className="flex gap-10 border-t-2 pt-5">
            <div className="w-2/3">
              <CardHeading title="Phone Number" />
              <p className="text-sm text-gray-400">
                The phone number in which you would like to recieve
                text/whatsapp notifications
              </p>
            </div>
            <div className="mt-5 w-1/3">
              <Input
                label=""
                placeholder="Enter Phone Number"
                helpText="Please add the correct phone number. Text/Whatsapp Notification will be sent to this number if enabled"
                value={phone}
                onChange={(value) => {
                  if (value.length <= 10) {
                    setPhone(value)
                  }
                }}
              />
            </div>
          </div>
        </Container>
      )}
    </div>
  )
}

export default Settings
