import React, { useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import BookingActions from 'Redux/BookingRedux'
import Modal from 'Components/Modal'
import SelectCustomer from 'Components/Bookings/Subscriptions/SelectCustomer'
import SelectActivator from 'Components/Bookings/Subscriptions/SelectActivator'
import SelectSubscription from 'Components/Bookings/Subscriptions/SelectSubscription'

function ManualSubscriptionModal(props) {
  const dispatch = useDispatch()
  const [subscription, setSubscription] = useState({})
  const [selectedVariant, setSelectedVariant] = useState({})
  const [selectedCustomer, setSelectedCustomer] = useState({})
  const [currentPage, setCurrentPage] = useState('selectSubscription')
  const [connectedVariants, setConnectedVariants] = useState([])
  const [loading, setLoading] = useState(false)

  const handleNext = () => {
    if (currentPage === 'selectSubscription') {
      setConnectedVariants(subscription.selling_plan_activators)
      setCurrentPage('selectActivator')
    } else if (currentPage === 'selectActivator') {
      setCurrentPage('selectCustomer')
    } else if (currentPage === 'selectCustomer') {
      let params = {
        subscription_id: subscription.id,
        appointment_id: selectedVariant.id,
        customer_id: selectedCustomer.id,
        name: `${selectedCustomer.first_name} ${selectedCustomer.last_name}`,
        email: selectedCustomer.email,
        product_name: selectedVariant.product_name,
        variant_name: selectedVariant.variant_name,
        product_uuid: selectedVariant.product_uuid,
        variant_uuid: selectedVariant.variant_uuid,
      }

      setLoading(true)
      dispatch(
        BookingActions.createManualSubscription(params, (data) => {
          setLoading(false)
          handleClose()
          dispatch(BookingActions.bookingsSubscriptionsListRequest())
        })
      )
    }
  }

  const checkDisabled = () => {
    if (currentPage === 'selectSubscription') {
      if (!subscription.id) {
        return true
      }
    }

    if (currentPage === 'selectActivator') {
      if (!selectedVariant.id) {
        return true
      }
    }

    if (currentPage === 'selectCustomer') {
      if (!selectedCustomer.id) {
        return true
      }
    }

    return false
  }

  const handleClose = useCallback(() => {
    props.setShowModal(false)
  }, [props])

  return (
    <div>
      <Modal
        open={props.showModal}
        showModal={props.showModal}
        onClose={handleClose}
        title={'Create Subscription'}
        primaryButton={{
          title:
            currentPage === 'selectCustomer' ? 'Create Subscription' : 'Next',
          onClick: handleNext,
          loading: loading,
          disabled: checkDisabled(),
        }}
      >
        {currentPage === 'selectSubscription' && (
          <SelectSubscription
            subscription={subscription}
            setSubscription={setSubscription}
          />
        )}

        {currentPage === 'selectActivator' && (
          <SelectActivator
            selectedVariant={selectedVariant}
            setSelectedVariant={setSelectedVariant}
            connectedVariants={connectedVariants}
          />
        )}

        {currentPage === 'selectCustomer' && (
          <SelectCustomer
            selectedCustomer={selectedCustomer}
            setSelectedCustomer={setSelectedCustomer}
          />
        )}
      </Modal>
    </div>
  )
}

export default ManualSubscriptionModal
