import React from 'react'

const Card = (props) => {
  return (
    <div class="flex w-full flex-col bg-white border shadow-sm rounded-xl">
      <div class="p-2 md:p-5">
        <h3 class="text-md font-bold text-gray-800 mb-3">{props.title}</h3>
        {props.children}
      </div>
    </div>
  )
}

export default Card
