import Input from 'Components/Input'
import Modal from 'Components/Modal'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import store from 'store'
import styles from 'Styles/Integrations.module.css'

const AppleCalendarModal = (props) => {
  const loading = useSelector((state) => state.setting.appleCalUpdateFetching)
  const userData = store.get('userData')

  return (
    <Modal
      showModal={props.showModal}
      onClose={() => props.setShowModal(false)}
      title={'Connect Apple Calendar'}
      primaryButton={{
        loading: loading,
        title:
          userData.cal_connection &&
          userData.cal_connection.status !== 'success'
            ? 'Connect'
            : 'Disconnect',
        onClick: props.action,
        disabled:
          userData.cal_connection &&
          userData.cal_connection.status !== 'success'
            ? props.appleFormData?.email?.length === 0 ||
              props.appleFormData?.password?.length === 0
            : false,
      }}
    >
      <div>
        {userData.cal_connection.email &&
        userData.cal_connection.calendar_type === 'apple' ? (
          userData.cal_connection.status === 'error' ? (
            <p className={styles.text}>
              There was an error with the Calendar. Please disconnect and
              connect your calendar
            </p>
          ) : (
            <div>
              <p>Email - {userData.cal_connection.email}</p>
              <p>Calendar - Home</p>
            </div>
          )
        ) : (
          <div>
            <p className={styles.text}>
              Eliminate the constant need to go back and forth while managing
              your appointment. Simple connect your apple calendar and manage
              your appointments from apple Calendar itself
            </p>
            <p>
              Here is how you can generate{' '}
              <strong> App Specific Password -</strong>{' '}
              <a
                className={styles.ext_link}
                href="https://support.apple.com/en-in/102654"
                target="_blank"
              >
                Click here
              </a>
            </p>
            <div className={styles.apple_cal_form_container}>
              <Input
                label="Email"
                containerClass="col-span-full sm:col-span-2"
                value={props.appleFormData?.email}
                onChange={(value) => props.setAppleData('email')(value)}
                type="email"
                placeholder="Enter email"
              />
              <Input
                label="App Specific Password"
                containerClass="col-span-full sm:col-span-2 mt-5"
                placeholder="Enter Password"
                type="password"
                value={props.appleFormData?.password}
                onChange={(value) => props.setAppleData('password')(value)}
              />
            </div>
          </div>
        )}
        <p className={styles.text}>
          P.S. - By default Appointo allows 2 way sync. Where it adds any
          appointment to the apple calendar and also reads the apple calendar
          events to block your time in Appointo. If you wish to disable and
          enable only 1 way sync. Please contact support
        </p>
      </div>
    </Modal>
  )
}

export default AppleCalendarModal
