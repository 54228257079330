const ImageLinks = {
  Integration: {
    zapierLogo:
      "https://static.intercomassets.com/appstore/images/icons/zapier.png",
    apiLogo:
      "https://cdn.shopify.com/app-store/listing_images/b3dccf14643e2c76121cb5c5ca9a068e/icon/CLiykKz0lu8CEAE=.png",
  },
};

export default ImageLinks;
