import Heading from 'Components/Heading'
import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import SettingsActions from 'Redux/SettingsRedux'
import store from 'store'
import Toast from 'Components/Toast'
import { MANAGE_THEME } from 'Routes/routes'
import ManageThemeModal from 'Components/Modals/ManageThemeModal'

const Themes = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [showToast, setShowToast] = useState(false)
  const [content, setContent] = useState('')
  const [themes, setThemes] = useState([{ name: 'Dawn' }])
  const [showModal, setShowModal] = useState(false)

  const goToTheme = () => {
    setShowModal(true)
  }

  return (
    <div>
      <Heading title="Themes" />
      <ul className="grid grid-cols-2 gap-6 sm:grid-cols-2 lg:grid-cols-3 my-6">
        {themes.map((i) => (
          <li
            key={i.name}
            className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200"
            onClick={goToTheme}
          >
            <div className="w-full flex items-center justify-between p-6 space-x-6">
              <div className="flex-1 truncate">
                <div className="flex items-center space-x-3">
                  <h3 className="text-gray-900 text-sm font-medium truncate">
                    {i.name}
                  </h3>
                </div>
              </div>
              {/* <img
                className="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0 object-cover"
                src={i.imageUrl}
                alt=""
              /> */}
            </div>
          </li>
        ))}
      </ul>
      {showModal && (
        <ManageThemeModal
          showModal={showModal}
          setShowModal={setShowModal}
          shop={props.shop}
        />
      )}
    </div>
  )
}

export default Themes
