import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  loginRequest: ['params', 'callback'],
  signupRequest: ['params', 'callback'],
  forgotPasswordRequest: ['params', 'callback'],
  googleLoginRequest: ['params', 'callback'],
  wixLoginRequest: ['queryString', 'callback'],
  adminLoginRequest: ['queryString', 'callback'],
  redirectedLoginRequest: ['queryString', 'callback'],
  meRequest: ['callback'],
  masqueradeRequest: ['params','callback'],
})

export const AuthTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({})

/* ------------- Reducers ------------- */

export const reset = (state) => state.merge(INITIAL_STATE)

export const resetBasedOnKey = (state, { key }) => {
  return state.merge({
    [key]: INITIAL_STATE[key],
  })
}

/* ------------- Selectors ------------- */

export const AuthSelectors = {}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {})
