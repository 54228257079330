export const ItemsPerPage = 50

// successful data lookup
export const success = (state, action) => {
  const { data } = action
  return state.merge({
    fetching: false,
    error: null,
    data: data.results || data,
  })
}

export const listRequest = (state, { listParams, listDataOffset }) => {
  return state.merge({
    listParams,
    listFetching: true,
    listError: null,
    listDataOffset: listDataOffset || 0,
  })
}

export const listSuccess = (state, { list }) => {
  return state.merge({
    list,
    listFetching: false,
  })
}

export const listFailure = (state, { listError }) => {
  return state.merge({ listError, listFetching: false })
}

export const createRequest = (state, { createParams }) => {
  return state.merge({ createParams, createFetching: true, createError: null })
}

export const createSuccess = (state, { createData }) => {
  return state.merge({ createData, createFetching: false })
}

export const createFailure = (state, { createError }) => {
  return state.merge({ createError, createFetching: false })
}

export const updateRequest = (state, { updateParams }) => {
  return state.merge({ updateParams, updateFetching: true, updateError: null })
}

export const updateSuccess = (state, { updateData }) => {
  return state.merge({ updateData, updateFetching: false })
}

export const updateFailure = (state, { updateError }) => {
  return state.merge({ updateError, updateFetching: false })
}

export const deleteRequest = (state) => {
  return state.merge({ deleteFetching: true, deleteError: null })
}

export const deleteSuccess = (state, { deleteData }) => {
  return state.merge({ deleteData, deleteFetching: false })
}

export const deleteFailure = (state, { deleteError }) => {
  return state.merge({ deleteError, deleteFetching: false })
}

export const setObject = (state, { data }) => {
  return state.merge({ data })
}

export const setList = (state, { list }) => {
  return state.merge({ list })
}

export const detailRequest = (state) => {
  return state.merge({ detailFetching: true, detailError: null })
}

export const detailSuccess = (state, { detailData }) => {
  return state.merge({ detailData, detailFetching: false })
}

export const detailFailure = (state, { detailError }) => {
  return state.merge({ detailError, detailFetching: false })
}

export const upsertRequest = (state) => {
  return state.merge({ upsertFetching: true, upsertError: null })
}

export const upsertSuccess = (state, {}) => {
  return state.merge({ upsertFetching: false })
}

export const upsertFailure = (state, { upsertError }) => {
  return state.merge({ upsertError, upsertFetching: false })
}
