import { all, call, put, takeLatest, select } from 'redux-saga/effects'
import ThemesAction, { ThemesTypes } from '../Redux/ThemesRedux'
import { ItemsPerPage } from '../Redux/genericReducers'

// export function* getAll(api, action) {
//   const resp = yield call(api.getThemes, action.params)
//   if (resp.ok) {
//     yield put(ThemesAction.themesListSuccess(resp.data))
//   } else {
//     yield put(ThemesAction.themesListFailure('something went wrong'))
//   }
// }

// export function* detailsRequest(api, action) {
//   const resp = yield call(api.getThemeDetails, action.id)
//   if (resp.ok) {
//     yield put(ThemesAction.themesDetailSuccess(resp.data))
//   } else {
//     yield put(ThemesAction.themesDetailFailure('something went wrong'))
//   }
// }

export function* filesRequest(api, action) {
  const resp = yield call(api.getThemeFiles)
  if (resp.ok) {
    action.callback(resp.data)
  }
}

export function* fileRequest(api, action) {
  const resp = yield call(api.getThemeFileDetails, action.id)
  if (resp.ok) {
    action.callback(resp.data)
  }
}

export function* fileUpdateRequest(api, action) {
  const resp = yield call(api.updateThemeFile, action.params)
  if (resp.ok) {
    action.callback(resp.data)
  }
}

export default function* authSagas(api) {
  yield all([
    // takeLatest(ThemesTypes.THEMES_LIST_REQUEST, getAll, api),
    takeLatest(ThemesTypes.THEMES_FILES_REQUEST, filesRequest, api),
    takeLatest(ThemesTypes.THEMES_FILE_REQUEST, fileRequest, api),
    takeLatest(ThemesTypes.THEMES_FILE_UPDATE_REQUEST, fileUpdateRequest, api),
  ])
}
