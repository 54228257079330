import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from 'Components/Button'
import Container from 'Components/Container'
import ServiceActions from 'Redux/ServiceRedux'
import SettingsActions from 'Redux/SettingsRedux'
import store from 'store'
import Spinner from 'Components/Spinner'
import AuthAction from 'Redux/AuthRedux'
import { TrackEvent } from 'Themes/constants'
import styles from 'Styles/Onboarding.module.css'

const Step4 = (props) => {
  const dispatch = useDispatch()
  const [productId, setProductId] = useState('')
  const [productInternalName, setProductInternalName] = useState('')
  const servicesFetching = useSelector((state) => state.service.listFetching)
  const [loading, setLoading] = useState(false)
  const servicesList = useSelector((state) => state.service.list)

  useEffect(() => {
    if (servicesList.length > 0) {
      setProductId(servicesList[0].product_uuid)
      setProductInternalName(servicesList[0].internal_name)
    }
  }, [servicesList])

  useEffect(() => {
    dispatch(ServiceActions.servicesListRequest())
  }, [])

  const onClickPreview = () => {
    let userData = store.get('userData')
    window.open(
      // `${window.location.origin}/appointments/${userData.shop.shop_uuid}/product/${productId}`
      `${window.location.origin}/shop/${userData.shop.subdomain}/product/${productInternalName}`
    )
  }

  return (
    <>
      {servicesFetching ? (
        <Spinner />
      ) : (
        <div className="flex items-center justify-center">
          <div
            className="bg-white p-6  rounded-lg ring-1 ring-gray-900 ring-opacity-5 overflow-hidden"
            style={{ width: '90%' }}
          >
            <div className="flex justify-center flex-col">
              <p className="text-2xl font-medium">Congratulations</p>

              <div className="flex flex-col pt-5">
                <h3 className="text-md font-medium text-base">
                  Schedule a 1:1 call with us, We will help you -
                </h3>
                <ul
                  className="pl-5"
                  style={{
                    listStyle: 'disc',
                  }}
                >
                  <li className="text-sm">
                    To Set Up and Embed Appointo on Your Website with Ease
                  </li>
                  <li className="text-sm">
                    Responding to Your Feature Requests and Feedback: Our Team
                    Rapidly Implements New Features to Meet Your Needs
                  </li>
                </ul>
              </div>

              <iframe
                src="https://dashboard.appointo.me/shop/appointo/product/sidepanda-support-meeting-(free)"
                height="800"
                width="100%"
                title="Book Call With Us"
                className="mt-7 mb-5"
                allow="clipboard-write"
              ></iframe>

              <div>
                <div className="my-4 flex justify-center">
                  <Button
                    title="Test Your Service Live"
                    onClick={onClickPreview}
                    type="primary"
                    className="justify-center"
                  />
                  <Button
                    title="Finish Onboarding"
                    onClick={() => {
                      setLoading(true)
                      TrackEvent('OB Finish Onboarding', {})
                      dispatch(
                        SettingsActions.settingsUpdateRequest(
                          {
                            setting: { step: 0, onboarding: true },
                          },
                          () => {
                            dispatch(
                              AuthAction.meRequest(() => {
                                window.location.href = '/dashboard'
                              })
                            )
                          }
                        )
                      )
                    }}
                    type="default"
                    className="justify-center mx-4"
                    loading={loading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Step4
