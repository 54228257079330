import Card from 'Components/Card'
import Checkbox from 'Components/Checkbox'
import Spinner from 'Components/Spinner'
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import BookingActions from 'Redux/BookingRedux'

function SelectSubscription({ subscription, setSubscription }) {
  const dispatch = useDispatch()

  const subscriptionsList = useSelector((state) => state.booking.list)
  const subscriptionsFetching = useSelector(
    (state) => state.booking.listFetching
  )

  useEffect(() => {
    dispatch(BookingActions.bookingsSubscriptionPlansRequest())
  }, [])

  const Item = ({ item }) => {
    const { id, name } = item
    return (
      <div>
        {(!subscription.id || subscription.id === id) && (
          <Checkbox
            title={name}
            value={subscription.id === id}
            onClick={onSelectionChange(item)}
          />
        )}
      </div>
    )
  }

  const onSelectionChange = (item) => (value) => {
    if (value) {
      setSubscription(item)
    } else {
      setSubscription({})
    }
  }

  return (
    <div style={{ maxHeight: '60vh' }}>
      <Card>
        <p>
          Select one of the subscription for which you want to create a
          subscription booking{' '}
          <strong>(Only one time subscription is allowed)</strong>
        </p>
        {subscriptionsFetching ? (
          <Spinner />
        ) : (
          <div className="mt-3">
            {subscriptionsList.map((item) => (
              <div className="my-2">
                {!!item.allow_one_time && <Item item={item} />}
              </div>
            ))}
          </div>
        )}
      </Card>
    </div>
  )
}

export default SelectSubscription
