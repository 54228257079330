import React, { useState, useCallback, useEffect } from 'react'
import Spinner from 'Components/Spinner'
import { useDispatch } from 'react-redux'
import AuthAction from 'Redux/AuthRedux'

export default function PublicUrl() {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  useEffect(() => {
    let queryString = window.location.search
    setLoading(true)
    dispatch(
      AuthAction.wixLoginRequest(queryString, (resp, err) => {
        setLoading(false)
        if (!!err) {
          setError(err)
        } else {
          window.location.href = '/dashboard'
        }
      })
    )
  }, [])

  return (
    <div id="appointo">
      {error && <p>{error}</p>}
      {loading && <Spinner />}
    </div>
  )
}
