export const StepperItem = ({ header, body, subText }) => {
  return (
    <li class="mb-10 ms-6">
      <span class="absolute flex items-center justify-center w-5 h-5 bg-green-200 rounded-full -start-4 ring-4 ring-white dark:ring-gray-900 dark:bg-green-900 mt-4"></span>
      <div style={{ paddingLeft: '50px' }}>
        {header && <h3 className="text-md font-semibold">{header}</h3>}
        {body && <p className="text-sm">{body}</p>}
        {subText && <p className="text-xs text-gray-500">{subText}</p>}
      </div>
    </li>
  )
}

const Stepper = ({ children }) => {
  return (
    <ol class="relative text-gray-500 border-s border-gray-200 dark:border-gray-700 dark:text-gray-400">
      {children}
    </ol>
  )
}
export default Stepper
