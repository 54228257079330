import { Dialog, Menu, Transition } from '@headlessui/react'
import {
  CalendarIcon,
  MenuAlt2Icon,
  UsersIcon,
  XIcon,
  TagIcon,
  CogIcon,
  ExternalLinkIcon,
  CurrencyDollarIcon,
  RefreshIcon,
  CollectionIcon,
  PuzzleIcon,
} from '@heroicons/react/outline'
import React, { Fragment, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { logo_url, getInitials, getPublicUrl } from 'Themes/constants'
import {
  BOOKINGS,
  CALENDAR,
  DASHBOARD,
  INTEGRATIONS,
  PLANS,
  SERVICES,
  SETTINGS,
  TEAMS,
  THEMES,
  TRANSLATIONS,
  WORKFLOWS,
  ONBOARDING,
  SUBSCRIPTIONS,
  CUSTOMERS,
  WAITLIST,
  CONFLICT,
} from '../Routes/routes'
import { useHistory } from 'react-router-dom'
import store from 'store'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import NavigationActions from 'Redux/NavigationRedux'
import { useLocation } from 'react-router-dom'

var classNames = require('classnames')

const navigationNonThinkific = [
  // { name: 'Dashboard', href: '/dashboard', icon: HomeIcon, current: true },
  {
    name: 'Services',
    href: DASHBOARD,
    icon: TagIcon,
  },
  {
    name: 'Bookings',
    href: BOOKINGS,
    icon: CollectionIcon,
    children: [
      { id: 'upcoming', name: 'Upcoming Bookings', href: 'bookings' },
      { id: 'past', name: 'Past Bookings', href: 'bookings' },
      {
        id: 'cancelled',
        name: 'Cancelled Bookings',
        href: 'bookings',
      },
      { id: 'customers', name: 'Customers', href: CUSTOMERS },
    ],
  },
  {
    name: 'Calendar',
    href: CALENDAR,
    icon: CalendarIcon,
  },
  {
    name: 'Workflows',
    href: WORKFLOWS,
    icon: RefreshIcon,
  },
  {
    name: 'Integrations',
    href: INTEGRATIONS,
    icon: PuzzleIcon,
  },
  { name: 'Teams', href: TEAMS, icon: UsersIcon },
  // { name: 'Themes', href: THEMES, icon: UsersIcon },
  { name: 'Plans', href: PLANS, icon: CurrencyDollarIcon },
  { name: 'Settings', href: SETTINGS, icon: CogIcon },
  // {
  //   name: 'Changelog',
  //   href: 'https://headwayapp.co/appointo-app-changelog',
  //   icon: BookmarkIcon,
  //   outsideUrl: true,
  // },
  // {
  //   name: 'Translations',
  //   href: TRANSLATIONS,
  //   icon: TranslateIcon,
  // },
  {
    name: 'Public Link',
    href: getPublicUrl(),
    icon: ExternalLinkIcon,

    outsideUrl: true,
  },
]

const navigationThinkific = [
  // { name: 'Dashboard', href: '/dashboard', icon: HomeIcon, current: true },
  {
    name: 'Services',
    href: DASHBOARD,
    icon: TagIcon,
  },
  {
    name: 'Bookings',
    href: BOOKINGS,
    icon: CollectionIcon,
    children: [
      { id: 'upcoming', name: 'Upcoming Bookings', href: 'bookings' },
      { id: 'past', name: 'Past Bookings', href: 'bookings' },
      {
        id: 'cancelled',
        name: 'Cancelled Bookings',
        href: 'bookings',
      },
      { id: 'customers', name: 'Customers', href: CUSTOMERS },
    ],
  },
  {
    name: 'Calendar',
    href: CALENDAR,
    icon: CalendarIcon,
  },
  {
    name: 'Workflows',
    href: WORKFLOWS,
    icon: RefreshIcon,
  },
  {
    name: 'Integrations',
    href: INTEGRATIONS,
    icon: PuzzleIcon,
  },

  { name: 'Teams', href: TEAMS, icon: UsersIcon },
  { name: 'Plans', href: PLANS, icon: CurrencyDollarIcon },
  { name: 'Settings', href: SETTINGS, icon: CogIcon },
  // {
  //   name: 'Changelog',
  //   href: 'https://headwayapp.co/appointo-app-changelog',
  //   icon: BookmarkIcon,
  //   outsideUrl: true,
  // },
  {
    name: 'Help',
    href: 'https://blog.appointo.me/integrate-appointo-into-your-thinkific-site/',
    icon: ExternalLinkIcon,
    outsideUrl: true,
  },
]

const onboardingNavigation = [
  {
    name: 'Onboarding',
    href: ONBOARDING,
    icon: UsersIcon,
  },
  {
    name: 'Services',
    href: DASHBOARD,
    icon: TagIcon,
  },
  {
    name: 'Bookings',
    href: BOOKINGS,
    icon: CollectionIcon,
    children: [
      { id: 'upcoming', name: 'Upcoming Bookings', href: 'bookings' },
      { id: 'past', name: 'Past Bookings', href: 'bookings' },
      {
        id: 'cancelled',
        name: 'Cancelled Bookings',
        href: 'bookings',
      },
      { id: 'customers', name: 'Customers', href: CUSTOMERS },
    ],
  },
  {
    name: 'Calendar',
    href: CALENDAR,
    icon: CalendarIcon,
  },
  {
    name: 'Workflows',
    href: WORKFLOWS,
    icon: RefreshIcon,
  },
  {
    name: 'Integrations',
    href: INTEGRATIONS,
    icon: PuzzleIcon,
  },
  { name: 'Teams', href: TEAMS, icon: UsersIcon },
  // { name: 'Themes', href: THEMES, icon: UsersIcon },
  { name: 'Plans', href: PLANS, icon: CurrencyDollarIcon },
  { name: 'Settings', href: SETTINGS, icon: CogIcon },
  // {
  //   name: 'Changelog',
  //   href: 'https://headwayapp.co/appointo-app-changelog',
  //   icon: BookmarkIcon,
  //   outsideUrl: true,
  // },
  // {
  //   name: 'Translations',
  //   href: TRANSLATIONS,
  //   icon: TranslateIcon,
  // },
  {
    name: 'Public Link',
    href: getPublicUrl(),
    icon: ExternalLinkIcon,

    outsideUrl: true,
  },
]

const navigationExpired = [
  {
    name: 'Services',
    href: DASHBOARD,
    icon: TagIcon,
  },

  { name: 'Plans', href: PLANS, icon: CurrencyDollarIcon },
]
const shopifyNavigation = [
  {
    name: 'Services',
    href: DASHBOARD,
    icon: TagIcon,
  },
  {
    name: 'Bookings',
    href: BOOKINGS,
    icon: CollectionIcon,
    children: [
      { id: 'upcoming', name: 'Upcoming Bookings', href: 'bookings' },
      { id: 'past', name: 'Past Bookings', href: 'bookings' },
      { id: 'subscription', name: 'Subscription', href: SUBSCRIPTIONS },
      { id: 'conflicts', name: 'Conflicts', href: CONFLICT },
      {
        id: 'cancelled',
        name: 'Cancelled Bookings',
        href: 'bookings',
      },
      { id: 'customers', name: 'Customers', href: CUSTOMERS },
      { id: 'waitlist', name: 'Waitlist', href: WAITLIST },
    ],
  },
  {
    name: 'Calendar',
    href: CALENDAR,
    icon: CalendarIcon,
  },
  {
    name: 'Integrations',
    href: INTEGRATIONS,
    icon: PuzzleIcon,
  },
  { name: 'Settings', href: SETTINGS, icon: CogIcon },
]

export default function Sidebar(props) {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const history = useHistory()
  const [currentIndex, setCurrentIndex] = useState(0)
  let thinkificSubdomain = store.get('thinkific_subdoamin')

  const location = useLocation()

  let settingsData = store.get('userData')
  const navigation =
    settingsData.shop.flow === 'shopify'
      ? shopifyNavigation
      : settingsData.shop.campaign === 'thinkific'
      ? navigationThinkific
      : !settingsData.setting.onboarding
      ? onboardingNavigation
      : navigationNonThinkific

  const dispatch = useDispatch()
  const currentTab = useSelector((state) => state.navigation.currentTab)
  const userNavigation = [
    {
      name: 'Your Profile',
      onClick: () => {
        history.push(SETTINGS)
      },
    },
    {
      name: 'Sign out',
      onClick: () => {
        store.clearAll()
        history.push('/login')
      },
    },
  ]

  useEffect(() => {
    const currentPath = location.pathname
    navigation.forEach((item, index) => {
      if (item.href === currentPath) {
        if (item.children && item.children.length > 0) {
          setCurrentIndex(index)
          dispatch(NavigationActions.setNavigation(item.children[0].id))
        } else {
          setCurrentIndex(index)
          dispatch(NavigationActions.setNavigation(item.id))
        }
      } else if (item.children) {
        item.children.forEach((child) => {
          if (child.href === currentPath) {
            setCurrentIndex(index)
            dispatch(NavigationActions.setNavigation(child.id))
          }
        })
      }
    })
  }, [location.pathname, navigation, dispatch])

  const renderLink = (item, index, body) => (
    <Link
      key={item.name}
      to={item.href}
      onClick={() => {
        setCurrentIndex(index)
        item.onClick && item.onClick()
        setSidebarOpen(false)
      }}
      className={classNames(
        currentIndex === index
          ? 'bg-green-100 text-gray-900'
          : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
        'group flex items-center px-2 py-2 text-base font-medium rounded-md'
      )}
    >
      {body}
    </Link>
  )

  const renderChildren = (children, parentIndex) => (
    <>
      {currentIndex ? (
        <div className="w-full">
          {children.map((child, childIndex) => (
            <div
              className={`my-2 ${
                currentTab === child.id && parentIndex === currentIndex
                  ? 'bg-green-100'
                  : ''
              }`}
              key={child.name}
            >
              {child.href ? (
                <Link
                  to={child.href}
                  onClick={() => {
                    setCurrentIndex(parentIndex)
                    dispatch(NavigationActions.setNavigation(child.id))
                  }}
                  className={`hover:text-gray-900 my-1 pl-8 ${
                    currentTab === child.id && parentIndex === currentIndex
                      ? 'font-medium'
                      : ''
                  }`}
                >
                  {child.name}
                </Link>
              ) : (
                <button
                  onClick={() => {
                    setCurrentIndex(parentIndex)
                    setSidebarOpen(false)
                    dispatch(NavigationActions.setNavigation(child.id))
                  }}
                  className={`hover:text-gray-900 my-1 pl-8 ${
                    currentTab === child.id ? 'font-medium' : ''
                  }`}
                >
                  {child.name}
                </button>
              )}
            </div>
          ))}
        </div>
      ) : (
        ''
      )}
    </>
  )

  return (
    <div className="h-screen flex overflow-hidden bg-gray-100">
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed inset-0 flex z-40 md:hidden"
          open={sidebarOpen}
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-shrink-0 flex items-center px-4">
                <img className="h-14 w-auto" src={logo_url} alt="Workflow" />
              </div>
              <div className="mt-5 flex-1 h-0 overflow-y-auto">
                <nav className="px-2 space-y-1">
                  {navigation.map((item, i) => {
                    let current = currentIndex === i
                    let body = (
                      <>
                        <item.icon
                          className={classNames(
                            current
                              ? 'text-gray-500'
                              : 'text-gray-400 group-hover:text-gray-500',
                            'mr-4 flex-shrink-0 h-6 w-6'
                          )}
                          aria-hidden="true"
                        />
                        {item.name}
                      </>
                    )

                    return (
                      <div key={item.name}>
                        {item.outsideUrl ? (
                          <a
                            href={item.href}
                            className={classNames(
                              current
                                ? 'bg-green-100 text-gray-900'
                                : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                              'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                            )}
                            target="_blank"
                          >
                            {body}
                          </a>
                        ) : (
                          renderLink(item, i, body)
                        )}
                        {item.children && renderChildren(item.children, i)}
                      </div>
                    )
                  })}
                </nav>
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14" aria-hidden="true"></div>
        </Dialog>
      </Transition.Root>

      <div className="hidden md:flex md:flex-shrink-0">
        <div className="flex flex-col w-64">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex flex-col flex-grow border-r border-gray-200 pt-5 pb-4 bg-white overflow-y-auto">
            <div className="flex items-center flex-shrink-0 px-4">
              <img className="h-14 w-auto" src={logo_url} alt="Appointo" />
            </div>
            <div className="mt-5 flex-grow flex flex-col">
              <nav className="px-2 space-y-1">
                {navigation.map((item, i) => {
                  let current = currentIndex === i
                  let body = (
                    <>
                      <item.icon
                        className={classNames(
                          current
                            ? 'text-gray-500'
                            : 'text-gray-400 group-hover:text-gray-500',
                          'mr-4 flex-shrink-0 h-6 w-6'
                        )}
                        aria-hidden="true"
                      />
                      {item.name}
                    </>
                  )

                  return (
                    <div key={item.name}>
                      {item.outsideUrl ? (
                        <a
                          href={item.href}
                          className={classNames(
                            current
                              ? 'bg-green-100 text-gray-900'
                              : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                            'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                          )}
                          target="_blank"
                        >
                          {body}
                        </a>
                      ) : (
                        renderLink(item, i, body)
                      )}
                      {item.children && renderChildren(item.children, i)}
                    </div>
                  )
                })}
              </nav>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <div className="relative z-10 flex-shrink-0 flex h-16 bg-white shadow">
          <button
            className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500 md:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
          </button>

          <div className="flex-1 px-4 flex justify-between">
            <div className="flex-1 flex">
              {settingsData.shop.campaign === 'thinkific' && (
                <p className="mt-6">
                  Please have a look at this video to understand how you can
                  integrate your thinkific website with Appointo.{' '}
                  <a
                    className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
                    target="_blank"
                    href="https://www.loom.com/share/98e770e9a1b944928cffea156236daae"
                  >
                    Watch Video
                  </a>
                </p>
              )}
            </div>
            <div className="ml-4 flex items-center md:ml-6">
              {/* Profile dropdown */}
              <Menu as="div" className="ml-3 relative">
                {({ open }) => (
                  <>
                    <div>
                      <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500">
                        <span className="sr-only">Open user menu</span>
                        <span className="inline-flex items-center justify-center h-8 w-8 rounded-full bg-gray-500">
                          <span className="text-sm font-medium leading-none text-white">
                            {getInitials()}
                          </span>
                        </span>
                      </Menu.Button>
                    </div>
                    <Transition
                      show={open}
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items
                        static
                        className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                      >
                        {userNavigation.map((item) => (
                          <Menu.Item key={item.name}>
                            {({ active }) => (
                              <div
                                onClick={item.onClick}
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'block px-4 py-2 text-sm text-gray-700 cursor-pointer'
                                )}
                              >
                                {item.name}
                              </div>
                            )}
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </>
                )}
              </Menu>
            </div>
          </div>
        </div>

        <main className="flex-1 relative overflow-y-auto focus:outline-none">
          {settingsData &&
            settingsData.setting.plan === 'free' &&
            moment().isBefore(settingsData.shop.trial_expires_at) && (
              <p className="ml-8 mt-2">
                You trial expires on{' '}
                {moment(settingsData.shop.trial_expires_at).format(
                  'ddd, MMM DD YYYY'
                )}
                Please upgrade to continue using our app post that
              </p>
            )}
          <div className="py-6">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
              {props.children}
            </div>
          </div>
        </main>
      </div>
    </div>
  )
}
