import { all, call, put, takeLatest, select } from 'redux-saga/effects'
import TeamAction, { TeamTypes } from 'Redux/TeamRedux'
import SettingsAction, { SettingsSelectors } from 'Redux/SettingsRedux'

export function* getAll(api, action) {
  console.log('hit here')
  const resp = yield call(api.getTeams, action.params)
  if (resp.ok) {
    yield put(TeamAction.teamsListSuccess(resp.data))
  } else {
    yield put(TeamAction.teamsListFailure('something went wrong'))
  }
}

export function* onCreate(api, action) {
  const resp = yield call(api.createTeam, action.params)
  if (resp.ok) {
    yield put(TeamAction.teamCreateSuccess(resp.data))
    action.callback()
  } else {
    yield put(TeamAction.teamCreateFailure('something went wrong'))
    action.callback()
  }
}

export function* deleteTeam(api, action) {
  const resp = yield call(api.deleteTeam, action.id)
  if (resp.ok) {
    yield put(TeamAction.teamDeleteSuccess())
    action.callback()
  } else {
    yield put(TeamAction.teamDeleteFailure())
    action.callback()
  }
}

export function* onUpdate(api, action) {
  const resp = yield call(api.updateTeamMember, action.params.id, action.params)
  if (resp.ok) {
    yield put(TeamAction.teamUpdateSuccess(resp.data))
    action.callback('success')
  } else {
    yield put(TeamAction.teamUpdateFailure('something went wrong'))
    action.callback('failure')
  }
}

export function* getValidTeamMemberForBooking(api, action) {
  const resp = yield call(api.getValidTeamMemberForBooking, action.params)
  if (resp.ok) {
    action.callback(resp.data)
  } else {
    action.callback('failure')
  }
}

export function* reassignTeamMember(api, action) {
  const resp = yield call(api.reassignTeamMember, action.params)
  if (resp.ok) {
    action.callback(resp.data)
  } else {
    action.callback('failure')
  }
}

export function* sendVerificationEmail(api, action) {
  const resp = yield call(api.sendMemberVerificationEmail, action.id)
}

export default function* authSagas(api) {
  yield all([
    takeLatest(TeamTypes.TEAMS_LIST_REQUEST, getAll, api),
    takeLatest(TeamTypes.TEAM_CREATE_REQUEST, onCreate, api),
    takeLatest(TeamTypes.TEAM_UPDATE_REQUEST, onUpdate, api),
    takeLatest(TeamTypes.TEAM_DELETE_REQUEST, deleteTeam, api),
    takeLatest(TeamTypes.SEND_VERIFICATION_EMAIL, sendVerificationEmail, api),
    takeLatest(
      TeamTypes.GET_VALID_TEAM_MEMBER_FOR_BOOKING,
      getValidTeamMemberForBooking,
      api
    ),
    takeLatest(TeamTypes.REASSIGN_TEAM_MEMBER, reassignTeamMember, api),
  ])
}
