import { all, fork } from 'redux-saga/effects'
import API from '../Services/Api'
// Dashboard Sagas
import serviceSaga from './ServiceSagas'
import variantSaga from './VariantSagas'
import authSaga from './AuthSagas'
import startupSaga from './StartupSagas'
import bookingSaga from './BookingSagas'
import settingSaga from './SettingsSagas'
import teamSaga from './TeamSagas'
import workflowSaga from './WorkflowSagas'
import thinkificSaga from './ThinkificSagas'
import themesSaga from './ThemesSagas'
import CustomerSaga from './CustomerSagas'
import WaitlistSaga from './WaitlistSagas'

/* ------------- API ------------- */

const api = API.create()

/* ------------- Connect Types To Sagas ------------- */

export default function* root() {
  yield all([
    fork(serviceSaga, api),
    fork(variantSaga, api),
    fork(authSaga, api),
    fork(startupSaga, api),
    fork(bookingSaga, api),
    fork(settingSaga, api),
    fork(teamSaga, api),
    fork(workflowSaga, api),
    fork(thinkificSaga, api),
    fork(themesSaga, api),
    fork(CustomerSaga, api),
    fork(WaitlistSaga, api),
  ])
}
