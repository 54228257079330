import React from 'react'

const CardSection = (props) => {
  return (
    <div className="flex flex-col gap-1">
      <h3 className="text-md font-medium">{props.title}</h3>
      <div className={props.classNames}>{props.children}</div>
    </div>
  )
}

export default CardSection
