import Card from 'Components/Card'
import CardSection from 'Components/CardSection'
import Checkbox from 'Components/Checkbox'
import Input from 'Components/Input'
import Modal from 'Components/Modal'
import Spinner from 'Components/Spinner'
import React, { useState, useCallback, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import WorkflowActions from 'Redux/WorkflowRedux'

function WorkflowModal(props) {
  const dispatch = useDispatch()
  const [queryValue, setQueryValue] = useState('')
  const [selectedWorkflows, setSelectedWorkflows] = useState([])
  const workflowList = useSelector((state) => state.workflow.list)
  const loading = useSelector((state) => state.workflow.listFetching)
  const [filteredWorkflows, setFilteredWorkflows] = useState([])

  useEffect(() => {
    getAllWorkflows()
  }, [])

  useEffect(() => {
    if (queryValue.length > 0) {
      const filtered = workflowList.filter((workflow) =>
        workflow.name.toLowerCase().includes(queryValue.toLowerCase())
      )
      setFilteredWorkflows(filtered)
    } else {
      setFilteredWorkflows(workflowList)
    }
  }, [queryValue, workflowList])

  const getAllWorkflows = () => {
    dispatch(WorkflowActions.workflowsListRequest())
  }

  const handleFiltersQueryChange = useCallback((value) => {
    setQueryValue(value)
  }, [])

  const handleClose = useCallback(() => {
    props.setShowModal(false)
  }, [props])

  const handleSave = useCallback(() => {
    props.onSave(selectedWorkflows)
    handleClose()
  }, [selectedWorkflows])

  const onSelectionChange = useCallback((isChecked, item) => {
    setSelectedWorkflows((prevSelectedWorkflows) =>
      isChecked
        ? [...prevSelectedWorkflows, item]
        : prevSelectedWorkflows.filter(
            (selectedItem) => selectedItem.id !== item.id
          )
    )
  }, [])

  const onSelectAllChange = useCallback(
    (isChecked) => {
      if (isChecked) {
        setSelectedWorkflows(filteredWorkflows)
      } else {
        setSelectedWorkflows([])
      }
    },
    [filteredWorkflows]
  )

  const allSelected = selectedWorkflows.length === filteredWorkflows?.length

  return (
    <div>
      <Modal
        showModal={props.showModal}
        onClose={handleClose}
        title={props.title}
        primaryButton={{
          title: 'Add',
          onClick: handleSave,
          disabled: selectedWorkflows.length === 0,
        }}
      >
        <>
          <div>
            <Card>
              <CardSection>
                <Input
                  value={queryValue}
                  placeholder="Filter workflows by name"
                  onChange={(value) => handleFiltersQueryChange(value)}
                  label=""
                />
                {filteredWorkflows.length > 0 && (
                  <p className="text-xs py-2">
                    Showing top {filteredWorkflows.length} workflows. Please use{' '}
                    <strong>Search</strong> to get your desired workflows
                  </p>
                )}
              </CardSection>
              {loading ? (
                <div>
                  <Spinner />
                </div>
              ) : filteredWorkflows.length > 0 ? (
                <>
                  <div className="flex flex-col gap-2 my-2">
                    <Checkbox
                      name="selectAll"
                      title="Select All"
                      onClick={(isChecked) => onSelectAllChange(isChecked)}
                      value={allSelected}
                    />

                    {filteredWorkflows?.map((item) => (
                      <Checkbox
                        key={item.id}
                        name={item.name}
                        title={item.name}
                        onClick={(isChecked) =>
                          onSelectionChange(isChecked, item)
                        }
                        value={selectedWorkflows.some(
                          (selectedItem) => selectedItem.id === item.id
                        )}
                      />
                    ))}
                  </div>
                </>
              ) : (
                <p className="text-xs py-2">Uh-oh! No workflows found!</p>
              )}
            </Card>
          </div>
        </>
      </Modal>
    </div>
  )
}

export default WorkflowModal
