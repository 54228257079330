import React, { useEffect } from 'react'
import store from 'store'
import Smartlook from 'smartlook-client'

const SmartlookClient = (props) => {
  useEffect(() => {
    let userData = store.get('userData')
    Smartlook.init('86fe3740bc6c582e58e9b98e61ff3f28258463e8')
    setTimeout(() => {
      Smartlook.identify(userData.shop.shopify_domain, {
        email: userData.email,
        user_hash: userData.user_hash,
        name: userData.name,
      })
    }, 2000)
  }, [])

  return null
}

export default SmartlookClient
