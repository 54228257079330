import Table from 'Components/Table'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from 'Components/Spinner'
import EmptyState from 'Components/EmptyState'
import WaitlistActions from 'Redux/WaitlistRedux'
import useDebounce from 'Hooks/debounce'
import Input from 'Components/Input'
import { ItemsPerPage } from 'Redux/genericReducers'
import Select from 'Components/Select'
import moment from 'moment'
import AppointoPagination from 'Components/AppointoPagination'
import Button from 'Components/Button'
import Heading from 'Components/Heading'

const Waitlist = () => {
  const waitlistHeaders = [
    'Order No.',
    'Team Member',
    'Appointment Name',
    'Appointment Time',
    'Customer Email',
    'Quantity',
    'Status',
    'Action',
    '',
  ]
  const waitlistFetching = useSelector((state) => state.waitlist.listFetching)
  const waitlistList = useSelector((state) => state.waitlist.list)
  const listDataOffset = useSelector((state) => state.waitlist.listDataOffset)
  const [list, setList] = useState([])
  const [status, setStatus] = useState('')
  const dispatch = useDispatch()
  const [queryValue, setQueryValue] = useState('')
  const debouncedQueryValue = useDebounce(queryValue, 300)

  const rejectWaitlist = (w) => () => {
    let params = {
      id: w.id,
      status: 'cancelled',
    }

    dispatch(
      WaitlistActions.waitlistUpsertRequest(params, () => {
        dispatch(WaitlistActions.waitlistListRequest({}, 0))
      })
    )
  }

  const approveWaitlist = (w) => () => {
    let params = {
      id: w.id,
      status: 'booked',
    }

    dispatch(
      WaitlistActions.waitlistUpsertRequest(params, () => {
        dispatch(WaitlistActions.waitlistListRequest({}, 0))
      })
    )
  }

  useEffect(() => {
    if (debouncedQueryValue.length >= 3) {
      const filteredList = waitlistList.filter((item) => {
        const searchTerm = debouncedQueryValue.toLowerCase()
        return (
          (item.team_member?.name?.toLowerCase() || '').includes(searchTerm) ||
          (item.appointment?.name?.toLowerCase() || '').includes(searchTerm) ||
          (item.email?.toLowerCase() || '').includes(searchTerm)
        )
      })

      const mappedList = filteredList.map((t) => [
        t.order_name ? t.order_name : '-',
        t.team_member ? t.team_member.name : '-',
        t.appointment.name || '-',
        {
          value: (
            <div>
              <p>{moment(t.selected_time).format('llll')}</p>
              <p style={{ fontSize: '0.8em', color: 'gray' }}>
                Duration: {t.appointment_config.duration} mins
              </p>
            </div>
          ),
        },
        t.email || '-',
        t.quantity || 1,
        {
          value: (
            <>
              {t.status === 'pending' ? (
                <p className="" style={{ color: '#949407' }}>
                  Pending
                </p>
              ) : t.status === 'booked' ? (
                <p className="" style={{ color: 'green' }}>
                  Approved
                </p>
              ) : (
                <p className="" style={{ color: 'red' }}>
                  Rejected
                </p>
              )}
            </>
          ),
        },
        {
          value: (
            <div className="flex gap-2.5">
              <Button
                type="primary"
                title="Approve"
                onClick={approveWaitlist(t)}
              />
              <Button
                type="destructive"
                title="Reject"
                onClick={rejectWaitlist(t)}
              />
            </div>
          ),
        },
      ])

      setList(mappedList)
    } else {
      const mappedList = waitlistList.map((t) => [
        t.order_name ? t.order_name : '-',
        t.team_member ? t.team_member.name : '-',
        t.appointment.name || '-',
        {
          value: (
            <div>
              <p>{moment(t.selected_time).format('llll')}</p>
              <p style={{ fontSize: '0.8em', color: 'gray' }}>
                Duration: {t.appointment_config.duration} mins
              </p>
            </div>
          ),
        },
        t.email || '-',
        t.quantity || 1,
        {
          value: (
            <>
              {t.status === 'pending' ? (
                <p className="" style={{ color: '#949407' }}>
                  Pending
                </p>
              ) : t.status === 'booked' ? (
                <p className="" style={{ color: 'green' }}>
                  Approved
                </p>
              ) : (
                <p className="" style={{ color: 'red' }}>
                  Rejected
                </p>
              )}
            </>
          ),
        },
        {
          value: (
            <div className="flex gap-2.5">
              <Button
                type="primary"
                title="Approve"
                onClick={approveWaitlist(t)}
              />
              <Button
                type="destructive"
                title="Reject"
                onClick={rejectWaitlist(t)}
              />
            </div>
          ),
        },
      ])
      setList(mappedList)
    }
  }, [debouncedQueryValue, waitlistList])

  useEffect(() => {
    if (queryValue.length == 0) {
      dispatch(WaitlistActions.waitlistListRequest({ status: status }, 0))
    }
  }, [debouncedQueryValue])

  const onPrevious = () => {
    dispatch(
      WaitlistActions.waitlistListRequest(
        {
          search_term: queryValue,
          status: status,
        },
        listDataOffset - ItemsPerPage
      )
    )
  }

  const onNext = () => {
    dispatch(
      WaitlistActions.waitlistListRequest(
        {
          search_term: queryValue,
          status: status,
        },
        listDataOffset + ItemsPerPage
      )
    )
  }

  useEffect(() => {
    dispatch(WaitlistActions.waitlistListRequest({}, 0))
  }, [])

  return (
    <>
      <div className="mb-6">
        <Heading title="Waitlist" />
      </div>
      {waitlistFetching ? (
        <Spinner />
      ) : list.length === 0 ? (
        <EmptyState header="No Waitlists" />
      ) : (
        <>
          <div className="bg-white p-5 rounded-tr-md rounded-tl-md">
            <div className="grid grid-cols-12 mb-4 justify-between gap-4 items-center">
              <div className="col-span-12 sm:col-span-8">
                <Input
                  value={queryValue}
                  placeholder="Filter waitlists by name or email"
                  onChange={(value) => setQueryValue(value)}
                />
              </div>
              <div className="col-span-12 mt-4 sm:mt-0 sm:col-span-4">
                <Select
                  label="Status"
                  options={[
                    { label: 'All', value: '' },
                    { label: 'Pending', value: 'pending' },
                    { label: 'Approved', value: 'booked' },
                    { label: 'Rejected', value: 'cancelled' },
                  ]}
                  selected={status}
                  setSelected={(value) => {
                    setStatus(value)
                    dispatch(
                      WaitlistActions.waitlistListRequest(
                        {
                          search_term: queryValue,
                          status: value,
                        },
                        0
                      )
                    )
                  }}
                  className="text-sm"
                />
              </div>
            </div>
          </div>

          {list.length > 0 ? (
            <>
              <Table headers={waitlistHeaders} data={list} />
              <AppointoPagination
                offset={listDataOffset}
                onPrevious={onPrevious}
                list={list}
                onNext={onNext}
              />
            </>
          ) : (
            <p className="text-md bg-white p-5 text-center">
              Oops. No Waitlist Found. Please change the search term or add
              waitlist
            </p>
          )}
        </>
      )}
    </>
  )
}

export default Waitlist
