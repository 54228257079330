import React, { useState, useEffect } from 'react'
import Select from './Select'
import Label from './Label'

let selectOptions = [
  {
    label: '0 min',
    value: '0',
  },
  {
    label: '1 min',
    value: '1',
  },
  {
    label: '2 min',
    value: '2',
  },
  {
    label: '3 min',
    value: '3',
  },
  {
    label: '4 min',
    value: '4',
  },
  {
    label: '5 min',
    value: '5',
  },
  {
    label: '6 min',
    value: '6',
  },
  {
    label: '7 min',
    value: '7',
  },
  {
    label: '8 min',
    value: '8',
  },
  {
    label: '9 min',
    value: '9',
  },
  {
    label: '10 min',
    value: '10',
  },
  {
    label: '11 min',
    value: '11',
  },
  {
    label: '12 min',
    value: '12',
  },
  {
    label: '13 min',
    value: '13',
  },
  {
    label: '14 min',
    value: '14',
  },
  {
    label: '15 min',
    value: '15',
  },
  {
    label: '16 min',
    value: '16',
  },
  {
    label: '17 min',
    value: '17',
  },
  {
    label: '18 min',
    value: '18',
  },
  {
    label: '19 min',
    value: '19',
  },
  {
    label: '20 min',
    value: '20',
  },
  {
    label: '21 min',
    value: '21',
  },
  {
    label: '22 min',
    value: '22',
  },
  {
    label: '23 min',
    value: '23',
  },
  {
    label: '24 min',
    value: '24',
  },
  {
    label: '25 min',
    value: '25',
  },
  {
    label: '26 min',
    value: '26',
  },
  {
    label: '27 min',
    value: '27',
  },
  {
    label: '28 min',
    value: '28',
  },
  {
    label: '29 min',
    value: '29',
  },
  {
    label: '30 min',
    value: '30',
  },
  {
    label: '31 min',
    value: '31',
  },
  {
    label: '32 min',
    value: '32',
  },
  {
    label: '33 min',
    value: '33',
  },
  {
    label: '34 min',
    value: '34',
  },
  {
    label: '35 min',
    value: '35',
  },
  {
    label: '36 min',
    value: '36',
  },
  {
    label: '37 min',
    value: '37',
  },
  {
    label: '38 min',
    value: '38',
  },
  {
    label: '39 min',
    value: '39',
  },
  {
    label: '40 min',
    value: '40',
  },
  {
    label: '41 min',
    value: '41',
  },
  {
    label: '42 min',
    value: '42',
  },
  {
    label: '43 min',
    value: '43',
  },
  {
    label: '44 min',
    value: '44',
  },
  {
    label: '45 min',
    value: '45',
  },
  {
    label: '46 min',
    value: '46',
  },
  {
    label: '47 min',
    value: '47',
  },
  {
    label: '48 min',
    value: '48',
  },
  {
    label: '49 min',
    value: '49',
  },
  {
    label: '50 min',
    value: '50',
  },
  {
    label: '51 min',
    value: '51',
  },
  {
    label: '52 min',
    value: '52',
  },
  {
    label: '53 min',
    value: '53',
  },
  {
    label: '54 min',
    value: '54',
  },
  {
    label: '55 min',
    value: '55',
  },
  {
    label: '56 min',
    value: '56',
  },
  {
    label: '57 min',
    value: '57',
  },
  {
    label: '58 min',
    value: '58',
  },
  {
    label: '59 min',
    value: '59',
  },
]

let selectHourOptions = [
  {
    label: '0 hour',
    value: '0',
  },
  {
    label: '1 hour',
    value: '1',
  },
  {
    label: '2 hours',
    value: '2',
  },
  {
    label: '3 hours',
    value: '3',
  },
  {
    label: '4 hours',
    value: '4',
  },
  {
    label: '5 hours',
    value: '5',
  },
  {
    label: '6 hours',
    value: '6',
  },
  {
    label: '7 hours',
    value: '7',
  },
  {
    label: '8 hours',
    value: '8',
  },
  {
    label: '9 hours',
    value: '9',
  },
  {
    label: '10 hours',
    value: '10',
  },
  {
    label: '11 hours',
    value: '11',
  },
  {
    label: '12 hours',
    value: '12',
  },
  {
    label: '13 hours',
    value: '13',
  },
  {
    label: '14 hours',
    value: '14',
  },
  {
    label: '15 hours',
    value: '15',
  },
  {
    label: '16 hours',
    value: '16',
  },
  {
    label: '17 hours',
    value: '17',
  },
  {
    label: '18 hours',
    value: '18',
  },
  {
    label: '19 hours',
    value: '19',
  },
  {
    label: '20 hours',
    value: '20',
  },
  {
    label: '21 hours',
    value: '21',
  },
  {
    label: '22 hours',
    value: '22',
  },
  {
    label: '23 hours',
    value: '23',
  },
]

let generateOptionDays = (days) => {
  let array = []
  for (let i = 0; i <= days; i++) {
    if (i === 0 || i === 1) {
      array.push({
        label: `${i} day`,
        value: `${i}`,
      })
    } else {
      array.push({
        label: `${i} days`,
        value: `${i}`,
      })
    }
  }
  return array
}

const HourMinutePicker = (props) => {
  const [hour, setHour] = useState(`0`)
  const [minutes, setMinute] = useState(`10`)
  const [day, setDay] = useState(`0`)
  const [selectDaysOptions, setSelectDaysOptions] = useState([])

  useEffect(() => {
    const options = generateOptionDays(60)
    setSelectDaysOptions(options)
  }, [])

  const handleHourChange = (val) => {
    let totalTime =
      parseInt(day || 0, 10) * 24 * 60 +
      parseInt(val || 0, 10) * 60 +
      parseInt(minutes || 10, 10)
    setHour(val)
    props.onChange(`${totalTime}`)
  }

  const handleMinuteChange = (val) => {
    let totalTime =
      parseInt(day || 0, 10) * 24 * 60 +
      parseInt(hour || 0, 10) * 60 +
      parseInt(val || 10, 10)
    setMinute(val)
    props.onChange(`${totalTime}`)
  }

  const handleDayChange = (val) => {
    let totalTime =
      parseInt(val || 0, 10) * 24 * 60 +
      parseInt(hour || 0, 10) * 60 +
      parseInt(minutes || 10, 10)
    setDay(val)
    props.onChange(`${totalTime}`)
  }

  useEffect(() => {
    let totalTime = parseInt(props.value || 10, 10)
    let d = Math.floor(totalTime / 1440)
    let h = Math.floor((totalTime - d * 1440) / 60)
    let m = Math.round(totalTime % 60)

    setDay(`${d}`)
    setHour(`${h}`)
    setMinute(`${m}`)
  }, [props.value])

  return (
    <>
      <Label>
        {props.label} {props.toolTip || ''}
      </Label>
      <div className="flex mt-[4px]">
        {!props.hideDays && (
          <div className="mr-5 w-1/3">
            <Select
              label=""
              options={selectDaysOptions}
              selected={day}
              setSelected={handleDayChange}
            />
          </div>
        )}

        <div className={`${props.hideDays ? 'w-1/2' : 'w-1/3'} mr-5`}>
          <Select
            label=""
            options={selectHourOptions}
            selected={hour}
            setSelected={handleHourChange}
          />
        </div>

        <div className={`${props.hideDays ? 'w-1/2' : 'w-1/3'}`}>
          <Select
            label=""
            options={selectOptions}
            selected={minutes}
            setSelected={handleMinuteChange}
          />
        </div>
      </div>
    </>
  )
}

export default HourMinutePicker
