import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'
import {
  listRequest,
  listSuccess,
  listFailure,
  createRequest,
  createSuccess,
  createFailure,
  deleteRequest,
  deleteSuccess,
  deleteFailure,
  updateRequest,
  updateSuccess,
  updateFailure,
} from './genericReducers'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  teamsListRequest: ['params'],
  teamsListSuccess: ['list'],
  teamsListFailure: ['listError'],

  teamCreateRequest: ['params', 'callback'],
  teamCreateSuccess: ['createData'],
  teamCreateFailure: ['createError'],

  teamUpdateRequest: ['params', 'callback'],
  teamUpdateSuccess: ['data'],
  teamUpdateFailure: ['error'],

  teamDeleteRequest: ['id', 'callback'],
  teamDeleteSuccess: [],
  teamDeleteFailure: [],

  sendVerificationEmail: ['id'],

  getValidTeamMemberForBooking: ['params', 'callback'],
  reassignTeamMember: ['params', 'callback'],
})

export const TeamTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  list: [],
  listFetching: null,
  listError: null,

  createData: {},
  createError: null,
  createFetching: false,

  deleteFetching: null,
  deleteError: null,

  updateData: {},
  updateError: null,
  updateFetching: false,
})

/* ------------- Reducers ------------- */

export const reset = (state) => state.merge(INITIAL_STATE)

/* ------------- Selectors ------------- */

export const TeamSelectors = {}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.TEAMS_LIST_REQUEST]: listRequest,
  [Types.TEAMS_LIST_SUCCESS]: listSuccess,
  [Types.TEAMS_LIST_FAILURE]: listFailure,

  [Types.TEAM_CREATE_REQUEST]: createRequest,
  [Types.TEAM_CREATE_SUCCESS]: createSuccess,
  [Types.TEAM_CREATE_FAILURE]: createFailure,

  [Types.TEAM_DELETE_REQUEST]: deleteRequest,
  [Types.TEAM_DELETE_SUCCESS]: deleteSuccess,
  [Types.TEAM_DELETE_FAILURE]: deleteFailure,

  [Types.TEAM_UPDATE_REQUEST]: updateRequest,
  [Types.TEAM_UPDATE_SUCCESS]: updateSuccess,
  [Types.TEAM_UPDATE_FAILURE]: updateFailure,
})
