import PropTypes from 'prop-types'
import React from 'react'

export default function Checkbox(props) {
  const handleInputChange = (event) => {
    props.onClick(event.target.checked)
  }

  return (
    <div className="relative flex items-start">
      <div className="flex items-center h-5">
        <input
          id={props.name}
          aria-describedby={`${props.name}-description`}
          name={props.name}
          type="checkbox"
          className="focus:ring-green-500 h-4 w-4 text-green-600 border-gray-300 rounded"
          onChange={handleInputChange}
          checked={props.value}
          disabled={props.disabled}
        />
      </div>
      <div className="ml-3 text-sm">
        <label htmlFor="comments" className="font-medium text-gray-700">
          {props.title}
        </label>
        {props.subTitle && (
          <p id={`${props.name}-description`} className="text-gray-500">
            {props.subTitle}
          </p>
        )}
      </div>
    </div>
  )
}

Checkbox.propTypes = {
  name: PropTypes.string,
  subTitle: PropTypes.string,
  title: PropTypes.string,
}
