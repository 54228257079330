import classNames from 'classnames'
import React from 'react'

export default function Container(props) {
  const ctClass = classNames(
    'relative bg-gray-500 rounded-lg ring-1 ring-gray-900 ring-opacity-5 overflow-hidden',
    props.containerClass
  )

  return (
    <div className={ctClass}>
      <div className="p-6 bg-white">{props.children}</div>
    </div>
  )
}
