import { all, call, put, takeLatest, select } from 'redux-saga/effects'
import WorkflowAction, { WorkflowTypes } from 'Redux/WorkflowRedux'

export function* getAll(api, action) {
  const resp = yield call(api.getWorkflows, action.params)
  if (resp.ok) {
    yield put(WorkflowAction.workflowsListSuccess(resp.data))
  } else {
    yield put(WorkflowAction.workflowsListFailure('something went wrong'))
  }
}

export function* getAllTypes(api, action) {
  const resp = yield call(api.getWorkflowTypes, action.params)
  if (resp.ok) {
    yield put(WorkflowAction.workflowsTypesListSuccess(resp.data))
  } else {
    yield put(WorkflowAction.workflowsTypesListFailure('something went wrong'))
  }
}

export function* onCreate(api, action) {
  const resp = yield call(api.createWorkflow, action.params)
  if (resp.ok) {
    yield put(WorkflowAction.workflowsCreateSuccess(resp.data))
    action.callback()
  } else {
    yield put(WorkflowAction.workflowsCreateFailure('something went wrong'))
    action.callback()
  }
}

export function* deleteWorkflow(api, action) {
  const resp = yield call(api.deleteWorkflow, action.id)
  if (resp.ok) {
    yield put(WorkflowAction.workflowsDeleteSuccess())
    action.callback()
  } else {
    yield put(WorkflowAction.workflowsDeleteFailure())
    action.callback()
  }
}

export function* onUpdate(api, action) {
  const resp = yield call(api.updateWorkflow, action.params.id, action.params)
  if (resp.ok) {
    yield put(WorkflowAction.workflowsUpdateSuccess(resp.data))
    action.callback('success')
  } else {
    yield put(WorkflowAction.workflowsUpdateFailure('something went wrong'))
    action.callback('failure')
  }
}

export function* detailsRequest(api, action) {
  const resp = yield call(api.getWorkflowDetails, action.id)
  if (resp.ok) {
    yield put(WorkflowAction.workflowsDetailSuccess(resp.data))
  } else {
    yield put(WorkflowAction.workflowsDetailFailure('something went wrong'))
  }
}

export default function* authSagas(api) {
  yield all([
    takeLatest(WorkflowTypes.WORKFLOWS_LIST_REQUEST, getAll, api),
    takeLatest(WorkflowTypes.WORKFLOWS_TYPES_LIST_REQUEST, getAllTypes, api),
    takeLatest(WorkflowTypes.WORKFLOWS_DETAIL_REQUEST, detailsRequest, api),
    takeLatest(WorkflowTypes.WORKFLOWS_CREATE_REQUEST, onCreate, api),
    takeLatest(WorkflowTypes.WORKFLOWS_UPDATE_REQUEST, onUpdate, api),
    takeLatest(WorkflowTypes.WORKFLOWS_DELETE_REQUEST, deleteWorkflow, api),
  ])
}
