/* This example requires Tailwind CSS v2.0+ */
import { CheckIcon } from '@heroicons/react/solid'
import { useDispatch } from 'react-redux'
import SettingsActions from 'Redux/SettingsRedux'
import store from 'store'
import React, { useEffect, useState } from 'react'
import AuthAction from 'Redux/AuthRedux'
import { TrackEvent } from 'Themes/constants'
import Button from 'Components/Button'

const tiers = [
  {
    name: 'Pro',
    priceMonthly: 12,
    serverName: 'pro',
    description: 'Pro for more larger teams',
    includedFeatures: [
      'All Features',
      'Unlimited Services',
      'Email/Text Reminders',
      'Extra members at $6/m',
    ],
  },
]

export default function Plans() {
  const dispatch = useDispatch()
  const [currentPlan, setCurrentPlan] = useState('free')
  const [campaign, setCampaign] = useState()
  const [loading, setLoading] = useState(false)
  const [role, setRole] = useState('admin')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const settingsData = store.get('userData')
    setCurrentPlan(settingsData.setting.plan)
    setCampaign(settingsData.shop.campaign)
    setRole(settingsData.role)
  }, [])

  const onClick = (name, serverName) => () => {
    switch (name) {
      case 'Free':
        dispatch(
          SettingsActions.settingsPlanCancelRequest({}, () => {
            dispatch(AuthAction.meRequest(() => {}))
          })
        )
        break
      default:
        setLoading(true)
        TrackEvent('Plan Clicked', {})
        dispatch(
          SettingsActions.settingsPlanUpgradeRequest(
            { name: serverName },
            (data, shop) => {
              window.location.href = data.url
            }
          )
        )
        setLoading(false)
    }
  }

  const cancelPlan = (refreshPage) => {
    setIsLoading(true)
    dispatch(
      SettingsActions.settingsPlanCancelRequest(() => {
        setIsLoading(false)
      })
    )
  }

  if (role !== 'admin' && role !== 'super_admin') {
    return (
      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:flex-col sm:align-center">
            <h1 className="text-5xl font-extrabold text-gray-900 sm:text-center">
              Pricing Plans
            </h1>
            <p className="mt-5 text-xl text-gray-500 sm:text-center">
              Please contact your account admin to change/modify pricing plans
            </p>
          </div>
        </div>
      </div>
    )
  }

  if (campaign === 'appsumo') {
    return (
      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:flex-col sm:align-center">
            <h1 className="text-5xl font-extrabold text-gray-900 sm:text-center">
              Pricing Plans
            </h1>
            <p className="mt-5 text-xl text-gray-500 sm:text-center">
              You are part of the lifetime plan that your purchased through App
              Sumo.
            </p>
          </div>
        </div>
      </div>
    )
  }

  if (campaign === 'wix') {
    return (
      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:flex-col sm:align-center">
            <h1 className="text-5xl font-extrabold text-gray-900 sm:text-center">
              Pricing Plans
            </h1>
            <p className="mt-5 text-xl text-gray-500 sm:text-center">
              You are part of the lifetime free plan as part of Wix
            </p>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:flex-col sm:align-center">
          <h1 className="text-5xl font-extrabold text-gray-900 sm:text-center">
            Pricing Plans
          </h1>
          <p className="mt-5 text-xl text-gray-500 sm:text-center">
            Start building for free, then add a site plan to go live. Account
            plans unlock additional features.
          </p>
        </div>

        <div className="mt-12 space-y-4 sm:mt-16 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:max-w-6xl lg:mx-auto xl:max-w-none xl:mx-0 xl:grid-cols-3">
          {tiers.map((tier) => (
            <div
              key={tier.name}
              className="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200"
            >
              <div className="p-6">
                <h2 className="text-lg leading-6 font-medium text-gray-900">
                  {tier.name}
                </h2>
                <p className="mt-4 text-sm text-gray-500">{tier.description}</p>
                <p className="mt-8">
                  {tier.offerPrice && (
                    <>
                      <span className="text-4xl font-extrabold text-gray-900 line-through">
                        ${tier.offerPrice}{' '}
                      </span>
                      <span className="text-base font-medium text-gray-500">
                        per year (5 members)
                      </span>
                    </>
                  )}
                  <br />
                  <span className="text-4xl font-extrabold text-gray-900">
                    ${tier.priceMonthly}
                  </span>{' '}
                  <span className="text-base font-medium text-gray-500">
                    {tier.lifetime ? 'one-time' : '/mo'}
                  </span>
                </p>
                <div
                  onClick={
                    currentPlan === tier.serverName
                      ? () => {}
                      : onClick(tier.name, tier.serverName)
                  }
                  className={`${
                    currentPlan === tier.serverName
                      ? 'opacity-40'
                      : 'opacity-100'
                  } cursor-pointer mt-8 block w-full bg-green-600 border border-green-600 rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-green-500`}
                >
                  {loading && (
                    <svg
                      className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  )}
                  {currentPlan === tier.serverName
                    ? 'Current Plan'
                    : `Buy ${tier.name}`}
                </div>
              </div>
              <div className="pt-6 pb-8 px-6">
                <h3 className="text-xs font-medium text-gray-900 tracking-wide uppercase">
                  What's included
                </h3>
                <ul role="list" className="mt-6 space-y-4">
                  {tier.includedFeatures.map((feature) => (
                    <li key={feature} className="flex space-x-3">
                      <CheckIcon
                        className="flex-shrink-0 h-5 w-5 text-green-500"
                        aria-hidden="true"
                      />
                      <span className="text-sm text-gray-500">{feature}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
        {currentPlan === 'pro' && (
          <div className="mt-3 w-100 flex justify-end">
            <Button
              type="destructive"
              title="Downgrade Plan"
              onClick={cancelPlan}
              loading={isLoading}
            />
          </div>
        )}
      </div>
    </div>
  )
}
