import Button from 'Components/Button'
import Heading from 'Components/Heading'
import Table from 'Components/Table'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import BookingActions from 'Redux/BookingRedux'
import Spinner from 'Components/Spinner'
import EmptyState from 'Components/EmptyState'

const headers = [
  'Order No.',
  'Team Member',
  'Appointment Name',
  'Invitee Email',
  'Invitee Name',
  '',
]

const Conflicts = (props) => {
  const dispatch = useDispatch()
  const [bookings, setBookings] = useState([])
  const bookingsFetching = useSelector((state) => state.booking.listFetching)
  const bookingsList = useSelector((state) => state.booking.list)
  const currentTab = useSelector((state) => state.navigation.currentTab)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    dispatch(BookingActions.bookingsListRequest({ status: 'upcoming' }))
  }, [])

  const bookNow = (booking) => () => {
    window.open(
      `${booking.booking_link}&fromAdmin=true&bookingConflict=true`,
      '_blank'
    )
  }

  const removeConflict = (c) => () => {
    setIsLoading(true)
    dispatch(
      BookingActions.deleteConflict(c.id, () => {
        setIsLoading(false)
        dispatch(BookingActions.conflictsRequest())
      })
    )
  }

  useEffect(() => {
    if (bookingsList?.length > 0) {
      let s = bookingsList.map((b, i) => {
        return [
          b.order_name,
          b.team_member ? b.team_member.email : 'Admin',
          b.product_name,
          b.customer_email,
          b.customer_name,
          {
            value: (
              <div className="flex gap-2.5">
                <Button
                  type="defaultGray"
                  title="Book Now"
                  onClick={bookNow(b)}
                />
                <Button
                  type="destructive"
                  title="Remove"
                  onClick={removeConflict(b)}
                  loading={isLoading}
                />
              </div>
            ),
          },
        ]
      })
      setBookings(s)
    }
  }, [bookingsList, currentTab])

  useEffect(() => {
    if (currentTab === 'conflicts') {
      dispatch(BookingActions.conflictsRequest())
    }
  }, [currentTab])

  return (
    <div>
      <Heading title="Conflicts" />

      <div className="my-6">
        {bookingsFetching ? (
          <Spinner />
        ) : bookingsList.length === 0 ? (
          <EmptyState header="No Conflicts" subHeader="" />
        ) : (
          <Table headers={headers} data={bookings} />
        )}
      </div>
    </div>
  )
}

export default Conflicts
