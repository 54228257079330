import React from 'react'
import ReactDOM from 'react-dom'
import 'Styles/index.css'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import App from './App'
import reportWebVitals from './reportWebVitals'
import posthog from 'posthog-js'

Sentry.init({
  dsn: 'https://3b9a68735fa142a98e2e94edfb3dc3dc@o585271.ingest.sentry.io/5945520',
  integrations: [new Integrations.BrowserTracing()],
  environment:
    process.env.NODE_ENV === 'development' ? 'development' : 'production',
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})

const render = (Component) => {
  posthog.init('phc_gKU0nxxSMKzJoQwUeGeUiprjTCIeSinFqQcy7Cfk3KP', {
    api_host: 'https://app.posthog.com',
  })
  return ReactDOM.render(
    <React.StrictMode>
      <Component />
    </React.StrictMode>,
    document.getElementById('root')
  )
}

render(App)

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default
    render(NextApp)
  })
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
