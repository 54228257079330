import React, { useState } from 'react'
import Modal from 'Components/Modal'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import moment from 'moment'
import BookingActions from 'Redux/BookingRedux'
import SelectConnectedVariants from './SelectConnectedVariants'
import SelectTeamMember from './SelectTeamMember'
import AnswerCustomFields from './AnswerCustomFields'
import SelectTime from './SelectTime'
import store from 'store'

function ManualSubscriptionBookingModal(props) {
  const dispatch = useDispatch()
  const [selectedDate, setSelectedDate] = useState()
  const [selectedEndDate, setSelectedEndDate] = useState()
  const [loading, setLoading] = useState(false)
  const [type, setType] = useState('select')
  const [selectedVariant, setSelectedVariant] = useState({})
  const [selectedProduct, setSelectedProduct] = useState({})
  const [teamMembers, setTeamMembers] = useState([])
  const [selectedTeamMembers, setSelectedTeamMembers] = useState([])
  const [customFields, setCustomFields] = useState([])
  const [customFieldsValues, setCustomFieldsValues] = useState({})
  const [allFieldsEntered, setAllFieldsEntered] = useState(false)
  const [override, setOverride] = useState(false)

  const userData = store.get('userData')

  const bookSlot = async () => {
    let params = {
      duration_uuid: selectedVariant.duration_uuid.replace(
        'gid://shopify/ProductVariant/',
        ''
      ),
      is_active_sub: true,
      quantity: 1,
      timestring: selectedDate,
      custom_fields: [],
      subscription_id: props.id,
      override: override,
    }

    if (Object.values(customFieldsValues).length > 0) {
      let cf = []
      Object.keys(customFieldsValues).map((cfv) => {
        let c = customFields.find((c) => `${c.id}` === `${cfv}`)
        cf.push({
          custom_field_id: c.id,
          value: customFieldsValues[cfv],
          admin_created: !!c.admin_created,
          admin_label: c.admin_label,
        })
      })
      params.custom_fields = cf
    }

    if (selectedTeamMembers.length > 0) {
      params.impersonated_tm = selectedTeamMembers[0]
    }

    if (selectedProduct && selectedProduct.scenario === 'multi_day') {
      let startDate = moment(selectedDate)
      let endDate = moment(selectedEndDate)
      params.quantity = endDate.diff(startDate, 'day') + 1
    }

    let slotUrl = `${process.env.REACT_APP_BASE_URL}${
      userData.shop.flow === 'shopify' ? '' : '/dashboard'
    }/scripttag/book_slot`
    setLoading(true)
    await axios.post(slotUrl, params)
    setLoading(false)
    props.setShowModal(false)
    dispatch(BookingActions.bookingsSubscriptionsListRequest())
  }

  const onNext = () => {
    if (type === 'select') {
      if (selectedVariant.custom_fields.length > 0) {
        setCustomFields(selectedVariant.custom_fields)
      }
      if (
        !!selectedVariant.team_members &&
        selectedVariant.team_members.length > 0
      ) {
        let tms = []
        selectedVariant.team_members.map((a) => {
          let tm = selectedVariant.appointment_team_members.find(
            (t) => a.id === t.team_member_id
          )
          tms.push({
            label:
              tm.category === 'default'
                ? `${a.email} (${a.name})`
                : `${a.email} - ${tm.category}(${a.name})`,
            value: a.id,
          })
        })
        setTeamMembers(tms)
        setType('selectTeamMember')
      } else {
        if (selectedVariant.custom_fields.length > 0) {
          setType('selectCustomFields')
        } else {
          setType('time')
        }
      }
    } else if (type === 'selectTeamMember') {
      if (selectedVariant.custom_fields.length > 0) {
        setType('selectCustomFields')
      } else {
        setType('time')
      }
    } else if (type === 'selectCustomFields') {
      setType('time')
    } else {
      bookSlot()
    }
  }

  const checkDisabled = () => {
    if (type === 'select') {
      return !selectedVariant
    } else if (type === 'selectTeamMember') {
      if (!selectedTeamMembers.length > 0) {
        return true
      }
    } else if (type === 'selectCustomFields') {
      return !allFieldsEntered
    } else {
      return !selectedDate
    }
  }

  return (
    <div>
      <Modal
        showModal={props.showModal}
        onClose={() => {
          props.setShowModal(false)
        }}
        title={props.title}
        primaryButton={{
          title: type === 'time' ? 'Book' : 'Next',
          onClick: onNext,
          loading: loading,
          disabled: checkDisabled(),
        }}
      >
        {type === 'select' && (
          <SelectConnectedVariants
            selectedVariant={selectedVariant}
            setSelectedVariant={setSelectedVariant}
            variants={props.connectedVariants}
            setSelectedProduct={setSelectedProduct}
          />
        )}

        {type === 'selectTeamMember' && (
          <SelectTeamMember
            teamMembers={teamMembers}
            setSelectedTeamMembers={setSelectedTeamMembers}
            selectedTeamMembers={selectedTeamMembers}
          />
        )}

        {type === 'selectCustomFields' && (
          <AnswerCustomFields
            customFields={customFields}
            customFieldsValues={customFieldsValues}
            setCustomFieldsValues={setCustomFieldsValues}
            allFieldsEntered={allFieldsEntered}
            setAllFieldsEntered={setAllFieldsEntered}
          />
        )}

        {type === 'time' && (
          <SelectTime
            setSelectedDate={setSelectedDate}
            selectedDate={selectedDate}
            variantId={selectedVariant.duration_uuid.replace(
              'gid://shopify/ProductVariant/',
              ''
            )}
            setOverride={setOverride}
            tms={selectedTeamMembers.length > 0 ? selectedTeamMembers[0] : null}
            shop={props.shop}
            selectedProduct={selectedProduct}
            setSelectedEndDate={setSelectedEndDate}
            customerPortal={props.customerPortal}
            subscription
            shopifyUser={userData.shop.flow === 'shopify'}
          />
        )}
      </Modal>
    </div>
  )
}

export default ManualSubscriptionBookingModal
