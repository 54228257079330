// import { createReducer, createActions } from 'reduxsauce'
// import Immutable from 'seamless-immutable'

// /* ------------- Types and Action Creators ------------- */

// const { Types, Creators } = createActions({
//   setNavigation: ['id'],
// })

// export const ServiceTypes = Types
// export default Creators

// /* ------------- Initial State ------------- */

// export const INITIAL_STATE = Immutable({
//   currentTab: 'upcoming',
// })

// /* ------------- Reducers ------------- */

// export const reset = (state) => state.merge(INITIAL_STATE)

// export const resetBasedOnKey = (state, { key }) => {
//   return state.merge({
//     [key]: INITIAL_STATE[key],
//   })
// }

// export const setNavigation = (state, { id }) => {
//   return state.merge({
//     currentTab: id,
//   })
// }

// /* ------------- Selectors ------------- */

// export const ServiceSelectors = {}

// /* ------------- Hookup Reducers To Types ------------- */

// export const reducer = createReducer(INITIAL_STATE, {
//   [Types.SET_NAVIGATION]: setNavigation,
// })

import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  setNavigation: ['id'],
})

export const ServiceTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  currentTab: 'upcoming',
})

/* ------------- Reducers ------------- */

export const reset = (state) => state.merge(INITIAL_STATE)

export const resetBasedOnKey = (state, { key }) => {
  return state.merge({
    [key]: INITIAL_STATE[key],
  })
}

export const setNavigation = (state, { id }) => {
  return state.merge({
    currentTab: id,
  })
}

/* ------------- Selectors ------------- */

export const ServiceSelectors = {}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SET_NAVIGATION]: setNavigation,
})
