import React, { useState } from 'react'
import Modal from 'Components/Modal'
import Input from 'Components/Input'
import { useSelector } from 'react-redux'

function ShowCountModal(props) {
  const [count, setCount] = useState('')
  const isLoading = useSelector((state) => state.booking.cancelFetching)
  return (
    <div>
      <Modal
        showModal={props.showModal}
        onClose={props.handleClose}
        title="Select Customers Count"
        primaryButton={{
          title: 'Done',
          loading: isLoading,
          onClick: () => {
            props.selectCount(count)
            props.handleClose()
          },
        }}
      >
        <Input
          label={`Number of customers for which you want to restore. Max Count: ${props.maxCount}`}
          onChange={(value) => {
            setCount(value)
          }}
          value={count}
          type="number"
          placeholder="Enter customer count"
        />
      </Modal>
    </div>
  )
}

export default ShowCountModal
