import Button from 'Components/Button'
import CardHeading from 'Components/CardHeading'
import Container from 'Components/Container'
import Toast from 'Components/Toast'
import Heading from 'Components/Heading'
import Input from 'Components/Input'
import Spinner from 'Components/Spinner'
import Table from 'Components/Table'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import ServiceActions from 'Redux/ServiceRedux'
import VariantActions from 'Redux/VariantRedux'
import { EDIT_SERVICE, SERVICES } from 'Routes/routes'
import { TrashIcon } from '@heroicons/react/outline'
import store from 'store'
import AddVariantModal from 'Components/Modals/AddVariantModal'
import RemoveModal from 'Components/Modals/RemoveModal'
import EditVariant from '../EditVariant'
import Tooltip from 'Components/Tooltip'

const AddEditService = (props) => {
  const history = useHistory()
  const dispatch = useDispatch()
  let settingsData = store.get('userData')
  const [variants, setVariants] = useState([])
  const [variantsList, setVariantsList] = useState([])
  const [editView, setEditView] = useState(false)
  const [productName, setProductName] = useState('Test Service')
  const [loading, setLoading] = useState(false)
  const [showVariantModal, setShowVariantModal] = useState(false)
  const [showRemoveModal, setShowRemoveModal] = useState(false)
  const [selectedVariant, setSelectedVariant] = useState()
  const [showToast, setShowToast] = useState(false)
  const [editVariantBody, setEditVariantBody] = useState(undefined)
  const [showSaveToast, setShowSaveToast] = useState(false)
  const [removeAvailability, setRemoveAvailability] = useState(false)
  const [redirectUrl, setRedirectUrl] = useState('')

  const variantsDeleteFetching = useSelector(
    (state) => state.variant.deleteFetching
  )
  const upsertFetching = useSelector((state) => state.service.upsertFetching)
  const detailFetching = useSelector((state) => state.service.detailFetching)
  const detailData = useSelector((state) => state.service.detailData)

  const onCreate = () => {
    let params = {
      product_name: productName,
      activate: true,
      include_variant_booking: removeAvailability,
      redirect_url: redirectUrl,
      variants: variants.map((v) => {
        let params = {}
        if (v.id) {
          params.id = v.id
        }

        return {
          ...params,
          name: v.name,
          price: v.price || 0,
          currency: settingsData.shop.currency
            ? settingsData.shop.currency.toUpperCase()
            : 'USD',
        }
      }),
    }

    if (editView) {
      params.id = props.match.params.id
    }

    if (editVariantBody) {
      onVariantSave(editVariantBody)
    }

    dispatch(
      ServiceActions.servicesUpsertRequest(params, (data) => {
        history.push(`${EDIT_SERVICE}/${data.id}`)
      })
    )
  }

  const onAddVariant = () => {
    setShowVariantModal(true)
  }

  useEffect(() => {
    if (!editView) {
      setVariants([
        {
          name: 'Default Name',
          price: 0,
        },
      ])
    } else {
      setVariants([])
    }
  }, [editView])

  useEffect(() => {
    if (props.match.params.id && !editView) {
      dispatch(ServiceActions.servicesDetailRequest(props.match.params.id))
      setEditView(true)
    }
  }, [props])

  const deleteVariant = () => {
    dispatch(
      VariantActions.variantsDeleteRequest(
        props.match.params.id,
        selectedVariant,
        () => {
          setShowRemoveModal(false)
          dispatch(ServiceActions.servicesDetailRequest(props.match.params.id))
          setShowToast(true)
        }
      )
    )
  }

  useEffect(() => {
    if (editView) {
      setProductName(detailData.product_name)
      let variantList = detailData.appointments.map((v, index) => {
        return [
          v.name,
          {
            value: (
              <div className="inline-flex gap-2">
                <Tooltip title="Manage">
                  <Button
                    type="defaultGray"
                    onClick={() => {
                      history.push(
                        `${SERVICES}/${detailData.id}/appointments/${v.id}`
                      )
                    }}
                    title="Manage"
                  />
                </Tooltip>
                <Tooltip title="Delete product">
                  <Button
                    type="defaultGray"
                    onClick={() => {
                      setShowRemoveModal(true)
                      setSelectedVariant(v.id)
                    }}
                    title={
                      <TrashIcon
                        className="text-red-600 w-5 cursor-pointer"
                        width={20}
                      />
                    }
                  />
                </Tooltip>
              </div>
            ),
            type: 'Button',
          },
        ]
      })

      setVariantsList(variantList)
      setRemoveAvailability(detailData.include_variant_booking)
    }
  }, [detailData])

  const onChangeName = (i) => (value) => {
    let tmpVariants = variants
    tmpVariants[i] = {
      ...tmpVariants[i],
      name: value,
    }
    setVariants([...tmpVariants])
  }

  const onChangePrice = (i) => (value) => {
    let tmpVariants = variants
    tmpVariants[i] = {
      ...tmpVariants[i],
      price: value,
    }
    setVariants([...tmpVariants])
  }

  const addVariant = () => {
    setVariants([...variants, { name: '60 minutes', price: '0' }])
  }

  const checkDisabled = () => {
    let disabled = false

    if (!productName) {
      disabled = true
    }

    if (!editView) {
      if (variants.length === 0) {
        disabled = true
      }

      variants.map((v) => {
        if (!v.name) {
          disabled = true
        }
      })
    }

    return disabled
  }

  const removeVariant = (i) => () => {
    let tmpVariants = [...variants]
    tmpVariants.splice(i, 1)
    setVariants([...tmpVariants])
  }

  const onVariantSave = (params) => {
    dispatch(
      VariantActions.variantsUpsertRequest(params, () => {
        setShowSaveToast(true)
      })
    )
  }

  const onClickPreview = () => {
    let userData = store.get('userData')

    if (userData.shop.flow === 'shopify') {
      window.open(
        `https://${userData.shop.shopify_domain}/products/${detailData.handle}`,
        '_blank'
      )
    } else {
      window.open(
        `${window.location.origin}/shop/${userData.shop.subdomain}/product/${detailData.internal_name}`
      )
    }
  }

  return (
    <div>
      <Toast
        show={showToast}
        setShow={setShowToast}
        title="Variant has been deleted successfully"
      />
      <Toast
        show={showSaveToast}
        setShow={setShowSaveToast}
        title="Service and variant has been saved successfully"
      />
      <Heading
        title={editView ? 'Edit Service' : 'Add Service'}
        backAction={{
          title: 'Services',
        }}
        rightSection={
          <>
            {editView && (
              <Button
                title="Preview"
                onClick={onClickPreview}
                type="default"
                className="mr-4"
              />
            )}

            <Button
              title={editView ? 'Save' : 'Create'}
              onClick={onCreate}
              type="primary"
              disabled={checkDisabled()}
              loading={upsertFetching}
            />
          </>
        }
      />

      {detailFetching || loading ? (
        <Spinner />
      ) : (
        <>
          {/* When only 1 variant is present, show the edit components */}
          {variantsList.length === 1 ? (
            <EditVariant
              productId={props.match.params.id}
              variantId={detailData?.appointments?.[0]?.id}
              showHeader={false}
              setVariant={setEditVariantBody}
              setProductName={setProductName}
              fromProduct
              removeAvailability={removeAvailability}
              setRemoveAvailability={setRemoveAvailability}
              redirectUrl={redirectUrl}
              setRedirectUrl={setRedirectUrl}
            />
          ) : editView ? (
            <div className="my-6">
              <Container containerClass="my-6">
                <form className="space-y-6">
                  <div className="grid grid-cols-4 gap-6">
                    <Input
                      label="Name"
                      placeholder="Enter service name"
                      containerClass="col-span-full sm:col-span-2"
                      value={productName}
                      onChange={(value) => {
                        setProductName(value)
                      }}
                    />
                  </div>
                </form>
              </Container>

              <Table headers={['Variant Name', '']} data={variantsList} />
            </div>
          ) : (
            <Container containerClass="my-6">
              <form className="space-y-6">
                {/* <CardHeading
                  title="Variants"
                  rightSection={
                    <Button
                      title="Add Variant"
                      onClick={addVariant}
                      type="underline"
                    />
                  }
                /> */}

                <div className="grid grid-cols-4 gap-6">
                  <Input
                    label="Name"
                    placeholder="Enter service name"
                    containerClass="col-span-full sm:col-span-2"
                    value={productName}
                    onChange={(value) => {
                      setProductName(value)
                    }}
                  />

                  {variants.map((v, i) => {
                    return (
                      <Input
                        label="Price"
                        placeholder="Enter price"
                        containerClass="col-span-full sm:col-span-2"
                        type="currency"
                        value={v.price}
                        onChange={onChangePrice(i)}
                      />
                    )
                  })}
                </div>
              </form>
            </Container>
          )}
        </>
      )}
      <AddVariantModal
        showModal={showVariantModal}
        setShowModal={setShowVariantModal}
        handleClose={() => {
          setShowVariantModal(false)
        }}
        title={'Add Variant'}
        serviceId={props.match.params.id}
      />
      <RemoveModal
        showModal={showRemoveModal}
        setShowModal={setShowRemoveModal}
        handleClose={() => {
          setShowRemoveModal(false)
        }}
        title="Are you sure?"
        btnTitle="Remove Variant"
        body="Are you sure you want to remove this variant? This action cannot be undone"
        onClick={deleteVariant}
        loading={variantsDeleteFetching}
      />
    </div>
  )
}

export default AddEditService
