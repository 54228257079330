import { DuplicateIcon, PlusIcon, TrashIcon } from '@heroicons/react/outline'
import React, { useState } from 'react'
import Select from 'react-select'
import styles from 'Styles/WeeklyHours.module.css'
import {
  custWeekDays,
  DefaultTimeSlot,
  generateTimeOptions,
  weekdays,
} from 'Themes/constants'
import CopyHoursModal from './Modals/CopyHoursModal'
import store from 'store'

const weekdayNameMap = {
  SUN: 'sunday',
  MON: 'monday',
  TUE: 'tuesday',
  WED: 'wednesday',
  THUR: 'thursday',
  FRI: 'friday',
  SAT: 'saturday',
}

const selectStyles = {
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
  singleValue: (provided, state) => {
    return { ...provided, fontSize: 13 }
  },
  option: (base) => ({
    ...base,
    fontSize: 12,
  }),
}

const WeeklyHours = ({ setWeeklyHours, weeklyHours, config, disabled }) => {
  let timeOptions = generateTimeOptions(15)
  const [showModal, setShowModal] = useState(false)
  const [showToast, setShowToast] = useState(false)
  const [currentDay, setCurrentDay] = useState()
  const [showCopyModal, setShowCopyModal] = useState(false)
  const settingsData = store.get('userData')

  const handleStartSelectChange = (w, i) => (option) => {
    let tempAvail = [...weeklyHours]
    let weekday = tempAvail.find((a) => a.weekday === weekdayNameMap[w])
    let weekdayIndex = tempAvail.indexOf(weekday)
    tempAvail[weekdayIndex] = {
      ...weekday,
      slots: Object.assign([], weekday.slots, {
        [i]: {
          start_time: option.value,
          end_time: weekday.slots[i].end_time,
        },
      }),
    }
    setWeeklyHours(tempAvail)
  }

  const handleEndSelectChange = (w, i) => (option) => {
    let tempAvail = [...weeklyHours]
    let weekday = tempAvail.find((a) => a.weekday === weekdayNameMap[w])
    let weekdayIndex = tempAvail.indexOf(weekday)
    tempAvail[weekdayIndex] = {
      ...weekday,
      slots: Object.assign([], weekday.slots, {
        [i]: {
          start_time: weekday.slots[i].start_time,
          end_time: option.value,
        },
      }),
    }

    setWeeklyHours(tempAvail)
  }

  const addOption = (w) => () => {
    let tempAvail = [...weeklyHours]
    let weekday = tempAvail.find((a) => a.weekday === weekdayNameMap[w])
    let weekdayIndex = tempAvail.indexOf(weekday)

    if (weekdayIndex === -1) {
      weekday = {
        weekday: weekdayNameMap[w],
        slots: [DefaultTimeSlot],
      }
      tempAvail = [...tempAvail, weekday]
    } else {
      if (!!weekday.slots) {
        let slots = [...weekday.slots, DefaultTimeSlot]
        tempAvail[weekdayIndex] = {
          ...weekday,
          slots: slots,
        }
      } else {
        weekday = {
          weekday: weekdayNameMap[w],
          slots: [DefaultTimeSlot],
        }
        tempAvail[weekdayIndex] = weekday
      }
    }

    setWeeklyHours(tempAvail)
  }

  const onClickConfirm = () => {
    setShowModal(true)
  }

  const removeOption = (w, i) => () => {
    let tempAvail = [...weeklyHours]
    let weekday = tempAvail.find((a) => a.weekday === weekdayNameMap[w])
    let weekdayIndex = tempAvail.indexOf(weekday)
    let newslots = weekday.slots.filter((_, index) => index !== i)
    tempAvail[weekdayIndex] = {
      ...weekday,
      slots: newslots,
    }
    setWeeklyHours(tempAvail)
  }

  const copyOption = (w) => () => {
    setCurrentDay(w)
    setShowCopyModal(true)
  }

  const onCopy = (selectedDays) => {
    setShowCopyModal(false)
    let tempAvail = [...weeklyHours]
    let currentWeekDay = tempAvail.find(
      (a) => a.weekday === weekdayNameMap[currentDay]
    )

    console.log(selectedDays)

    selectedDays.map((s) => {
      let day = tempAvail.find((a) => a.weekday === weekdayNameMap[s.name])
      console.log(day)
      if (!day) {
        let newDay = {
          weekday: weekdayNameMap[s.name],
          slots: currentWeekDay.slots,
        }
        tempAvail = [...tempAvail, newDay]
      } else {
        let weekdayIndex = tempAvail.indexOf(day)
        tempAvail[weekdayIndex] = {
          ...day,
          slots: currentWeekDay.slots,
        }
      }
    })

    setWeeklyHours(tempAvail)
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.weeklyContainer}>
          <p className={styles.weeklyText}>Set your weekly hours</p>

          {/* <div style={{ marginRight: 20 }}>
            <Button primary onClick={onClickConfirm} size="small">
              Copy Hours
            </Button>
          </div> */}
        </div>
        <div className={styles.weekdaysContainer}>
          {weekdays.map((w, wIndex) => {
            let weekday = weeklyHours.find(
              (a) => a.weekday === weekdayNameMap[w]
            )
            return (
              <div key={w} className={styles.slotContainer}>
                <p
                  className={`${styles.dayText} exclude-translation notranslate`}
                >
                  {settingsData.shop.shopify_domain === 'amedcine.myshopify.com'
                    ? custWeekDays[wIndex]
                    : w}
                </p>
                <div className={styles.timeContainer}>
                  {!!weekday && !!weekday.slots && weekday.slots.length > 0 ? (
                    weekday.slots.map((s, i) => {
                      return (
                        <div
                          key={`${s}_${i}`}
                          className={styles.selectContainer}
                        >
                          <Select
                            isDisabled={disabled}
                            label=""
                            className={styles.select}
                            classNamePrefix="select"
                            isSearchable
                            options={timeOptions}
                            onChange={handleStartSelectChange(w, i)}
                            value={timeOptions.find(
                              (t) => s.start_time === t.value
                            )}
                            defaultValue={DefaultTimeSlot.start_time}
                            menuPortalTarget={document.body}
                            styles={selectStyles}
                          />
                          <p className={styles.dash}>-</p>
                          <Select
                            isDisabled={disabled}
                            label=""
                            className={styles.select}
                            classNamePrefix="select"
                            isSearchable
                            options={timeOptions}
                            onChange={handleEndSelectChange(w, i)}
                            value={timeOptions.find(
                              (t) => s.end_time === t.value
                            )}
                            defaultValue={DefaultTimeSlot.end_time}
                            menuPortalTarget={document.body}
                            styles={selectStyles}
                          />
                          {!disabled && (
                            <>
                              <div
                                className={styles.removeButton}
                                onClick={removeOption(w, i)}
                              >
                                <TrashIcon className="w-5" color="#FF3B3B" />
                              </div>
                              <div
                                className={styles.removeButton}
                                onClick={copyOption(w)}
                              >
                                <DuplicateIcon
                                  className="w-5"
                                  color="#5B93FF"
                                />
                              </div>
                            </>
                          )}
                        </div>
                      )
                    })
                  ) : (
                    <p className={styles.unavailable}>Unavailable</p>
                  )}
                </div>
                <div className={styles.addContainer} onClick={addOption(w)}>
                  <PlusIcon className="w-5 " color="#378760" />
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <CopyHoursModal
        setShowModal={setShowCopyModal}
        showModal={showCopyModal}
        title="Copy Hours to Other Days"
        onCopy={onCopy}
      />
    </>
  )
}

export default WeeklyHours
