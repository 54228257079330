import React, { useState, useCallback, useEffect } from "react";
import Input from "Components/Input";
import AuthAction from "Redux/AuthRedux";
import Button from "Components/Button";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { GoogleLogin } from "react-google-login";
import { logo_url } from "Themes/constants";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const signIn = () => {
    setLoading(true);
    dispatch(
      AuthAction.loginRequest({ email, password }, (resp, err) => {
        setLoading(false);
        if (!!err) {
          setError(err);
        } else {
          window.location.href = "/dashboard";
        }
      })
    );
  };

  const checkDisabled = () => {
    return !email || !password;
  };

  const onGoogleLogin = (code) => {
    console.log(code);
    setLoading(true);
    dispatch(
      AuthAction.googleLoginRequest({ code }, (resp, err) => {
        setLoading(false);
        if (!!err) {
          setError(err);
        } else {
          window.location.href = "/dashboard";
        }
      })
    );
  };

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-16 w-auto" src={logo_url} alt="Appointo" />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Sign in to your account
        </h2>
        <p className="mt-2 text-center text-sm text-gray-600">
          Or{" "}
          <a
            href="/register"
            className="font-medium text-green-600 hover:text-green-500"
          >
            create a new account
          </a>
        </p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <div className="space-y-6">
            <div>
              <Input
                label="Email"
                placeholder="Enter your email"
                containerClass="col-span-full sm:col-span-2"
                value={email}
                onChange={(value) => {
                  setEmail(value);
                }}
              />
            </div>

            <div>
              <Input
                label="Password"
                placeholder="Enter your password"
                containerClass="col-span-full sm:col-span-2"
                value={password}
                onChange={(value) => {
                  setPassword(value);
                }}
                errorText={error}
                type="password"
              />
            </div>

            <div className="flex items-center justify-between">
              <div className="text-sm">
                <a
                  href="/forgot-password"
                  className="font-medium text-green-600 hover:text-green-500"
                >
                  Forgot your password?
                </a>
              </div>
            </div>

            <div>
              <Button
                title="Sign in"
                onClick={signIn}
                type="primary"
                className="w-full justify-center"
                disabled={checkDisabled()}
                loading={loading}
              />
            </div>
          </div>

          <div className="mt-6">
            <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="px-2 bg-white text-gray-500">
                  Or continue with
                </span>
              </div>
            </div>

            <div className="mt-6 flex align-center justify-center">
              <div>
                <GoogleLogin
                  clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                  buttonText="Sign in with Google"
                  onSuccess={onGoogleLogin}
                  onFailure={(err) => {
                    console.log(err);
                  }}
                  cookiePolicy={"single_host_origin"}
                  scope="email profile"
                  responseType="code"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

//w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50
