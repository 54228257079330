import CardHeading from 'Components/CardHeading'
import EmptyState from 'Components/EmptyState'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import WorkflowModal from './WorkflowModal'
import Button from 'Components/Button'
import ConfirmationModal from 'Components/ConfirmationModal'
import { TrashIcon } from '@heroicons/react/outline'

const Workflow = ({ workflows, setWorkflows }) => {
  const [showModal, setShowModal] = useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)

  const addWorkflows = (selectedWorkflows) => {
    let mergedAppts = [...selectedWorkflows]
    workflows.map((s) => {
      let apptExist = mergedAppts.find((m) => m.id === s.id)
      if (!apptExist) {
        mergedAppts.push(s)
      }
    })
    setWorkflows(mergedAppts)
  }

  const handleConfirmationClose = () => {
    setShowConfirmationModal(false)
  }

  const deleteWorkflow = (c) => () => {
    if (!!c.id) {
      let tempCf = workflows.filter((cf) => cf.id !== c.id)
      setWorkflows(tempCf)
    } else {
      let tempCf = workflows.filter((cf) => cf.variant_uuid !== c.variant_uuid)
      setWorkflows(tempCf)
    }
  }

  const deleteAllWorkflows = () => {
    setShowConfirmationModal(false)
    setWorkflows([])
  }

  const openWorkflowModal = () => {
    setShowModal(true)
  }

  return (
    <>
      <CardHeading
        title="Connected Workflows"
        rightSection={
          <>
            {workflows?.length > 0 && (
              <div className="flex gap-2 items-center">
                <Button
                  type="destructive"
                  onClick={() => setShowConfirmationModal(true)}
                  title="Delete All Workflows"
                />

                <Button
                  onClick={openWorkflowModal}
                  type="primary"
                  title="+Add Workflow"
                />
              </div>
            )}
          </>
        }
      />
      <div>
        {workflows?.length > 0 ? (
          <div>
            <div className="flex flex-col gap-3 pt-5">
              {workflows.map((w) => {
                return (
                  <div>
                    <div className="flex justify-between items-center">
                      <p style={{ fontSize: 14 }}>{w.name}</p>
                      <p className="cursor-pointer" onClick={deleteWorkflow(w)}>
                        <TrashIcon className="w-5" />
                      </p>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        ) : (
          <div className="-mt-4">
            <EmptyState
              header="No workflows connected"
              subHeader="Create workflows to send customized notifications to your customers"
              btnTitle={'Add Workflows'}
              onClick={openWorkflowModal}
            />
          </div>
        )}
      </div>

      {showModal && (
        <WorkflowModal
          showModal={showModal}
          setShowModal={setShowModal}
          title="Workflows"
          selectedAppts={workflows}
          onSave={addWorkflows}
        />
      )}
      {showConfirmationModal && (
        <ConfirmationModal
          showModal={showConfirmationModal}
          onClose={handleConfirmationClose}
          onClick={deleteAllWorkflows}
          text="Deleting the workflows will remove all the Workflows for this appointment. This cannot be undone"
          title="Are you sure?"
          buttonText="Yes, Delete"
        />
      )}
    </>
  )
}

export default Workflow
