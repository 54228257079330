import { PlusIcon, TrashIcon } from '@heroicons/react/outline'
import OverrideModal from 'Components/Modals/OverrideModal'
import React, { useState } from 'react'
import styles from 'Styles/DateOverrides.module.css'
import BreakOverrideModal from './Modals/BreakOverrideModal'

const BreakOverrides = ({ breakOverride, setBreakOverride, disabled }) => {
  const [showModal, setShowModal] = useState(false)

  const setBreakOverrides = (ovrds) => {
    let filteredOvrd = breakOverride.filter(
      (o) => !ovrds.find((ov) => ov.day === o.day)
    )
    setBreakOverride([...filteredOvrd, ...ovrds])
    setShowModal(false)
  }

  const removeOption = (i) => () => {
    let tempOverride = [...breakOverride]
    tempOverride = tempOverride.filter((_, index) => index !== i)
    setBreakOverride(tempOverride)
  }

  return (
    <>
      <div
        className={styles.container}
        style={{
          marginLeft: 0,
        }}
      >
        <div className={styles.weeklyContainer}>
          <p className={styles.weeklyText}>Add Breaks</p>
          {!disabled && (
            <div
              className={styles.addContainer}
              onClick={() => {
                setShowModal(true)
              }}
            >
              <PlusIcon className="w-5" />
            </div>
          )}
        </div>
        <div className={styles.weekdaysContainer}>
          <div className={styles.headerContainer}>
            <p className={styles.dates}>DATES</p>
            <p className={styles.availability}>Breaks</p>
          </div>
          {breakOverride.map((o, i) => {
            return (
              <div className={styles.bodyContainer}>
                <p className={styles.day}>{o.day}</p>
                <div className={styles.startContainer}>
                  <>
                    {o.slots.map((s) => (
                      <div className={styles.iconContainer}>
                        <p className={styles.time}>
                          {s.start_time} - {s.end_time}
                        </p>
                      </div>
                    ))}
                    {o.slots.length === 0 && (
                      <p className={styles.unavailable}>Unavailable</p>
                    )}
                  </>
                </div>
                {!disabled && (
                  <div
                    className={styles.removeButton}
                    onClick={removeOption(i)}
                  >
                    <TrashIcon />
                  </div>
                )}
              </div>
            )
          })}
        </div>
      </div>
      <BreakOverrideModal
        showModal={showModal}
        setShowModal={setShowModal}
        handleClose={() => {
          setShowModal(false)
        }}
        onClick={setBreakOverrides}
        breakOverride={breakOverride}
      />
    </>
  )
}

export default BreakOverrides
