import { createActions, createReducer } from 'reduxsauce'
import Immutable from 'seamless-immutable'
import {
  deleteFailure,
  deleteRequest,
  deleteSuccess,
  detailFailure,
  detailRequest,
  detailSuccess,
  listFailure,
  listRequest,
  listSuccess,
  upsertFailure,
  upsertRequest,
  upsertSuccess,
} from './genericReducers'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  variantsListRequest: ['params'],
  variantsListSuccess: ['list'],
  variantsListFailure: ['listError'],

  variantsUpsertRequest: ['params', 'callback'],
  variantsUpsertSuccess: [],
  variantsUpsertFailure: ['upsertError'],

  variantsDetailRequest: ['id', 'productId'],
  variantsDetailSuccess: ['detailData'],
  variantsDetailFailure: ['detailError'],

  customFieldDeleteRequest: [
    'variantId',
    'productId',
    'customFieldId',
    'callback',
  ],
  customFieldDeleteSuccess: [],
  customFieldDeleteFailure: [],

  variantsDeleteRequest: ['productId', 'variantId', 'callback'],
  variantsDeleteSuccess: [],
  variantsDeleteFailure: [],

  variantsSearchRequest: ['params', 'callback'],
})

export const VariantTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  list: [],
  listFetching: null,
  listError: null,

  upsertFetching: null,
  upsertError: null,
  sendingEmail: false,

  detailData: {},
  detailError: null,
  detailFetching: false,

  deleteFetching: null,
  deleteError: null,
})

/* ------------- Reducers ------------- */

export const reset = (state) => state.merge(INITIAL_STATE)

export const resetBasedOnKey = (state, { key }) => {
  return state.merge({
    [key]: INITIAL_STATE[key],
  })
}

/* ------------- Selectors ------------- */

export const VariantSelectors = {}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.VARIANTS_LIST_REQUEST]: listRequest,
  [Types.VARIANTS_LIST_SUCCESS]: listSuccess,
  [Types.VARIANTS_LIST_FAILURE]: listFailure,

  [Types.VARIANTS_UPSERT_REQUEST]: upsertRequest,
  [Types.VARIANTS_UPSERT_SUCCESS]: upsertSuccess,
  [Types.VARIANTS_UPSERT_FAILURE]: upsertFailure,

  [Types.VARIANTS_DETAIL_REQUEST]: detailRequest,
  [Types.VARIANTS_DETAIL_SUCCESS]: detailSuccess,
  [Types.VARIANTS_DETAIL_FAILURE]: detailFailure,

  [Types.CUSTOM_FIELD_DELETE_REQUEST]: deleteRequest,
  [Types.CUSTOM_FIELD_DELETE_SUCCESS]: deleteSuccess,
  [Types.CUSTOM_FIELD_DELETE_FAILURE]: deleteFailure,

  [Types.VARIANTS_DELETE_REQUEST]: deleteRequest,
  [Types.VARIANTS_DELETE_SUCCESS]: deleteSuccess,
  [Types.VARIANTS_DELETE_FAILURE]: deleteFailure,

  // [Types.Variants_KEY_RESET]: resetBasedOnKey,
})
