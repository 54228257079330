import { all, call, put, takeLatest, select } from 'redux-saga/effects'
import WaitlistActions, {
  WaitlistSelectors,
  WaitlistTypes,
} from 'Redux/WaitlistRedux'
import { ItemsPerPage } from 'Redux/genericReducers'

export function* getAll(api, action) {
  let params = action.params || {}

  if (typeof action.listDataOffset !== 'undefined') {
    params.offset = action.listDataOffset
  } else {
    const listDataOffset = yield select(WaitlistSelectors.listDataOffset)
    params.offset = listDataOffset
  }
  params.limit = ItemsPerPage

  const resp = yield call(api.getWaitlists, action.params)
  if (resp.ok) {
    yield put(WaitlistActions.waitlistListSuccess(resp.data))
  } else {
    yield put(WaitlistActions.waitlistListFailure('something went wrong'))
  }
}

export function* onUpsert(api, action) {
  const resp = yield call(api.upsertWaitlist, action.params)
  if (resp.ok) {
    yield put(WaitlistActions.waitlistUpsertSuccess(resp.data))
    action.callback(resp.data)
  } else {
    yield put(WaitlistActions.waitlistUpsertFailure('something went wrong'))
    action.callback(resp.data)
  }
}

export default function* authSagas(api) {
  yield all([
    takeLatest(WaitlistTypes.WAITLIST_LIST_REQUEST, getAll, api),
    takeLatest(WaitlistTypes.WAITLIST_UPSERT_REQUEST, onUpsert, api),
  ])
}
