import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'
import {
  listRequest,
  listSuccess,
  listFailure,
  upsertRequest,
  upsertSuccess,
  upsertFailure,
} from './genericReducers'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  thinkificListRequest: ['params'],
  thinkificListSuccess: ['list'],
  thinkificListFailure: ['listError'],

  thinkificUpsertRequest: ['params', 'callback'],
  thinkificUpsertSuccess: [],
  thinkificUpsertFailure: ['upsertError'],
})

export const ThinkificTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  list: [],
  listFetching: null,
  listError: null,

  upsertFetching: null,
  upsertError: null,
})

/* ------------- Reducers ------------- */

export const reset = (state) => state.merge(INITIAL_STATE)

export const resetBasedOnKey = (state, { key }) => {
  return state.merge({
    [key]: INITIAL_STATE[key],
  })
}

/* ------------- Selectors ------------- */

export const ThinkificSelectors = {}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.THINKIFIC_LIST_REQUEST]: listRequest,
  [Types.THINKIFIC_LIST_SUCCESS]: listSuccess,
  [Types.THINKIFIC_LIST_FAILURE]: listFailure,

  [Types.THINKIFIC_UPSERT_REQUEST]: upsertRequest,
  [Types.THINKIFIC_UPSERT_SUCCESS]: upsertSuccess,
  [Types.THINKIFIC_UPSERT_FAILURE]: upsertFailure,

  // [Types.SERVICES_KEY_RESET]: resetBasedOnKey,
})
