import { all, call, put, takeLatest } from 'redux-saga/effects'
import AuthAction, { AuthTypes } from 'Redux/AuthRedux'
import store from 'store'
import { pick } from 'ramda'
import { authDataKeys } from 'Services/Api'

export function* onLogin(api, action) {
  const resp = yield call(api.login, action.params)
  if (resp.ok) {
    const authData = pick(authDataKeys, resp.headers)
    store.set('authData', authData)
    store.set('userData', resp.data)
    yield call(api.setAuthData, authData)
    action.callback(resp, null)
  } else {
    let errText = 'Invalid username or password. Please retry'
    if (resp.data && resp.data.errors === 'shopify_user') {
      errText =
        'Looks like you are a shopify user. Please login to your shopify admin dashboard or the web dashboard at https://app.appointo.me/team_members/sign_in'
    }
    action.callback(null, errText)
  }
}

export function* onSignup(api, action) {
  const resp = yield call(api.signup, action.params)
  if (resp.ok) {
    const authData = pick(authDataKeys, resp.headers)
    store.set('authData', authData)
    store.set('userData', resp.data)
    yield call(api.setAuthData, authData)
    action.callback(resp, null)
  } else {
    let errText = 'There was an error creating your account. Please try again'
    if (resp.data && resp.data.errors === 'email_exist') {
      errText = 'Email already exists. Please try another email'
      action.callback(null, errText)
    } else if (resp.data && resp.data.errors === 'invalid_code') {
      errText =
        'The code entered is not correct or has already been redeemed. Please enter the correct code or contact us support@sidepanda.com'
      action.callback(null, errText)
    } else if (resp.data && resp.data.errors === 'shopify_store_exist') {
      errText =
        'Your account is already linked with Shopify Appointment App. Click below link'
      action.callback(resp.data, errText)
    } else {
      action.callback(null, errText)
    }
  }
}

export function* onGoogleLogin(api, action) {
  const resp = yield call(api.googleLogin, action.params)
  if (resp.ok) {
    const authData = pick(authDataKeys, resp.headers)
    store.set('authData', authData)
    store.set('userData', resp.data)
    yield call(api.setAuthData, authData)
    action.callback(resp, null)
  } else {
    let errText =
      'There was an error logging into your account. Please try again'
    if (resp.data && resp.data.errors === 'shopify_user') {
      errText =
        'Looks like you are a shopify user. Please login to your shopify admin dashboard or the web dashboard at https://app.appointo.me/team_members/sign_in'
    }
    action.callback(null, errText)
  }
}

export function* onWixLogin(api, action) {
  const resp = yield call(api.wixLogin, action.queryString)
  if (resp.ok) {
    const authData = pick(authDataKeys, resp.headers)
    store.set('authData', authData)
    store.set('userData', resp.data)
    yield call(api.setAuthData, authData)
    action.callback(resp, null)
  } else {
    action.callback(
      null,
      'There was an error logging into your account. Please refresh the page'
    )
  }
}

export function* onAdminLogin(api, action) {
  const resp = yield call(api.adminLogin, action.queryString)
  if (resp.ok) {
    const authData = pick(authDataKeys, resp.headers)
    store.set('authData', authData)
    store.set('userData', resp.data)
    yield call(api.setAuthData, authData)
    action.callback(resp, null)
  } else {
    action.callback(
      null,
      'There was an error logging into your account. Please refresh the page'
    )
  }
}

export function* onRedirectedLogin(api, action) {
  const resp = yield call(api.redirectedLogin, action.queryString)
  if (resp.ok) {
    const authData = pick(authDataKeys, resp.headers)
    store.set('authData', authData)
    store.set('userData', resp.data)
    yield call(api.setAuthData, authData)
    action.callback(resp, null)
  } else {
    action.callback(
      null,
      'There was an error logging into your account. Please try again'
    )
  }
}

export function* forgotPassword(api, action) {
  const resp = yield call(api.forgotPassword, action.params)
  action.callback()
}

export function* meRequest(api, action) {
  const resp = yield call(api.meRequest, action.params)
  if (resp.ok) {
    store.set('userData', resp.data)
    action.callback()
  } else {
  }
}

export function* masqueradeRequest(api, action) {
  const resp = yield call(api.masqueradeRequest, action.params)
  if (resp.ok) {
    const authData = pick(authDataKeys, resp.headers)
    store.set('authData', authData)
    store.set('userData', resp.data)
    yield call(api.setAuthData, authData)
    action.callback(resp, null)
  } else {
    action.callback(
      null,
      'There was an error logging into your account. Please refresh the page'
    )
  }
}

export default function* appointmentSagas(api) {
  yield all([
    takeLatest(AuthTypes.LOGIN_REQUEST, onLogin, api),
    takeLatest(AuthTypes.SIGNUP_REQUEST, onSignup, api),
    takeLatest(AuthTypes.GOOGLE_LOGIN_REQUEST, onGoogleLogin, api),
    takeLatest(AuthTypes.WIX_LOGIN_REQUEST, onWixLogin, api),
    takeLatest(AuthTypes.ADMIN_LOGIN_REQUEST, onAdminLogin, api),
    takeLatest(AuthTypes.REDIRECTED_LOGIN_REQUEST, onRedirectedLogin, api),
    takeLatest(AuthTypes.FORGOT_PASSWORD_REQUEST, forgotPassword, api),
    takeLatest(AuthTypes.ME_REQUEST, meRequest, api),
    takeLatest(AuthTypes.MASQUERADE_REQUEST, masqueradeRequest, api),
  ])
}
