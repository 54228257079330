import Button from 'Components/Button'
import Card from 'Components/Card'
import Spinner from 'Components/Spinner'
import TabView from 'Components/TabView'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ArrowLeftIcon } from '@heroicons/react/solid'
import CardSection from 'Components/CardSection'
import moment from 'moment'
import UpdateCustomerModal from './UpdateCustomerModal'
import CustomerMessages from './CustomerMessages'
import CustomerActions from 'Redux/CustomerRedux'
import { useHistory } from 'react-router-dom'
import { ChevronLeftIcon } from '@heroicons/react/solid'

const CustomerDetails = (props) => {
  const queryParams = new URLSearchParams(window.location.search)
  const history = useHistory()

  const [selectedTab, setSelectedTab] = useState(0)
  let customerData = useSelector((state) => state.customer.detailData)
  const detailsFetching = useSelector((state) => state.customer.detailFetching)
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [upcomingBooking, setUpcomingBooking] = useState({})
  const [pastBooking, setPastBooking] = useState({})
  const [bookingCount, setBookingCount] = useState(0)
  const dispatch = useDispatch()
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    if (customerData && (customerData.email || customerData.name)) {
      setUpcomingBooking(customerData.upcoming_booking)
      setPastBooking(customerData.past_booking)
      setBookingCount(customerData.booking_count)
      setName(customerData.name)
      setEmail(customerData.email)
    }
  }, [customerData])

  const updateDetails = () => {
    setShowModal(true)
  }

  useEffect(() => {
    dispatch(
      CustomerActions.customersDetailRequest({
        name: queryParams.get('name'),
        email: queryParams.get('email'),
      })
    )
  }, [name, email])

  return (
    <>
      <div className="bg-white py-10 px-5 rounded-md">
        <div className="flex items-center justify-between mb-5">
          <div className="flex gap-3">
            <div className="flex flex-col gap-1">
              <div
                onClick={history.goBack}
                className="flex items-center text-sm font-medium text-gray-500 hover:text-gray-700 cursor-pointer"
              >
                <ChevronLeftIcon
                  className="flex-shrink-0 -ml-1 mr-1 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                Customer
              </div>
              <p className="text-lg font-medium pl-4">{customerData.name}</p>
              <p className="text-xs pl-4">
                Total {customerData.booking_count} booking
                {`${customerData.booking_count > 1 ? 's' : ''}`}
              </p>
            </div>
          </div>
          <Button type="primary" title="Edit Details" onClick={updateDetails} />
        </div>
        <TabView
          tabs={[
            { id: 0, name: 'Basic Information' },
            { id: 1, name: 'Messages' },
          ]}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
        {detailsFetching ? (
          <Spinner />
        ) : (
          <>
            {selectedTab == 0 && (
              <>
                <div className="grid grid-cols-12 gap-4 justify-between mb-4">
                  <div className="col-span-12 sm:col-span-8 md:col-span-6">
                    <Card title="Upcoming Booking Details">
                      {upcomingBooking && upcomingBooking.id && (
                        <>
                          <CardSection title="Service Name">
                            <p className="text-sm">
                              {upcomingBooking.variant_name
                                ? `${upcomingBooking.variant_name}`
                                : upcomingBooking.product_name}
                            </p>
                          </CardSection>
                          <CardSection
                            title={
                              upcomingBooking.scenario === 'multi_day'
                                ? 'Start Date'
                                : 'Duration'
                            }
                          >
                            <p lassName="text-sm">
                              {upcomingBooking.scenario === 'multi_day'
                                ? `${moment(upcomingBooking.start_date).format(
                                    'MMM Do YYYY'
                                  )}`
                                : `${upcomingBooking.duration} MINS`}
                            </p>
                          </CardSection>
                          <CardSection
                            title={
                              upcomingBooking.scenario === 'multi_day'
                                ? 'End Date'
                                : 'Appointment Time'
                            }
                          >
                            <p lassName="text-sm">
                              {upcomingBooking.scenario === 'multi_day'
                                ? `${moment(upcomingBooking.end_date).format(
                                    'MMM Do YYYY'
                                  )}`
                                : moment(upcomingBooking.selected_time).format(
                                    'llll'
                                  )}
                            </p>
                          </CardSection>
                        </>
                      )}
                    </Card>
                  </div>
                  <div className="col-span-12 sm:col-span-4 md:col-span-4">
                    {customerData && (
                      <Card title="Customer Details">
                        <CardSection classNames="text-sm">
                          <p className="text-sm">{customerData.name || ''}</p>
                          <p className="text-sm">{customerData.email || ''}</p>
                          <p className="text-sm">{customerData.phone || ''}</p>
                        </CardSection>
                      </Card>
                    )}
                  </div>
                </div>
                <div className="col-span-12 sm:grid grid-cols-12">
                  <div className="col-span-8 md:col-span-6">
                    {pastBooking && pastBooking.id && (
                      <Card title="Past Booking Details">
                        <CardSection title="Service Name">
                          <p className="text-sm">
                            {pastBooking.variant_name
                              ? `${pastBooking.variant_name}`
                              : pastBooking.product_name}
                          </p>
                        </CardSection>
                        <CardSection
                          title={
                            pastBooking.scenario === 'multi_day'
                              ? 'Start Date'
                              : 'Duration'
                          }
                        >
                          <p className="text-sm">
                            {pastBooking.scenario === 'multi_day'
                              ? `${moment(pastBooking.start_date).format(
                                  'MMM Do YYYY'
                                )}`
                              : `${pastBooking.duration} MINS`}
                          </p>
                        </CardSection>
                        <CardSection
                          title={
                            pastBooking.scenario === 'multi_day'
                              ? 'End Date'
                              : 'Appointment Time'
                          }
                        >
                          <p className="text-sm">
                            {pastBooking.scenario === 'multi_day'
                              ? `${moment(pastBooking.end_date).format(
                                  'MMM Do YYYY'
                                )}`
                              : moment(pastBooking.selected_time).format(
                                  'llll'
                                )}
                          </p>
                        </CardSection>
                      </Card>
                    )}
                  </div>
                </div>
              </>
            )}
            {selectedTab === 1 && (
              <CustomerMessages
                email={email}
                name={name}
                phone={customerData.phone}
              />
            )}
          </>
        )}
      </div>
      {showModal && (
        <UpdateCustomerModal
          showModal={showModal}
          setShowModal={setShowModal}
          // handleClose={(data) => {
          //   if (!!data) {
          //     if (props.fromRouter) {
          //       history.push(`/shopify/customers/view/${data}`)
          //     }
          //     loadData(data)
          //   }
          //   setShowModal(false)
          // }}
          email={email}
          name={name}
          phone={customerData.phone}
          handleClose={() => setShowModal(false)}
        />
      )}
    </>
  )
}

export default CustomerDetails
