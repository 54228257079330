import React, { useState, useCallback } from 'react'
import BookingActions from 'Redux/BookingRedux'
import { useDispatch, useSelector } from 'react-redux'
import Modal from 'Components/Modal'
import Select from 'Components/Select'

function EditSubscriptionModal(props) {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [status, setStatus] = useState(`${props.subscriptionStatus}`)

  const updateSubscriptionStatus = () => {
    setLoading(true)
    let params = {
      id: props.id,
      status,
    }

    dispatch(
      BookingActions.updateSubscriptionDetails(params, () => {
        setLoading(false)
        dispatch(BookingActions.bookingsSubscriptionRequest(props.id))
        props.handleClose()
      })
    )
  }

  const options = [
    { label: 'ACTIVE', value: 'ACTIVE' },
    { label: 'ARCHIVED', value: 'ARCHIVED' },
  ]

  return (
    <div>
      <Modal
        open={props.showModal}
        showModal={props.showModal}
        onClose={props.handleClose}
        title="Update Subscription Status"
        primaryButton={{
          title: 'Update',
          onClick: updateSubscriptionStatus,
          loading: loading,
        }}
      >
        <div className="p-5">
          <Select
            label="Subscription Status"
            options={options}
            selected={status}
            setSelected={(value) => {
              setStatus(value)
            }}
          />
        </div>
      </Modal>
    </div>
  )
}

export default EditSubscriptionModal
