import React, { useState } from 'react'
import { RadioGroup } from '@headlessui/react'
import classNames from 'classnames'
import Label from 'Components/Label'

export default function RadioButton(props) {
  const ctClass = classNames(
    'bg-white rounded-md -space-y-px my-2',
    props.containerClass
  )

  return (
    <RadioGroup
      value={props.selected}
      onChange={props.setSelected}
      className="my-6"
    >
      <Label title={props.label} />
      <div className={ctClass}>
        {props.data.map((d, dataIdx) => (
          <RadioGroup.Option
            key={d.name}
            value={d.value}
            className={({ checked }) =>
              classNames(
                dataIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                dataIdx === props.data.length - 1
                  ? 'rounded-bl-md rounded-br-md'
                  : '',
                checked
                  ? 'bg-green-50 border-green-200 z-10'
                  : 'border-gray-200',
                'relative border p-4 flex cursor-pointer focus:outline-none',
                checked ? props.checkedClass : '',
                props.optionsClass
              )
            }
          >
            {({ active, checked }) => (
              <>
                <span
                  className={classNames(
                    checked
                      ? 'bg-green-600 border-transparent'
                      : 'bg-white border-gray-300',
                    active ? 'ring-2 ring-offset-2 ring-green-500' : '',
                    'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center'
                  )}
                  aria-hidden="true"
                >
                  <span className="rounded-full bg-white w-1.5 h-1.5" />
                </span>
                <div className="ml-3 flex flex-col">
                  <RadioGroup.Label
                    as="span"
                    className={classNames(
                      checked ? 'text-green-900' : 'text-gray-900',
                      'block text-sm font-medium'
                    )}
                  >
                    {d.name}
                  </RadioGroup.Label>
                  {d.description && (
                    <RadioGroup.Description
                      as="span"
                      className={classNames(
                        checked ? 'text-green-700' : 'text-gray-500',
                        'block text-sm'
                      )}
                    >
                      {d.description}
                    </RadioGroup.Description>
                  )}
                </div>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  )
}
