import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'
import {
  listRequest,
  listSuccess,
  listFailure,
  upsertRequest,
  upsertSuccess,
  upsertFailure,
  detailRequest,
  detailSuccess,
  detailFailure,
  deleteFailure,
  deleteRequest,
  deleteSuccess,
} from './genericReducers'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  servicesListRequest: ['params'],
  servicesListSuccess: ['list'],
  servicesListFailure: ['listError'],

  servicesUpsertRequest: ['params', 'callback'],
  servicesUpsertSuccess: [],
  servicesUpsertFailure: ['upsertError'],

  servicesDetailRequest: ['id'],
  servicesDetailSuccess: ['detailData'],
  servicesDetailFailure: ['detailError'],

  servicesDeleteRequest: ['productId', 'callback'],
  servicesDeleteSuccess: [],
  servicesDeleteFailure: [],
})

export const ServiceTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  list: [],
  listFetching: null,
  listError: null,

  upsertFetching: null,
  upsertError: null,
  sendingEmail: false,

  detailData: {},
  detailError: null,
  detailFetching: false,

  deleteFetching: null,
  deleteError: null,
})

/* ------------- Reducers ------------- */

export const reset = (state) => state.merge(INITIAL_STATE)

export const resetBasedOnKey = (state, { key }) => {
  return state.merge({
    [key]: INITIAL_STATE[key],
  })
}

/* ------------- Selectors ------------- */

export const ServiceSelectors = {}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SERVICES_LIST_REQUEST]: listRequest,
  [Types.SERVICES_LIST_SUCCESS]: listSuccess,
  [Types.SERVICES_LIST_FAILURE]: listFailure,

  [Types.SERVICES_UPSERT_REQUEST]: upsertRequest,
  [Types.SERVICES_UPSERT_SUCCESS]: upsertSuccess,
  [Types.SERVICES_UPSERT_FAILURE]: upsertFailure,

  [Types.SERVICES_DETAIL_REQUEST]: detailRequest,
  [Types.SERVICES_DETAIL_SUCCESS]: detailSuccess,
  [Types.SERVICES_DETAIL_FAILURE]: detailFailure,

  [Types.SERVICES_DELETE_REQUEST]: deleteRequest,
  [Types.SERVICES_DELETE_SUCCESS]: deleteSuccess,
  [Types.SERVICES_DELETE_FAILURE]: deleteFailure,

  // [Types.SERVICES_KEY_RESET]: resetBasedOnKey,
})
