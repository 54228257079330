import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import CustomerActions from 'Redux/CustomerRedux'
import moment from 'moment'
import styles from 'Styles/ManualBookingModal.css'
import Input from 'Components/Input'
import Button from 'Components/Button'

const CustomerMessages = (props) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [messages, setMessages] = useState([])
  const [currentMessage, setCurrentMessage] = useState('')
  const messagesEndRef = useRef(null)
  const settingsData = useSelector((state) => state.setting.data)
  useEffect(() => {
    fetchMessages()
    dispatch(
      CustomerActions.markAsRead(
        {
          email: props.email,
          name: props.name,
        },
        () => {}
      )
    )
  }, [])

  const fetchMessages = () => {
    dispatch(
      CustomerActions.customerMessages(
        {
          email: props.email,
          name: props.name,
        },
        (data) => {
          setMessages(data)
          setTimeout(() => {
            if (messagesEndRef.current) {
              document
                .getElementById(`message_${data.length - 1}`)
                .scrollIntoView({
                  behavior: 'smooth',
                })
            }
          }, 300)
        }
      )
    )
  }

  const sendMessage = () => {
    setLoading(true)
    dispatch(
      CustomerActions.sendMessage(
        {
          message: currentMessage,
          phone: props.phone,
        },
        (data) => {
          setCurrentMessage('')
          setTimeout(() => {
            fetchMessages()
            setLoading(false)
          }, 1000)
        }
      )
    )
  }

  const getViewForMessage = (m, i) => {
    if (m.sent_by === 'admin') {
      return (
        <div id={`message_${i}`} className={styles.admin}>
          <div className={styles.adminInner}>
            <p className={styles.message}>{m.message}</p>
            <p className={styles.delivered}>
              {moment(m.updated_at).format('lll')}
            </p>
          </div>
        </div>
      )
    }

    return (
      <div id={`message_${i}`} className={styles.customer}>
        <div className={styles.customerInner}>
          <p className={styles.message}>{m.message}</p>
          <p className={styles.delivered}>
            {moment(m.updated_at).format('lll')}
          </p>
        </div>
      </div>
    )
  }

  return (
    <div style={{ marginTop: 20, marginBottom: 20 }}>
      <div
        className="Polaris-Card"
        style={{ maxHeight: '50vh', overflow: 'auto' }}
      >
        {settingsData.usage_charge_activated ? (
          <div className="Polaris-Card__Section" ref={messagesEndRef}>
            {props.phone ? (
              messages.length === 0 ? (
                <p>
                  There are no conversations with this customer. Start a new
                  conversation below
                </p>
              ) : (
                messages.map((m, i) => {
                  return getViewForMessage(m, i)
                })
              )
            ) : (
              <p>
                No phone number has been attached by the customer due to which
                there cannot be messages exchanged with the customer
              </p>
            )}
          </div>
        ) : (
          <p>
            Please activate sms sending from Settings {`->`} Text Settings to
            use this feature
          </p>
        )}
      </div>
      {props.phone && settingsData.usage_charge_activated && (
        <div style={{ marginTop: 16 }}>
          <Input
            label=""
            onChange={(value) => setCurrentMessage(value)}
            value={currentMessage}
            multiline={3}
            placeholder="Write your message here"
            connectedRight={
              <Button
                onClick={sendMessage}
                disabled={!currentMessage}
                loading={loading}
                variant="primary"
              >
                Send
              </Button>
            }
          />
        </div>
      )}
    </div>
  )
}

export default CustomerMessages
