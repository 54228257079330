import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'
import {
  listRequest,
  listSuccess,
  listFailure,
  detailRequest,
  detailSuccess,
  detailFailure,
} from './genericReducers'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  customersListRequest: ['params', 'listDataOffset', 'callback'],
  customersListSuccess: ['list'],
  customersListFailure: ['listError'],

  allCustomersListRequest: ['params', 'listDataOffset', 'callback'],
  allCustomersListSuccess: ['list'],
  allCustomersListFailure: ['listError'],

  customersDetailRequest: ['id'],
  customersDetailSuccess: ['detailData'],
  customersDetailFailure: ['detailError'],

  updateAllCustomer: ['params', 'callback'],

  customerMessages: ['params', 'callback'],

  markAsRead: ['params', 'callback'],
})

export const CustomerTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  shopifyCustomersList: [],
  shopifyCustomersListFetching: null,
  shopifyCustomersListError: null,

  list: [],
  listFetching: null,
  listError: null,
  listDataOffset: 0,

  detailData: {},
  detailError: null,
  detailFetching: false,
})

/* ------------- Reducers ------------- */

export const reset = (state) => state.merge(INITIAL_STATE)

/* ------------- Selectors ------------- */

export const CustomerSelectors = {
  listDataOffset: (state) => state.customer.listDataOffset,
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.CUSTOMERS_LIST_REQUEST]: listRequest,
  [Types.CUSTOMERS_LIST_SUCCESS]: listSuccess,
  [Types.CUSTOMERS_LIST_FAILURE]: listFailure,

  [Types.ALL_CUSTOMERS_LIST_REQUEST]: listRequest,
  [Types.ALL_CUSTOMERS_LIST_SUCCESS]: listSuccess,
  [Types.ALL_CUSTOMERS_LIST_FAILURE]: listFailure,

  [Types.CUSTOMERS_DETAIL_REQUEST]: detailRequest,
  [Types.CUSTOMERS_DETAIL_SUCCESS]: detailSuccess,
  [Types.CUSTOMERS_DETAIL_FAILURE]: detailFailure,
})
