import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import React, { Fragment, useEffect, useState } from 'react'
var classNames = require('classnames')

export default function Select(props) {
  const [selected, setSelected] = useState([])
  let data = props.options

  useEffect(() => {
    if (props.multiple) {
      setSelected(data.filter((d) => props.selected.includes(d.value)))
    } else {
      setSelected(data.find((d) => d.value === props.selected) || null)
    }
  }, [props.selected])

  const handleChange = (selectedOption) => {
    if (props.multiple) {
      const selectedValues = selected.map((s) => s.value)
      if (selectedValues.includes(selectedOption.value)) {
        setSelected(selected.filter((s) => s.value !== selectedOption.value))
        props.setSelected(
          selectedValues.filter((v) => v !== selectedOption.value)
        )
      } else {
        setSelected([...selected, selectedOption])
        props.setSelected([...selectedValues, selectedOption.value])
      }
    } else {
      setSelected(selectedOption)
      props.setSelected(selectedOption.value)
    }
  }

  return (
    <div className={props.containerClass}>
      <Listbox
        value={selected}
        onChange={handleChange}
        multiple={props.multiple}
      >
        {({ open }) => (
          <>
            <Listbox.Label className="block text-sm font-medium text-gray-700">
              {props.label}
            </Listbox.Label>
            <div className="mt-1 relative">
              <Listbox.Button
                className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500 sm:text-sm"
                style={{ minWidth: '150px' }}
              >
                <span className="block truncate">
                  {props.multiple
                    ? selected.map((s) => s.label).join(', ') ||
                      'Select Options'
                    : selected?.label ??
                      (props.selected === '0' ? '0 day' : 'Select an Option')}
                </span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <SelectorIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options
                  static
                  className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                >
                  {data.map((d) => (
                    <Listbox.Option
                      key={d.value}
                      className={({ active }) =>
                        classNames(
                          active ? 'text-white bg-green-600' : 'text-gray-900',
                          'cursor-default select-none relative py-2 pl-3 pr-9'
                        )
                      }
                      value={d}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={classNames(
                              selected ? 'font-semibold' : 'font-normal',
                              'block truncate'
                            )}
                          >
                            {d.label}
                          </span>

                          {selected ? (
                            <span
                              className={classNames(
                                active ? 'text-white' : 'text-green-600',
                                'absolute inset-y-0 right-0 flex items-center pr-4'
                              )}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
    </div>
  )
}
