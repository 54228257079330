import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'
import {
  listRequest,
  listSuccess,
  listFailure,
  createRequest,
  createSuccess,
  createFailure,
  deleteRequest,
  deleteSuccess,
  deleteFailure,
  updateRequest,
  updateSuccess,
  updateFailure,
  detailRequest,
  detailSuccess,
  detailFailure,
} from './genericReducers'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  workflowsListRequest: ['params'],
  workflowsListSuccess: ['list'],
  workflowsListFailure: ['listError'],

  workflowsTypesListRequest: ['typesListParams'],
  workflowsTypesListSuccess: ['typesList'],
  workflowsTypesListFailure: ['typesListError'],

  workflowsDetailRequest: ['id'],
  workflowsDetailSuccess: ['detailData'],
  workflowsDetailFailure: ['detailError'],

  workflowsCreateRequest: ['params', 'callback'],
  workflowsCreateSuccess: ['createData'],
  workflowsCreateFailure: ['createError'],

  workflowsUpdateRequest: ['params', 'callback'],
  workflowsUpdateSuccess: ['data'],
  workflowsUpdateFailure: ['error'],

  workflowsDeleteRequest: ['id', 'callback'],
  workflowsDeleteSuccess: [],
  workflowsDeleteFailure: [],

  workflowsKeyReset: ['key'],
})

export const WorkflowTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  list: [],
  listFetching: null,
  listError: null,

  typesList: {},
  typesListFetching: null,
  typesListError: null,

  detailData: {},
  detailError: null,
  detailFetching: false,

  createData: {},
  createError: null,
  createFetching: false,

  deleteFetching: null,
  deleteError: null,

  updateData: {},
  updateError: null,
  updateFetching: false,
})

/* ------------- Reducers ------------- */

export const reset = (state) => state.merge(INITIAL_STATE)

export const typesListRequest = (
  state,
  { typesListParams, typesListDataOffset }
) => {
  return state.merge({
    typesListParams,
    typesListFetching: true,
    typesListError: null,
    typesListDataOffset: typesListDataOffset || 0,
  })
}

export const typesListSuccess = (state, { typesList }) => {
  return state.merge({
    typesList,
    typesListFetching: false,
  })
}

export const typesListFailure = (state, { typesListError }) => {
  return state.merge({ typesListError, typesListFetching: false })
}

export const resetBasedOnKey = (state, { key }) => {
  return state.merge({
    [key]: INITIAL_STATE[key],
  })
}

/* ------------- Selectors ------------- */

export const WorkflowSelectors = {}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.WORKFLOWS_LIST_REQUEST]: listRequest,
  [Types.WORKFLOWS_LIST_SUCCESS]: listSuccess,
  [Types.WORKFLOWS_LIST_FAILURE]: listFailure,

  [Types.WORKFLOWS_TYPES_LIST_REQUEST]: typesListRequest,
  [Types.WORKFLOWS_TYPES_LIST_SUCCESS]: typesListSuccess,
  [Types.WORKFLOWS_TYPES_LIST_FAILURE]: typesListFailure,

  [Types.WORKFLOWS_DETAIL_REQUEST]: detailRequest,
  [Types.WORKFLOWS_DETAIL_SUCCESS]: detailSuccess,
  [Types.WORKFLOWS_DETAIL_FAILURE]: detailFailure,

  [Types.WORKFLOWS_CREATE_REQUEST]: createRequest,
  [Types.WORKFLOWS_CREATE_SUCCESS]: createSuccess,
  [Types.WORKFLOWS_CREATE_FAILURE]: createFailure,

  [Types.WORKFLOWS_DELETE_REQUEST]: deleteRequest,
  [Types.WORKFLOWS_DELETE_SUCCESS]: deleteSuccess,
  [Types.WORKFLOWS_DELETE_FAILURE]: deleteFailure,

  [Types.WORKFLOWS_UPDATE_REQUEST]: updateRequest,
  [Types.WORKFLOWS_UPDATE_SUCCESS]: updateSuccess,
  [Types.WORKFLOWS_UPDATE_FAILURE]: updateFailure,

  [Types.WORKFLOWS_KEY_RESET]: resetBasedOnKey,
})
