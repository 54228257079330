import React, { useState, useCallback } from 'react'
import CheckBox from './Checkbox'

const CheckBoxGroup = React.forwardRef(
  ({ options, selectedValues, onChange, errorText }, ref) => {
    const error = errorText ? (
      <p className="mt-2 text-sm text-red-600">{errorText}</p>
    ) : null
    //Mimicing the force update in the fucntional component
    const [, updateState] = useState()
    const forceUpdate = useCallback(() => updateState({}), [])

    const handleOnchange = (val, checked) => {
      if (onChange) {
        if (checked) {
          let index = selectedValues.indexOf(val)
          if (index === -1) {
            selectedValues.push(val)
          }
        } else {
          let index = selectedValues.indexOf(val)
          if (index !== -1) {
            selectedValues.splice(index, 1)
          }
        }
        onChange([...selectedValues])
      }
      // force update in functional component
      forceUpdate()
    }
    return (
      <div className="flex flex-col">
        <div className="flex flex-col ">
          {options.map((option, index) => {
            return (
              <>
                <div
                  className="flex items-center justify-between pt-4 pb-4 p-6"
                  key={index}
                >
                  <CheckBox
                    name={option.label}
                    value={selectedValues.includes(option.value)}
                    title={option.label}
                    onClick={(value) => {
                      handleOnchange(option.value, value)
                    }}
                  />
                </div>
                <hr />
              </>
            )
          })}
        </div>
        {error}
      </div>
    )
  }
)
export default CheckBoxGroup
