import { all, call, put, takeLatest } from 'redux-saga/effects'
import ThinkificAction, { ThinkificTypes } from 'Redux/ThinkificRedux'

export function* getAll(api, action) {
  const resp = yield call(api.getThinificCourses, action.params)
  if (resp.ok) {
    yield put(ThinkificAction.thinkificListSuccess(resp.data))
  } else {
    yield put(ThinkificAction.thinkificListFailure('something went wrong'))
  }
}

export function* upsertThinkific(api, action) {
  const resp = yield call(api.upsertThinkificCourses, action.params)
  if (resp.ok) {
    yield put(ThinkificAction.thinkificUpsertSuccess(resp.data))
    action.callback(resp.data)
  } else {
    yield put(ThinkificAction.thinkificUpsertFailure('something went wrong'))
    action.callback()
  }
}

export default function* appointmentSagas(api) {
  yield all([
    takeLatest(ThinkificTypes.THINKIFIC_LIST_REQUEST, getAll, api),
    takeLatest(ThinkificTypes.THINKIFIC_UPSERT_REQUEST, upsertThinkific, api),
  ])
}
