import React, { useState, useCallback, useEffect } from 'react'
import Input from 'Components/Input'
import AuthAction from 'Redux/AuthRedux'
import Button from 'Components/Button'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { GoogleLogin } from 'react-google-login'
import { TrackEvent, logo_url } from 'Themes/constants'
import styles from 'Styles/Integrations.module.css'

export default function Signup() {
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [error, setError] = useState('')
  const dispatch = useDispatch()
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [shopifyLink, setShopifyLink] = useState(null)

  const signUp = () => {
    if (password !== confirmPassword) {
      setError('Passwords do not match. Please try again')
      return
    }
    setLoading(true)
    dispatch(
      AuthAction.signupRequest({ email, password, name }, (resp, err) => {
        setLoading(false)
        if (!!err) {
          if (resp && resp.errors === 'shopify_store_exist') {
            setError(err)
            setShopifyLink(
              `https://admin.shopify.com/store/${
                resp.shopify_domain.split('.myshopify')[0]
              }/apps/apppointment-booking-dev`
            )
          } else {
            setError(err)
            setShopifyLink(null)
          }
        } else {
          TrackEvent('New Sign Up', { email, name, type: 'normal' })
          window.location.href = '/dashboard'
        }
      })
    )
  }

  const onGoogleLogin = (code) => {
    setLoading(true)
    dispatch(
      AuthAction.googleLoginRequest({ code }, (resp, err) => {
        setLoading(false)
        if (!!err) {
          setError(err)
        } else {
          TrackEvent('New Sign Up', { type: 'google' })
          window.location.href = '/dashboard'
        }
      })
    )
  }

  const checkDisabled = () => {
    return !email || !password || !confirmPassword || !name
  }

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-16 w-auto" src={logo_url} alt="Appointo" />
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Create an account
        </h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <div className="space-y-6">
            <div>
              <Input
                label="Email"
                placeholder="Enter your email"
                containerClass="col-span-full sm:col-span-2"
                value={email}
                onChange={(value) => {
                  setEmail(value)
                }}
              />
            </div>

            <div>
              <Input
                label="Individual / Business name"
                placeholder="Enter your name"
                containerClass="col-span-full sm:col-span-2"
                value={name}
                onChange={(value) => {
                  setName(value)
                }}
              />
            </div>

            <div>
              <Input
                label="Password"
                placeholder="Enter your password"
                containerClass="col-span-full sm:col-span-2"
                value={password}
                onChange={(value) => {
                  setPassword(value)
                }}
                type="password"
              />
            </div>

            <div>
              <Input
                label="Confirm Password"
                placeholder="Confirm your password"
                containerClass="col-span-full sm:col-span-2"
                value={confirmPassword}
                onChange={(value) => {
                  setConfirmPassword(value)
                }}
                errorText={error}
                type="password"
              />
              {shopifyLink && (
                <a
                  className={styles.ext_link}
                  target="_blank"
                  href={shopifyLink}
                >
                  Click here
                </a>
              )}
            </div>

            <div>
              <Button
                title="Sign up"
                onClick={signUp}
                type="primary"
                className="w-full justify-center"
                disabled={checkDisabled()}
                loading={loading}
              />
            </div>
          </div>

          <div className="mt-6">
            <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="px-2 bg-white text-gray-500">
                  Or continue with
                </span>
              </div>
            </div>

            <div className="mt-6 flex align-center justify-center">
              <div>
                <GoogleLogin
                  clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                  buttonText="Sign up with Google"
                  onSuccess={onGoogleLogin}
                  onFailure={(err) => {
                    console.log(err)
                  }}
                  cookiePolicy={'single_host_origin'}
                  scope="email profile"
                  responseType="code"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
