import Input from 'Components/Input'
import Modal from 'Components/Modal'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import CustomerActions from 'Redux/CustomerRedux'

function UpdateCustomerModal(props) {
  const [name, setName] = useState(props.name || '')
  const [email, setEmail] = useState(props.email || '')
  const [phone, setPhone] = useState(props.phone || '')
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  const updateCustomer = () => {
    setLoading(true)
    let params = {
      old_email: props.email,
      new_email: email,
      old_name: props.name,
      new_name: name,
      phone: phone,
    }

    dispatch(
      CustomerActions.updateAllCustomer(params, () => {
        setLoading(false)
        props.handleClose(`${email}__${name}`)
        dispatch(CustomerActions.customersDetailRequest({ email, name }))
      })
    )
  }

  return (
    <div>
      <Modal
        open={props.showModal}
        showModal={props.showModal}
        onClose={props.handleClose}
        title="Customer Details"
        primaryButton={{
          title: 'Save',
          onClick: updateCustomer,
          loading: loading,
        }}
      >
        <div style={{ padding: 20 }}>
          <Input
            label="Email"
            onChange={(value) => setEmail(value)}
            value={email}
            placeholder="Enter your email"
          />
          <div style={{ marginTop: 20 }}>
            <Input
              label="Name"
              onChange={(value) => setName(value)}
              value={name}
              placeholder="Enter your name"
            />
          </div>
          <div style={{ marginTop: 20 }}>
            <Input
              label="Phone"
              onChange={(value) => setPhone(value)}
              value={phone}
              placeholder="Enter your phone no"
            />
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default UpdateCustomerModal
