import Button from 'Components/Button'
import CardHeading from 'Components/CardHeading'
import Checkbox from 'Components/Checkbox'
import Container from 'Components/Container'
import CustomFields from 'Components/CustomFields'
import DateOverrides from 'Components/DateOverrides'
import Heading from 'Components/Heading'
import Input from 'Components/Input'
import Select from 'Components/Select'
import Spinner from 'Components/Spinner'
import Toast from 'Components/Toast'
import Label from 'Components/Label'
import RadioButton from 'Components/RadioButton'
import WeeklyHours from 'Components/WeeklyHours'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import VariantActions from 'Redux/VariantRedux'
import {
  convertTime,
  generateDateRange,
  locationOptions,
  selectOptions,
} from 'Themes/constants'
import ReactSelect from 'react-select'
import TeamActions from 'Redux/TeamRedux'
import ServiceActions from 'Redux/ServiceRedux'
import moment from 'moment'
import store from 'store'
import TabView from 'Components/TabView'
import Workflow from 'Components/Services/Workflow/Workflow'
import HourMinutePicker from 'Components/HourMinutePicker'
import RangeDatePicker from 'Components/Modals/RangeDatePicker'

const invitessOptions = [
  {
    label: 'X days into the future',
    value: 'days_in_future',
  },
  {
    label: 'Within a date range',
    value: 'date_range',
  },
  {
    label: 'Indefinitely into the future',
    value: 'all',
  },
]

const monthStartOtions = [
  {
    label: 'Manual Date',
    value: 'manual',
  },
  {
    label: 'Current Month',
    value: 'current',
  },
]

const EditVariant = (props) => {
  const dispatch = useDispatch()
  const [showToast, setShowToast] = useState(false)
  const [showPaidToast, setShowPaidToast] = useState(false)
  const [override, setOverride] = useState([])
  const [weeklyHours, setWeeklyHours] = useState([])
  const [duration, setDuration] = useState(30)
  const [timezone, setTimezone] = useState(selectOptions[0])
  const [meetingDetails, setMeetingDetails] = useState('')
  const [name, setName] = useState('')
  const [price, setPrice] = useState('')
  const [note, setNote] = useState('')
  const [color, setColor] = useState('')
  const [groupAppointment, setGroupAppointment] = useState(false)
  const [maxCapacity, setMaxCapacity] = useState('2')
  const [sendReminder, setSendReminder] = useState(false)
  const [reminderDuration, setReminderDuration] = useState(`10`)
  const [textReminder, setSendTextReminder] = useState(false)
  const [allowCustomerWaitlist, setAllowCustomerWaitlist] = useState(false)
  const [removeGroupSlot, setRemoveGroupSlot] = useState(false)
  const [textReminderDuration, setTextReminderDuration] = useState(`10`)
  const [whatsappReminder, setSendWhatsappReminder] = useState(false)
  const [whatsappReminderDuration, setWhatsAppReminderDuration] = useState(`10`)
  const [schedule, setSchedule] = useState(`240`)
  const [interval, setInterval] = useState(`30`)
  const [maxEventsChecked, setMaxEventsChecked] = useState(false)
  const [maxEvents, setMaxEvents] = useState(`1`)
  const [startTimeBuffer, setStartTimeBuffer] = useState(`0`)
  const [endTimeBuffer, setEndTimeBuffer] = useState(`0`)
  const [customFields, setCustomFields] = useState([])
  const detailFetching = useSelector((state) => state.variant.detailFetching)
  const detailData = useSelector((state) => state.variant.detailData)
  const upsertFetching = useSelector((state) => state.variant.upsertFetching)
  const productDetail = useSelector((state) => state.service.detailData)
  const [teamMembers, setTeamMembers] = useState([])
  const [tmAssignRule, setTmAssignRule] = useState('random')
  const [teamOptions, setTeamOptions] = useState([])
  const teamList = useSelector((state) => state.team.list)
  const [allowCustomerReschedule, setAllowCustomerReschedule] = useState(false)
  const [allowCustomerCancel, setAllowCustomerCancel] = useState(false)
  const [workflows, setWorkflows] = useState([])
  const [dateRangeType, setDateRangeType] = useState('all')
  const [daysInFuture, setDaysInFuture] = useState(`0`)
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(null)
  const [businessHours, setBusinessHours] = useState(false)
  const [selectedLocation, setSelectedLocation] = useState('')
  const [selectedLocationValue, setSelectedLocationValue] = useState('')
  const [showGoogleToast, setShowGoogleToast] = useState(false)
  const [showZoomToast, setShowZoomToast] = useState(false)
  const [rescheduleDuration, setRescheduleDuration] = useState(`10`)
  const [cancelDuration, setCancelDuration] = useState(`10`)
  const [selectedTab, setSelectedTab] = useState('basic')
  const [appointmentName, setAppointmentName] = useState('')
  const [isComboService, setIsComboService] = useState(false)
  const [calendarStartMonthType, setCalendarStartMonthType] =
    useState('current')
  const [calendarStartMonth, setCalendarStartMonth] = useState('')
  const [shopifyUser, setShopifyUser] = useState(false)

  let userData = store.get('userData')
  let thinkificSubdomain = store.get('thinkific_subdoamin')
  const role = userData.role
  const showHeader = props.showHeader ?? true

  const variantBody = {
    id: props.variantId ?? props.match.params.id,
    productId: props.productId ?? props.match.params.productId,
    workflows: workflows.map((workflow) => ({ workflow_id: workflow.id })),
    config: {
      timezone: timezone,
      duration: duration,
      minimum_notice: schedule || 0,
      max_capacity: groupAppointment ? maxCapacity : 1,
      meeting_detail: meetingDetails || '',
      note: note || '',
      color: color || '#51796B',
      interval: interval,
      reminder_duration: reminderDuration,
      send_reminder: sendReminder,
      enable_max_events_per_day: maxEventsChecked,
      max_events_per_day: maxEventsChecked && maxEvents > 0 ? maxEvents : 1,
      start_buffer_time: startTimeBuffer || `0`,
      end_buffer_time: endTimeBuffer || `0`,
      tm_assign_rule: tmAssignRule,
      start_date_range: moment(startDate).format('YYYY-MM-DD'),
      end_date_range: moment(endDate).format('YYYY-MM-DD'),
      date_range_type: dateRangeType,
      days_in_future: daysInFuture,
      allow_reschedule: allowCustomerReschedule,
      allow_cancel: allowCustomerCancel,
      send_text_reminder: textReminder,
      text_duration: textReminderDuration,
      whatsapp_duration: whatsappReminderDuration,
      send_whatsapp_reminder: whatsappReminder,
      location_type: selectedLocation,
      location_value: selectedLocationValue,
      reschedule_duration: rescheduleDuration,
      cancel_duration: cancelDuration,
      allow_waitlist: allowCustomerWaitlist,
      rem_group_slot_after_booking: removeGroupSlot,
      is_combo_service: teamMembers.length > 1 ? isComboService : false,
      start_month:
        calendarStartMonthType === 'manual' ? calendarStartMonth : '',
    },
    price: price,
    availabilities: weeklyHours,
    override: override,
    fixed_appointments: [],
    custom_fields: customFields.map((c, i) => {
      return {
        ...c,
        position: i + 1,
      }
    }),
    team_members: teamMembers.map((t) => {
      return { team_member_id: t }
    }),
    name: appointmentName,
  }

  useEffect(() => {
    if (!showHeader) {
      props.setVariant(variantBody)
    }
  }, [JSON.stringify(variantBody), showHeader])

  useEffect(() => {
    if (props?.match?.params?.id && props?.match?.params?.productId) {
      dispatch(TeamActions.teamsListRequest())
      dispatch(ServiceActions.servicesDetailRequest(props.match.params.id))

      dispatch(
        VariantActions.variantsDetailRequest(
          props.match.params.id,
          props.match.params.productId
        )
      )
    } else {
      dispatch(TeamActions.teamsListRequest())
      dispatch(
        VariantActions.variantsDetailRequest(props.variantId, props.productId)
      )
    }
  }, [props.variantId, props.productId])

  useEffect(() => {
    if (!!detailData.id) {
      let appt = detailData
      setOverride(appt.overridden_availabilities)
      setWeeklyHours(appt.weekly_availabilities)
      setDuration(
        appt.appointment_config ? `${appt.appointment_config.duration}` : `30`
      )
      setSchedule(
        appt.appointment_config
          ? `${appt.appointment_config.minimum_notice}`
          : `240`
      )
      setIsComboService(
        appt.appointment_config
          ? appt.appointment_config.is_combo_service
          : false
      )
      setTimezone(appt.appointment_config.timezone)
      setGroupAppointment(
        appt.appointment_config
          ? appt.appointment_config.max_capacity > 1
          : false
      )
      setMaxCapacity(
        appt.appointment_config
          ? `${appt.appointment_config.max_capacity}`
          : `2`
      )
      setMeetingDetails(
        appt.appointment_config
          ? `${appt.appointment_config.meeting_detail || ''}`
          : ''
      )
      setNote(
        appt.appointment_config ? `${appt.appointment_config.note || ''}` : ''
      )
      setColor(
        appt.appointment_config ? `${appt.appointment_config.color || ''}` : ''
      )
      setInterval(
        appt.appointment_config ? `${appt.appointment_config.interval}` : `30`
      )

      setSendReminder(
        appt.appointment_config ? appt.appointment_config.send_reminder : false
      )
      setReminderDuration(
        appt.appointment_config
          ? `${appt.appointment_config.reminder_duration}`
          : `10`
      )

      setSendTextReminder(
        appt.appointment_config
          ? appt.appointment_config.send_text_reminder
          : false
      )
      setAllowCustomerWaitlist(
        appt.appointment_config ? appt.appointment_config.allow_waitlist : false
      )
      setRemoveGroupSlot(
        appt.appointment_config
          ? appt.appointment_config.rem_group_slot_after_booking
          : false
      )
      setCalendarStartMonthType(
        appt.appointment_config && appt.appointment_config.start_month !== ''
          ? 'manual'
          : 'current'
      )
      setCalendarStartMonth(
        appt.appointment_config ? appt.appointment_config.start_month : ''
      )
      setTextReminderDuration(
        appt.appointment_config
          ? `${appt.appointment_config.text_duration}`
          : `10`
      )

      setSendWhatsappReminder(
        appt.appointment_config
          ? appt.appointment_config.send_whatsapp_reminder
          : false
      )
      setWhatsAppReminderDuration(
        appt.appointment_config
          ? `${appt.appointment_config.whatsapp_duration}`
          : `10`
      )

      setCustomFields(
        [...appt.custom_fields].sort((a, b) => a.position - b.position)
      )

      setMaxEventsChecked(
        appt.appointment_config
          ? appt.appointment_config.enable_max_events_per_day
          : false
      )
      setMaxEvents(
        appt.appointment_config
          ? `${appt.appointment_config.max_events_per_day}`
          : `1`
      )

      setStartTimeBuffer(
        appt.appointment_config
          ? `${appt.appointment_config.start_buffer_time}`
          : `0`
      )
      setEndTimeBuffer(
        appt.appointment_config
          ? `${appt.appointment_config.end_buffer_time}`
          : `0`
      )
      setName(appt.name)
      setPrice(appt.price)
      setTeamMembers(
        appt.team_members.length > 0 ? appt.team_members.map((t) => t.id) : []
      )
      setTmAssignRule(
        appt.appointment_config
          ? appt.appointment_config.tm_assign_rule
          : 'random'
      )
      setWorkflows(appt.workflows)
      setDateRangeType(
        appt.appointment_config
          ? appt.appointment_config.date_range_type
          : 'all'
      )
      setDaysInFuture(
        appt.appointment_config
          ? `${appt.appointment_config.days_in_future}`
          : '0'
      )
      setStartDate(
        appt.appointment_config && appt.appointment_config.start_date_range
          ? new Date(appt.appointment_config.start_date_range)
          : new Date()
      )
      setEndDate(
        appt.appointment_config && appt.appointment_config.start_date_range
          ? new Date(appt.appointment_config.end_date_range)
          : new Date()
      )

      setAllowCustomerCancel(
        appt.appointment_config ? appt.appointment_config.allow_cancel : false
      )
      setAllowCustomerReschedule(
        appt.appointment_config
          ? appt.appointment_config.allow_reschedule
          : false
      )

      setBusinessHours(userData.setting.enable_global_availability)
      setSelectedLocation(
        appt.appointment_config
          ? `${appt.appointment_config.location_type}`
          : ``
      )
      setSelectedLocationValue(
        appt.appointment_config
          ? `${appt.appointment_config.location_value || ''}`
          : ``
      )
      setRescheduleDuration(
        appt.appointment_config
          ? appt.appointment_config.reschedule_duration
          : `10`
      )
      setCancelDuration(
        appt.appointment_config ? appt.appointment_config.cancel_duration : `10`
      )
      setAppointmentName(appt.name)
      setShopifyUser(userData.shop.flow === 'shopify')
    }
  }, [detailData])

  useEffect(() => {
    console.log(productDetail, props.fromProduct)
    if (productDetail && props.fromProduct) {
      setName(productDetail.product_name)
    }
  }, [productDetail, detailData])

  useEffect(() => {
    let list = teamList
      .filter((t) => t.confirmed)
      .map((t) => {
        return {
          label: t.email,
          value: t.id,
        }
      })
    setTeamOptions([{ label: '', value: null }, ...list])
  }, [teamList])

  const onChangeDate = (dates) => {
    const [start, end] = dates
    setStartDate(start)
    setEndDate(end)
  }

  const onSave = () => {
    dispatch(
      VariantActions.variantsUpsertRequest(variantBody, () => {
        setShowToast(true)
      })
    )
  }

  const handleTmChange = (value) => {
    setTmAssignRule(value)
  }

  const onChangeLocation = (option) => {
    switch (option.value) {
      case 'google_meet':
        if (userData.cal_connection && userData.cal_connection.email) {
          setSelectedLocation(option.value)
        } else {
          setShowGoogleToast(true)
          setSelectedLocation(selectedLocation)
        }
        break
      case 'zoom':
        if (userData.zoom_detail && userData.zoom_detail.email) {
          setSelectedLocation(option.value)
        } else {
          setShowZoomToast(true)
          setSelectedLocation(selectedLocation)
        }
        break
      default:
        setSelectedLocation(option.value)
    }
  }

  let dateRangeOptions = generateDateRange()

  return (
    <>
      <Toast
        show={showToast}
        setShow={setShowToast}
        title="Variant has been saved successfully"
      />

      <Toast
        show={showPaidToast}
        setShow={setShowPaidToast}
        title="Please upgrade your plan to use this feature"
        type="info"
      />

      <Toast
        title="Google account not connected. Please go to 'Integrations' tab and connect your google account"
        setShow={setShowGoogleToast}
        show={showGoogleToast}
        type="info"
      />

      <Toast
        title="Zoom account not connected. Please go to 'Integrations' tab and connect your zoom account"
        setShow={setShowZoomToast}
        show={showZoomToast}
        type="info"
      />

      <div>
        {showHeader && (
          <Heading
            title="Edit Variant"
            backAction={{ title: 'Edit Service' }}
            rightSection={
              <Button
                title="Save"
                onClick={onSave}
                type="primary"
                loading={upsertFetching}
              />
            }
          />
        )}

        {detailFetching ? (
          <Spinner />
        ) : (
          <>
            {!thinkificSubdomain && (
              <Container containerClass="my-6">
                <form className="space-y-6">
                  <CardHeading
                    title={
                      props.fromProduct
                        ? 'Product Configuration'
                        : 'Variant Configuration'
                    }
                  />
                  <div
                    className={`${shopifyUser ? '' : 'grid-cols-4'} grid gap-6`}
                  >
                    {shopifyUser ? (
                      <p className="font-medium text-md sm:text-lg">{name}</p>
                    ) : (
                      <Input
                        label="Name"
                        placeholder="Enter the name"
                        containerClass="col-span-full sm:col-span-2"
                        value={name}
                        onChange={(value) => {
                          setName(value)
                          if (props.setProductName) {
                            props.setProductName(value)
                          }
                        }}
                      />
                    )}
                    {!shopifyUser && (
                      <Input
                        label="Price"
                        placeholder="Enter price"
                        type="currency"
                        value={price}
                        onChange={(value) => {
                          setPrice(value)
                        }}
                        containerClass="col-span-full sm:col-span-2"
                      />
                    )}
                  </div>
                  <Checkbox
                    title="Remove availability for common bookings"
                    checked={props.removeAvailability}
                    onClick={(value) => props.setRemoveAvailability(value)}
                  />
                </form>
              </Container>
            )}

            <Container containerClass="my-6">
              <TabView
                tabs={[
                  { id: 'basic', name: 'Basic' },
                  { id: 'availability', name: 'Availability' },
                  { id: 'advanced', name: 'Advanced' },
                  { id: 'custom', name: 'Custom Questions' },
                  { id: 'workflows', name: 'Connected Workflows' },
                ]}
                selectedTab={selectedTab}
                setSelectedTab={setSelectedTab}
              />
              {selectedTab === 'basic' && (
                <form className="space-y-6">
                  <CardHeading title="Basic Configuration" />
                  <div className="grid grid-cols-4 gap-6">
                    {userData.shop.flow !== 'shopify' && (
                      <Input
                        label="Appointment Name"
                        placeholder="Enter appointment name"
                        containerClass="col-span-full sm:col-span-2"
                        value={appointmentName}
                        onChange={(value) => {
                          setAppointmentName(value)
                        }}
                      />
                    )}
                    <div className="col-span-full sm:col-span-2">
                      <p className="text-sm">
                        Appointment Duration (min : 10 mins)
                      </p>
                      <HourMinutePicker
                        onChange={setDuration}
                        value={duration}
                        hideDays
                      />
                      <p className="text-sm text-gray-500 pt-3">
                        Define the total time length for this appointment
                        service offering (e.g. 30 mins, 1 hour).
                      </p>
                    </div>
                    <div className="col-span-full sm:col-span-2">
                      <p className="text-sm">Scheduling Cutoff</p>
                      <HourMinutePicker
                        onChange={setSchedule}
                        value={schedule}
                      />
                      <p className="text-sm text-gray-500 pt-3">
                        No new bookings can be made within{' '}
                        {convertTime(schedule)} prior to the appointment start
                        time.
                      </p>
                    </div>
                  </div>
                  <div className="grid grid-cols-4 gap-6">
                    <div className="col-span-full sm:col-span-2">
                      <p className="text-sm">Time Increments</p>
                      <HourMinutePicker
                        onChange={setInterval}
                        value={interval}
                        hideDays
                      />
                      <p className="text-sm text-gray-500 pt-3">
                        For example, if a 30-minute interval is selected,
                        appointments will be shown from 10:00 to 10:30.
                      </p>
                    </div>
                    <Input
                      label="Meeting Details (Will be sent in the email) (Optional)"
                      placeholder="Enter meeting details"
                      containerClass="col-span-full sm:col-span-2"
                      value={meetingDetails}
                      onChange={(value) => {
                        setMeetingDetails(value)
                      }}
                    />
                    <Input
                      label="Note (Optional)"
                      placeholder="Enter note"
                      containerClass="col-span-full sm:col-span-2"
                      value={note}
                      onChange={(value) => {
                        setNote(value)
                      }}
                      helpText="Include any additional notes about the appointment that will be included in the confirmation email."
                    />
                    <div></div>
                    <div className="col-span-full sm:col-span-2">
                      <p className="text-sm">Add Preparation Time</p>
                      <HourMinutePicker
                        onChange={setStartTimeBuffer}
                        value={startTimeBuffer}
                        hideDays
                      />
                      <p className="text-sm text-gray-500 pt-3">
                        Specify an amount of buffer time to reserve before the
                        start of each scheduled appointment
                      </p>
                    </div>
                    <div className="col-span-full sm:col-span-2">
                      <p className="text-sm">Add Time After Booking</p>
                      <HourMinutePicker
                        onChange={setEndTimeBuffer}
                        value={endTimeBuffer}
                        hideDays
                      />
                      <p className="text-sm text-gray-500 pt-3">
                        Specify an amount of buffer time to reserve after the
                        end of each scheduled appointment
                      </p>
                    </div>
                    <Select
                      label="Timezone"
                      options={selectOptions}
                      selected={timezone}
                      setSelected={setTimezone}
                      containerClass="col-span-full sm:col-span-2"
                    />

                    {role === 'admin' && (
                      <>
                        <div className="col-span-full sm:col-span-2">
                          <Label title="Assigned Member" />
                          <ReactSelect
                            label=""
                            isMulti
                            className="basic-single mt-1"
                            classNamePrefix="select"
                            isSearchable
                            options={teamOptions}
                            onChange={(option) => {
                              if (!!option) {
                                setTeamMembers(option.map((o) => o.value))
                              } else {
                                setTeamMembers([])
                              }
                            }}
                            value={teamOptions.filter(
                              (s) => !!teamMembers.find((t) => t === s.value)
                            )}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (provided) => ({
                                ...provided,
                                zIndex: 9999,
                              }),
                            }}
                          />
                        </div>
                        {teamMembers.length > 1 && (
                          <RadioButton
                            label="Assignment Rules"
                            selected={tmAssignRule}
                            setSelected={handleTmChange}
                            data={[
                              {
                                name: 'Randomly assign team member',
                                value: 'random',
                              },
                              {
                                name: 'User Selected team member',
                                value: 'user_selected',
                              },
                            ]}
                          />
                        )}
                      </>
                    )}
                    <div className="col-span-full sm:col-span-2 mt-1">
                      <Label title="Meeting Venue / Appointment Mode" />
                      <ReactSelect
                        label=""
                        className="basic-single"
                        classNamePrefix="select"
                        isSearchable
                        options={locationOptions}
                        onChange={onChangeLocation}
                        value={locationOptions.find(
                          (s) => selectedLocation === s.value
                        )}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (provided) => ({
                            ...provided,
                            zIndex: 9999,
                          }),
                        }}
                      />

                      {(selectedLocation === 'address' ||
                        selectedLocation === 'phone') && (
                        <Input
                          id={`locationValue`}
                          label={
                            selectedLocation === 'address'
                              ? `Address(Required)`
                              : 'Phone Number(Required)'
                          }
                          onChange={(value) => {
                            setSelectedLocationValue(value)
                          }}
                          placeholder=""
                          value={selectedLocationValue || ''}
                          containerClass="col-span-full sm:col-span-2"
                        />
                      )}
                    </div>
                    <Input
                      label="Calendar Color"
                      placeholder="Enter custom calendar color"
                      containerClass="col-span-full sm:col-span-2"
                      value={color}
                      onChange={(value) => {
                        setColor(value)
                      }}
                    />
                    <Checkbox
                      title="Is a Combo Service?"
                      checked={isComboService}
                      onClick={(newChecked) => setIsComboService(newChecked)}
                    />
                    <div className="col-span-full sm:col-span-2">
                      <Select
                        label="Calendar Date/Month Start"
                        options={monthStartOtions}
                        selected={calendarStartMonthType}
                        setSelected={setCalendarStartMonthType}
                        containerClass="col-span-full sm:col-span-2"
                      />
                      <p className="text-sm text-gray-500 pt-3">
                        Select the month that you want the calendar to start
                        from
                      </p>
                      {calendarStartMonthType === 'manual' && (
                        <Select
                          label=""
                          options={dateRangeOptions}
                          selected={calendarStartMonth}
                          setSelected={setCalendarStartMonth}
                        />
                      )}
                    </div>

                    <div className="col-span-full sm:col-span-2 mb-4">
                      <RadioButton
                        label="Date Range"
                        selected={dateRangeType}
                        setSelected={(value) => {
                          setDateRangeType(value)
                        }}
                        helpText=" Specify the date range when the appointment can be booked"
                        data={[
                          {
                            name: 'X days into the future',
                            value: 'days_in_future',
                            description:
                              'Customers will only be able to schedule for the next X days into the future',
                          },
                          {
                            name: 'Within a date range',
                            value: 'date_range',
                            description:
                              'Customers will be able to see time slots only in these date range',
                          },
                          {
                            name: 'Indefinetly into the future',
                            value: 'all',
                            description:
                              'Customers will be able to see timeslots for any dates in the future',
                          },
                        ]}
                      />
                      {dateRangeType === 'days_in_future' && (
                        <div className="mt-2">
                          <Input
                            label="Days into the future"
                            onChange={(value) => {
                              setDaysInFuture(value)
                            }}
                            value={daysInFuture}
                            type="number"
                          />
                        </div>
                      )}
                      {dateRangeType === 'date_range' && (
                        <div className="mt-2">
                          <p className="text-sm">
                            Start Date:{' '}
                            {moment(startDate).format('Do, MMMM YYYY')}
                          </p>
                          <p className="text-sm">
                            End Date: {moment(endDate).format('Do, MMMM YYYY')}
                          </p>
                          <div className="my-2">
                            <RangeDatePicker
                              selected={startDate}
                              onChange={onChangeDate}
                              startDate={startDate}
                              endDate={endDate}
                              selectsRange
                              inline
                              nextMonthButtonLabel=">"
                              previousMonthButtonLabel="<"
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </form>
              )}
              {selectedTab === 'availability' && (
                <form className="space-y-6">
                  <CardHeading title="Set Your Availability" />

                  <div style={{ display: 'flex' }}>
                    <WeeklyHours
                      setWeeklyHours={setWeeklyHours}
                      weeklyHours={weeklyHours}
                      config={{}}
                      disabled={businessHours}
                    />
                    <DateOverrides
                      setOverride={setOverride}
                      override={override}
                      disabled={businessHours}
                    />
                  </div>
                </form>
              )}
              {selectedTab === 'advanced' && (
                <form className="space-y-6">
                  <CardHeading title="Advanced Rules" />

                  <div className="grid grid-cols-4 gap-6">
                    <div className="col-span-full sm:col-span-2">
                      <div>
                        <Checkbox
                          name="group-appointment"
                          title="Is Group Appointment"
                          onClick={(checked) => {
                            setGroupAppointment(checked)
                          }}
                          value={groupAppointment}
                        />
                        {groupAppointment && (
                          <>
                            <div className="grid grid-cols-4 gap-6 my-4">
                              <Input
                                label="Max people in an appointment"
                                placeholder="Enter a number"
                                containerClass="col-span-full sm:col-span-2 ease-in-out"
                                value={maxCapacity}
                                onChange={(value) => {
                                  setMaxCapacity(value)
                                }}
                                type="number"
                              />
                            </div>

                            <div className="col-span-full sm:col-span-2">
                              <div>
                                <Checkbox
                                  title="Remove Slot After First Booking"
                                  checked={removeGroupSlot}
                                  onClick={(value) => {
                                    setRemoveGroupSlot(value)
                                  }}
                                />
                                <p className="text-xs text-gray-600 pl-6 w-1/2">
                                  Check this if you want to remove the available
                                  slot after the first booking. For example if
                                  you have 5 slots and if the first booking is
                                  done for 3 slots. The slots will not be
                                  available anymore
                                </p>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>

                    <div className="col-span-full sm:col-span-2">
                      <div>
                        <Checkbox
                          name="email-reminder"
                          title="Send Email Reminder"
                          onClick={(checked) => {
                            setSendReminder(checked)
                          }}
                          value={sendReminder}
                        />
                        {sendReminder && (
                          <div className="grid grid-cols-4 gap-6 ">
                            <Input
                              label="Duration before the appointment in mins (An invitee will receive a reminder email before the scheduled appointment)"
                              placeholder="Enter a number in mins"
                              containerClass="col-span-full sm:col-span-2 ease-in-out"
                              value={reminderDuration}
                              onChange={(value) => {
                                setReminderDuration(value)
                              }}
                              type="number"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-span-full sm:col-span-2">
                      <div>
                        <Checkbox
                          name="text-reminder"
                          title="Allow Waitlist after slots are filled"
                          onClick={(newChecked) => {
                            setAllowCustomerWaitlist(newChecked)
                          }}
                          value={allowCustomerWaitlist}
                        />
                      </div>
                    </div>

                    <div className="col-span-full sm:col-span-2">
                      <div>
                        <Checkbox
                          name="text-reminder"
                          title="Send Text Reminder"
                          onClick={(checked) => {
                            setSendTextReminder(checked)
                          }}
                          value={textReminder}
                        />
                        {textReminder && (
                          <div className="grid grid-cols-4 gap-6">
                            <Input
                              label="Duration before the appointment in mins (An invitee will receive a reminder text before the scheduled appointment)"
                              placeholder="Enter a number in mins"
                              containerClass="col-span-full sm:col-span-2 ease-in-out"
                              value={textReminderDuration}
                              onChange={(value) => {
                                setTextReminderDuration(value)
                              }}
                              type="number"
                            />
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-span-full sm:col-span-2">
                      <div>
                        <Checkbox
                          name="whatsapp-reminder"
                          title="Send Whatsapp Reminder"
                          onClick={(checked) => {
                            setSendWhatsappReminder(checked)
                          }}
                          value={whatsappReminder}
                        />
                        {whatsappReminder && (
                          <div className="grid grid-cols-4 gap-6">
                            <Input
                              label="Duration before the appointment in mins (An invitee will receive a reminder email before the scheduled appointment)"
                              placeholder="Enter a number in mins"
                              containerClass="col-span-full sm:col-span-2 ease-in-out"
                              value={whatsappReminderDuration}
                              onChange={(value) => {
                                setWhatsAppReminderDuration(value)
                              }}
                              type="number"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-span-full sm:col-span-2">
                      <div>
                        <Checkbox
                          name="customer-reschedule"
                          title="Allow Customers to Reschedule"
                          onClick={(checked) => {
                            setAllowCustomerReschedule(checked)
                          }}
                          value={allowCustomerReschedule}
                        />
                        {allowCustomerReschedule && (
                          <div className="flex flex-col gap-2 my-2">
                            <p className="text-sm text-gray-600">
                              Duration before the appointment (An invitee will
                              be able to reschedule before the scheduled
                              appointment)
                            </p>
                            <HourMinutePicker
                              value={rescheduleDuration}
                              onChange={setRescheduleDuration}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-span-full sm:col-span-2">
                      <div>
                        <Checkbox
                          name="email-reminder"
                          title="Allow Customers to Cancel"
                          onClick={(checked) => {
                            setAllowCustomerCancel(checked)
                          }}
                          value={allowCustomerCancel}
                        />
                        {allowCustomerCancel && (
                          <div className="flex flex-col gap-2 my-2">
                            <p className="text-sm text-gray-600">
                              Duration before the appointment (An invitee will
                              be able to cancel before the scheduled
                              appointment)
                            </p>
                            <HourMinutePicker
                              value={cancelDuration}
                              onChange={setCancelDuration}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-span-full sm:col-span-2">
                      <Checkbox
                        name="max-event"
                        title="Enable Max Events in a day"
                        onClick={(checked) => {
                          setMaxEventsChecked(checked)
                        }}
                        value={maxEventsChecked}
                      />
                      {maxEventsChecked && (
                        <Input
                          label="Maximum number of events in a day"
                          placeholder="Enter a number"
                          containerClass="col-span-full sm:col-span-2 ease-in-out my-2"
                          value={maxEvents}
                          onChange={(value) => {
                            setMaxEvents(value)
                          }}
                          type="number"
                        />
                      )}
                    </div>
                  </div>
                </form>
              )}
              {selectedTab === 'custom' && (
                <CustomFields
                  customFields={customFields}
                  setCustomFields={setCustomFields}
                  productId={props?.match?.params?.productId ?? props.productId}
                  variantId={props?.match?.params?.id ?? props.variantId}
                  setShowPaidToast={setShowPaidToast}
                />
              )}
              {selectedTab === 'workflows' && (
                <Workflow workflows={workflows} setWorkflows={setWorkflows} />
              )}
            </Container>
          </>
        )}
      </div>
    </>
  )
}

export default EditVariant
