import CardHeading from 'Components/CardHeading'
import Container from 'Components/Container'
import DateOverrides from 'Components/DateOverrides'
import Input from 'Components/Input'
import Select from 'Components/Select'
import Spinner from 'Components/Spinner'
import WeeklyHours from 'Components/WeeklyHours'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import VariantActions from 'Redux/VariantRedux'
import { TrackEvent, selectOptions } from 'Themes/constants'
import store from 'store'
import ServiceActions from 'Redux/ServiceRedux'
import SettingsActions from 'Redux/SettingsRedux'
import Button from 'Components/Button'

const Step3 = (props) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [override, setOverride] = useState([])
  const [weeklyHours, setWeeklyHours] = useState([])
  const [duration, setDuration] = useState(30)
  const [timezone, setTimezone] = useState(selectOptions[0])
  const [meetingDetails, setMeetingDetails] = useState('')
  const [note, setNote] = useState('')
  const [schedule, setSchedule] = useState(`240`)
  const [interval, setInterval] = useState(`30`)
  const detailFetching = useSelector((state) => state.variant.detailFetching)
  const servicesFetching = useSelector((state) => state.service.listFetching)
  const detailData = useSelector((state) => state.variant.detailData)
  const [loading, setLoading] = useState(false)
  const [businessHours, setBusinessHours] = useState(false)
  const servicesList = useSelector((state) => state.service.list)
  const [productId, setProductId] = useState('')
  const [variantId, setVariantId] = useState('')
  let userData = store.get('userData')

  useEffect(() => {
    if (servicesList.length > 0) {
      setProductId(servicesList[0].id)
      setVariantId(servicesList[0].appointments[0].id)
      dispatch(
        VariantActions.variantsDetailRequest(
          servicesList[0].appointments[0].id,
          servicesList[0].id
        )
      )
    }
  }, [servicesList])

  useEffect(() => {
    dispatch(ServiceActions.servicesListRequest())
  }, [])

  useEffect(() => {
    if (!!detailData.id) {
      let appt = detailData
      setOverride(appt.overridden_availabilities)
      setWeeklyHours(appt.weekly_availabilities)
      setDuration(
        appt.appointment_config ? `${appt.appointment_config.duration}` : `30`
      )
      setSchedule(
        appt.appointment_config
          ? `${appt.appointment_config.minimum_notice}`
          : `240`
      )

      setTimezone(appt.appointment_config.timezone)

      setMeetingDetails(
        appt.appointment_config
          ? `${appt.appointment_config.meeting_detail || ''}`
          : ''
      )
      setNote(
        appt.appointment_config ? `${appt.appointment_config.note || ''}` : ''
      )
      setInterval(
        appt.appointment_config ? `${appt.appointment_config.interval}` : `30`
      )

      setBusinessHours(userData.setting.enable_global_availability)
    }
  }, [detailData])

  const onSave = () => {
    setLoading(true)
    const params = {
      id: variantId,
      productId: productId,
      availabilities: weeklyHours,
      override: override,
      fixed_appointments: [],
    }

    TrackEvent('OB Add Config', {})
    dispatch(
      VariantActions.variantsUpsertRequest(params, () => {
        setLoading(false)
        props.setStep('step4')
        dispatch(
          SettingsActions.settingsUpdateRequest(
            {
              setting: { step: 4 },
            },
            () => {}
          )
        )
      })
    )
  }

  return (
    <div>
      {detailFetching || servicesFetching ? (
        <Spinner />
      ) : (
        <>
          <Container containerClass="my-6">
            <h2 className="text-2xl font-semibold leading-7 text-gray-900 sm:text-3xl sm:truncate">
              Select Availability
            </h2>
            <form className="space-y-6 my-6">
              <div style={{ display: 'flex' }}>
                <WeeklyHours
                  setWeeklyHours={setWeeklyHours}
                  weeklyHours={weeklyHours}
                  config={{}}
                  disabled={businessHours}
                />
                <DateOverrides
                  setOverride={setOverride}
                  override={override}
                  disabled={businessHours}
                />
              </div>
            </form>
          </Container>

          <div>
            <Button
              title="Save Configuration"
              onClick={onSave}
              type="primary"
            />
          </div>
        </>
      )}
    </div>
  )
}

export default Step3
