import Button from 'Components/Button'
import Heading from 'Components/Heading'
import Toast from 'Components/Toast'
import Spinner from 'Components/Spinner'
import Table from 'Components/Table'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { CREATE_SERVICE, EDIT_SERVICE, WORKFLOWS } from 'Routes/routes'
import store from 'store'
import WorkflowActions from 'Redux/WorkflowRedux'

const Workflows = (props) => {
  const dispatch = useDispatch()
  const [workflows, setWorkflows] = useState([])
  const [showToast, setShowToast] = useState(false)
  const history = useHistory()

  let userData = store.get('userData')
  const role = userData.role

  const workflowsFetching = useSelector((state) => state.workflow.listFetching)
  const workflowsDeleteFetching = useSelector(
    (state) => state.workflow.deleteFetching
  )
  const workflowsList = useSelector((state) => state.workflow.list)

  useEffect(() => {
    dispatch(WorkflowActions.workflowsListRequest())
    dispatch(WorkflowActions.workflowsTypesListRequest())
  }, [])

  const removeWorkflow = (t) => () => {
    dispatch(
      WorkflowActions.workflowsDeleteRequest(t.id, () => {
        dispatch(WorkflowActions.workflowsListRequest())
        setShowToast(true)
      })
    )
  }

  const editWorkflow = (t) => () => {
    dispatch(WorkflowActions.workflowsKeyReset('detailData'))
    history.push(`${WORKFLOWS}/${t.id}`)
  }

  const onCreate = () => {
    dispatch(WorkflowActions.workflowsKeyReset('detailData'))
    history.push(`${WORKFLOWS}/create`)
  }

  useEffect(() => {
    let w = workflowsList.map((t) => {
      return [
        t.name,
        {
          value: (
            <div>
              {t.workflow_type.label}
              <p style={{ fontSize: 12, color: 'gray' }}>
                Time:{' '}
                {t.schedule_time > 0
                  ? `${t.schedule_time} mins`
                  : 'Immediately'}
              </p>
            </div>
          ),

          type: '',
        },
        {
          value: (
            <div>
              {t.workflow_actions.map((a) => {
                return <p>{a.workflow_action_type.label}</p>
              })}
            </div>
          ),

          type: '',
        },
        {
          value:
            t.appointments.length > 0 ? (
              <p style={{ color: 'green' }}> Active </p>
            ) : (
              <p style={{ color: 'red' }}> InActive </p>
            ),

          type: '',
        },
        {
          value: (
            <>
              <Button
                title="Edit"
                onClick={editWorkflow(t)}
                type="text"
                className="mr-4"
              />
              <Button
                title="Remove"
                onClick={removeWorkflow(t)}
                type="destructiveText"
              />
            </>
          ),
          type: 'Button',
        },
      ]
    })
    setWorkflows(w)
  }, [workflowsList])

  return (
    <div>
      <Toast
        show={showToast}
        setShow={setShowToast}
        title="Workflow deleted successfully"
      />

      <Heading
        title="Workflows"
        rightSection={
          role === 'admin' && (
            <Button title="+ Workflow" onClick={onCreate} type="primary" />
          )
        }
      />

      {workflowsFetching || workflowsDeleteFetching ? (
        <Spinner />
      ) : (
        <Table
          headers={['Name', 'When', 'Actions', 'Status', '']}
          data={workflows}
          containerClass="my-6"
        />
      )}
    </div>
  )
}

export default Workflows
