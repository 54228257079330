import React, { useState } from 'react'
import BookingActions from 'Redux/BookingRedux'
import { useDispatch } from 'react-redux'
import Modal from 'Components/Modal'

function EditCustomerPaymentModal(props) {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const sendEmail = () => {
    setLoading(true)
    dispatch(
      BookingActions.updateCustomerPaymentMethod(
        { subscription_contract_id: props.uuid },
        () => {
          setLoading(false)
          props.handleClose()
        }
      )
    )
  }

  return (
    <>
      <Modal
        open={props.showModal}
        showModal={props.showModal}
        onClose={props.handleClose}
        title="Update Payment Method"
        primaryButton={{
          title: 'Send Email',
          onClick: sendEmail,
          loading: loading,
        }}
      >
        <div style={{ padding: 20 }}>
          An email will be sent to update the customer payment method. Please
          click on Send Email.
        </div>
      </Modal>
    </>
  )
}

export default EditCustomerPaymentModal
