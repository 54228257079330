import React from 'react'
var classNames = require('classnames')

export default function Table(props) {
  let contianer = classNames(props.containerClass, 'flex flex-col')

  return (
    <div className={contianer}>
      <div className="overflow-x-auto w-full">
        <div className="py-2 align-middle inline-block min-w-full">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  {props.headers.map((h) => {
                    if (!!h) {
                      return (
                        <th
                          scope="col"
                          className="px-6 py-3 whitespace-nowrap text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          key={h && typeof h === 'object' ? h.key : h}
                        >
                          {h && typeof h === 'object' ? h.value : h}
                        </th>
                      )
                    } else {
                      return (
                        <th key={h} scope="col" className="relative px-6 py-3">
                          <span className="sr-only">Edit</span>
                        </th>
                      )
                    }
                  })}
                </tr>
              </thead>
              <tbody>
                {props.data.map((d, personIdx) => (
                  <tr
                    key={personIdx}
                    className={personIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
                  >
                    {d.map((p) => (
                      <td
                        key={p && typeof p === 'object' ? p.key : p}
                        className={`px-6 py-4 whitespace-nowrap text-sm text-gray-500 ${
                          p && p.type === 'Button'
                            ? 'flex items-center justify-end'
                            : ''
                        }`}
                      >
                        {p && typeof p === 'object' ? p.value : p}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}
