import { call, fork, all, takeLatest } from 'redux-saga/effects'

import { pathOr } from 'ramda'
import { StartupTypes } from 'Redux/StartupRedux'
import store from 'store'

export function* startup(api, action) {
  const authData = store.get('authData')
  if (!authData) {
    action.callback(false)
  } else {
    yield call(api.setAuthData, authData)
    action.callback(true)
  }
}

export default function* authSagas(api) {
  yield all([takeLatest(StartupTypes.STARTUP, startup, api)])
}
