import React, { useEffect } from 'react'
import store from 'store'
import { Crisp } from 'crisp-sdk-web'

const ChatTool = (props) => {
  useEffect(() => {
    let userData = store.get('userData')

    Crisp.configure('fc2abbb2-83cb-4ad7-876a-78015df9fd51')
    Crisp.setTokenId(userData.user_hash)
    Crisp.user.setEmail(userData.email)
    Crisp.user.setNickname(userData.name)
    Crisp.session.setData({
      app: 'Appointo Independant',
    })
  }, [])

  return null
}

export default ChatTool
