import rootSaga from '../Sagas/'
import { combineReducers } from 'redux'
import configureStore from './CreateStore'

/* ------------- Assemble The Reducers ------------- */
export const reducers = combineReducers({
  // Main Dashboard redux
  service: require('./ServiceRedux').reducer,
  variant: require('./VariantRedux').reducer,
  booking: require('./BookingRedux').reducer,
  setting: require('./SettingsRedux').reducer,
  team: require('./TeamRedux').reducer,
  workflow: require('./WorkflowRedux').reducer,
  thinkific: require('./ThinkificRedux').reducer,
  themes: require('./ThemesRedux').reducer,
  customer: require('./CustomerRedux').reducer,
  waitlist: require('./WaitlistRedux').reducer,
  navigation: require('./NavigationRedux').reducer,
})

export const { store } = configureStore(reducers, rootSaga)

export default () => {
  let finalReducers = reducers
  // If rehydration is on use persistReducer otherwise default combineReducers

  let { store, sagasManager, sagaMiddleware } = configureStore(
    finalReducers,
    rootSaga
  )

  if (module.hot) {
    module.hot.accept(() => {
      let nextRootReducer = require('./').rootReducer
      store.replaceReducer(nextRootReducer)

      const newYieldedSagas = require('../Sagas').default
      sagasManager.done.then(() => {
        sagasManager.cancel()
        sagasManager = sagaMiddleware.run(newYieldedSagas)
      })
    })
  }
  return { store }
}
