import React, { useState, useCallback, useEffect } from 'react'
import Container from 'Components/Container'
import CardHeading from 'Components/CardHeading'
import Select from 'Components/Select'
import Input from 'Components/Input'
import styles from 'Styles/Workflows.module.css'
import Spinner from 'Components/Spinner'
import Toast from 'Components/Toast'
import Heading from 'Components/Heading'
import Button from 'Components/Button'
import { useDispatch, useSelector } from 'react-redux'
import AddActionModal from 'Components/Modals/AddActionModal'
import AddTemplateModal from 'Components/Modals/AddTemplateModal'
import AllAppointmentsModal from 'Components/Modals/AllAppointmentsModal'
import WorkflowActions from 'Redux/WorkflowRedux'
import { PencilIcon, TrashIcon } from '@heroicons/react/outline'

function ManageWorkflow(props) {
  const dispatch = useDispatch()
  const [showModal, setShowModal] = useState(false)
  const [showApptModal, setShowApptModal] = useState(false)
  const [showTemplateModal, setShowTemplateModal] = useState(false)
  const [name, setName] = useState('')
  const [actionsOptions, setActionOptions] = useState([])
  const [template, setTemplate] = useState({})
  const [typeOptions, setTypeOptions] = useState([])
  const [selectedType, setSelectedType] = useState()
  const [selectedAction, setSelectedAction] = useState()
  const [currentActions, setCurrentActions] = useState([])
  const [actionTime, setActionTime] = useState(`0`)
  const [selectedAppts, setSelectedAppts] = useState([])
  const [showToast, setShowToast] = useState(false)
  const [showPaidToast, setShowPaidToast] = useState(false)

  let detailsFetching = useSelector((state) => state.workflow.detailFetching)
  let createFetching = useSelector((state) => state.workflow.createFetching)
  let workflowData = useSelector((state) => state.workflow.detailData)
  let workflowTypesData = useSelector((state) => state.workflow.typesList)

  useEffect(() => {
    if (workflowData.id) {
      setSelectedType(`${workflowData.workflow_type.id}`)
      setName(workflowData.name)
      setActionTime(`${workflowData.schedule_time}`)
      setSelectedAppts(workflowData.appointments)
      setCurrentActions(
        workflowData.workflow_actions.map((w, i) => {
          return {
            index: i + 1,
            note: w.note,
            subject: w.subject,
            action: actionsOptions.find(
              (t) => t.value === `${w.workflow_action_type.id}`
            ),
          }
        })
      )
    }
  }, [workflowData])

  useEffect(() => {
    const id = props.match.params.id
    if (id !== 'create') {
      dispatch(WorkflowActions.workflowsDetailRequest(id))
    }
    dispatch(WorkflowActions.workflowsTypesListRequest())
  }, [])

  useEffect(() => {
    if (workflowTypesData.templates) {
      let actions = workflowTypesData.workflow_action_types.map((w) => {
        return {
          label: w.label,
          value: `${w.id}`,
        }
      })
      setActionOptions(actions)
      setSelectedAction(actions[0].value)

      let types = workflowTypesData.workflow_types.map((w) => {
        return {
          label: w.label,
          value: `${w.id}`,
        }
      })

      setSelectedType(types[0].value)
      setTypeOptions(types)
    }
  }, [workflowTypesData])

  const handleSelectChange = (value) => {
    setSelectedType(value)
  }

  const chooseTemplate = (selectedAction) => {
    let tmp = workflowTypesData.templates.find(
      (t) =>
        `${t.workflow_action_type_id}` === selectedAction &&
        `${t.workflow_type_id}` === selectedType
    )

    if (!!tmp) {
      setTemplate(tmp)
    }
    setShowModal(false)
    setSelectedAction(selectedAction)
    setTimeout(() => {
      setShowTemplateModal(true)
    }, 100)
  }

  const addAction = () => {
    setShowModal(true)
  }

  const addTemplate = (subject, note, index) => {
    let actions = currentActions
    let newAction = {
      subject,
      note,
      action: actionsOptions.find((t) => t.value === selectedAction),
      index: index || currentActions.length + 1,
    }
    if (!!index) {
      actions = currentActions.filter((a) => a.index !== index)
    }
    setCurrentActions([...actions, newAction])
    setSelectedAction(actionsOptions[0].value)

    setShowTemplateModal(false)
  }

  const deleteAction = (c) => () => {
    let act = []
    act = currentActions.filter((a) => a.index !== c.index)
    setCurrentActions(act)
  }

  const editAction = (c) => () => {
    setTemplate({
      index: c.index,
      note: c.note,
      subject: c.subject,
    })
    setShowTemplateModal(true)
    setSelectedAction(c.action.value)
  }

  const saveWorkflow = () => {
    let params = {
      workflow_type_id: parseInt(selectedType, 10),
      name: name,
      schedule_time: parseInt(actionTime, 10),
      appointment_workflows: selectedAppts.map((s) => {
        return { appointment_id: parseInt(s.id, 10) }
      }),
      workflow_actions: currentActions.map((c) => {
        return {
          note: c.note,
          subject: c.subject,
          workflow_action_type_id: parseInt(c.action.value, 10),
        }
      }),
    }

    if (props.match.params.id !== 'create') {
      params.id = props.match.params.id
    }

    dispatch(
      WorkflowActions.workflowsCreateRequest(params, () => {
        setShowToast(true)
      })
    )
  }

  const selectAppointments = () => {
    setShowApptModal(true)
  }

  const addAppts = (newAppts) => {
    let mergedAppts = [...selectedAppts]
    newAppts.map((s) => {
      let apptExist = mergedAppts.find((m) => m.id === s.id)
      if (!apptExist) {
        mergedAppts.push(s)
      }
    })

    setSelectedAppts(mergedAppts)
  }

  const checkDisabled = () => {
    return !name || !selectedType || currentActions.length === 0
  }

  const removeAppt = (appt) => () => {
    let tempAppts = selectedAppts.filter((s) => s.id !== appt.id)
    setSelectedAppts(tempAppts)
  }

  let selectedTypeObject =
    typeOptions && typeOptions.find((t) => t.value === selectedType)
  console.log(selectedTypeObject)

  return (
    <>
      <Toast
        show={showToast}
        setShow={setShowToast}
        title="Workflow saved successfully"
      />

      <Toast
        show={showPaidToast}
        setShow={setShowPaidToast}
        title="Please upgrade your plan to use this feature"
        type="info"
      />

      {detailsFetching ? (
        <Spinner />
      ) : (
        <>
          <Heading
            title={
              props.match.params.id === 'create'
                ? 'Create Workflow'
                : `Edit Workflow`
            }
            backAction={{
              title: 'Workflows',
            }}
            rightSection={
              <Button
                title="Save Workflow"
                onClick={saveWorkflow}
                type="primary"
                disabled={checkDisabled()}
                loading={createFetching}
              />
            }
          />

          <Container containerClass="my-6">
            <CardHeading title="When This Happens" />
            <div className="grid grid-cols-4 gap-6 my-4">
              <Input
                label="Workflow Name"
                placeholder="Enter your workflow name"
                containerClass="col-span-full sm:col-span-2"
                value={name}
                onChange={(value) => {
                  setName(value)
                }}
              />
            </div>
            <div className="grid grid-cols-4 gap-6 my-4">
              <Select
                label="When this workflow happens"
                options={typeOptions}
                selected={selectedType}
                setSelected={handleSelectChange}
                containerClass="col-span-full sm:col-span-2"
              />
            </div>
            <div className="grid grid-cols-4 gap-6 my-4">
              <Input
                label={`How long ${
                  selectedTypeObject &&
                  selectedTypeObject.label.includes('Before')
                    ? 'before'
                    : 'after'
                } the event happens (in mins)`}
                placeholder="Enter the duration in mins"
                containerClass="col-span-full sm:col-span-2"
                value={actionTime}
                onChange={(value) => {
                  setActionTime(value)
                }}
                type="number"
              />
            </div>
          </Container>

          <Container containerClass="my-6">
            <CardHeading
              title="Do This"
              rightSection={
                <Button title="+ Add Action" onClick={addAction} type="text" />
              }
            />
            <div className={styles.questions}>
              {currentActions.map((c) => {
                return (
                  <div
                    key={c.action ? c.action.label : ''}
                    className={styles.questionsValue}
                  >
                    <div className={styles.header}>
                      <p>{c.action ? c.action.label : ''}</p>
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      <div
                        className={styles.editButton}
                        onClick={editAction(c)}
                      >
                        <PencilIcon className="w-5 " />
                      </div>
                      <div
                        className={styles.removeButton}
                        onClick={deleteAction(c)}
                      >
                        <TrashIcon className="w-5" />
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </Container>

          <Container containerClass="my-6">
            <CardHeading
              title="For These Appointments"
              rightSection={
                <Button
                  title="+ Add Appointment To Workflow"
                  onClick={selectAppointments}
                  type="text"
                />
              }
            />
            <div className={styles.questions}>
              {selectedAppts.map((appt) => {
                return (
                  <div key={appt.name} className={styles.questionsValue}>
                    <div className={styles.header}>
                      <p>{appt.name}</p>
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                      }}
                    >
                      <div
                        className={styles.removeButton}
                        onClick={removeAppt(appt)}
                      >
                        <TrashIcon className="w-5" />
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </Container>
        </>
      )}
      {showModal && (
        <AddActionModal
          showModal={showModal}
          handleClose={() => {
            setShowModal(false)
          }}
          onClick={chooseTemplate}
          actionsOptions={actionsOptions}
        />
      )}
      {showTemplateModal && (
        <AddTemplateModal
          showModal={showTemplateModal}
          handleClose={() => {
            setShowTemplateModal(false)
          }}
          onClick={addTemplate}
          template={template}
          action={actionsOptions.find((t) => t.value === selectedAction)}
        />
      )}
      {showApptModal && (
        <AllAppointmentsModal
          showModal={showApptModal}
          setShowModal={setShowApptModal}
          title={'Select Appointments'}
          selectedAppts={selectedAppts}
          onSave={addAppts}
        />
      )}
    </>
  )
}

export default ManageWorkflow
