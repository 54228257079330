import React, { useState, useCallback, useEffect } from 'react'
import Modal from 'Components/Modal'
import Toast from 'Components/Toast'
import Spinner from 'Components/Spinner'
import { useSelector, useDispatch } from 'react-redux'
import VariantActions from 'Redux/VariantRedux'
import useDebounce from 'Hooks/debounce'
import CheckBoxGroup from 'Components/ChecboxGroup'

function AllAppointmentsModal(props) {
  const dispatch = useDispatch()
  const [queryValue, setQueryValue] = useState('')
  const [products, setProducts] = useState([])
  const [loading, setLoading] = useState(false)
  const [selectedProducts, setSelectedProducts] = useState([])
  const [showActivateToast, setShowActivateToast] = useState(false)

  useEffect(() => {
    queryAppointment()
  }, [])

  const queryAppointment = (params = {}) => {
    setLoading(true)
    dispatch(
      VariantActions.variantsSearchRequest(params, (data) => {
        setProducts(data)
        setLoading(false)
      })
    )
  }

  const handleClose = useCallback(() => {
    props.setShowModal(false)
  }, [props])

  const handleSave = useCallback(() => {
    props.onSave(
      products.filter((p) => {
        return selectedProducts.includes(p.id)
      })
    )
    handleClose()
  }, [selectedProducts])

  return (
    <div>
      {showActivateToast && (
        <Toast
          content="Products activated successfully"
          onDismiss={() => setShowActivateToast(false)}
        />
      )}
      <Modal
        showModal={props.showModal}
        onClose={handleClose}
        title={props.title}
        primaryButton={{
          title: 'Save',
          onClick: handleSave,
          disabled: selectedProducts.length == 0,
        }}
        childrenClassName="p-0"
      >
        {loading ? (
          <Spinner />
        ) : products.length > 0 ? (
          <CheckBoxGroup
            selectedValues={selectedProducts}
            options={products.map((p) => {
              return { value: p.id, label: p.name }
            })}
            onChange={(values) => {
              setSelectedProducts(values)
            }}
          />
        ) : (
          <div
            style={{
              display: 'flex',
              height: 300,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <p
              style={{
                fontSize: 16,
                color: '#637381',
                paddingLeft: 20,
                paddingRight: 20,
              }}
            >
              Uh-Oh! Looks like you do not have services. Please add services
            </p>
          </div>
        )}
      </Modal>
    </div>
  )
}

export default AllAppointmentsModal
