import store from 'store'
import moment from 'moment'
import posthog from 'posthog-js'

export const selectOptions = [
  {
    label: 'Pacific Standard Time (PST)',
    value: 'America/Los_Angeles',
  },
  {
    label: 'Eastern Time (ET)',
    value: 'America/New_York',
  },
  {
    label: 'Central Time (US & Canada)',
    value: 'Central Time (US & Canada)',
  },
  {
    label: 'Mountain Time (US & Canada)',
    value: 'Mountain Time (US & Canada)',
  },
  {
    label: 'Newfoundland Time Zone',
    value: 'Newfoundland',
  },
  {
    label: 'Atlantic Time (Canada)',
    value: 'Atlantic Time (Canada)',
  },
  {
    label: 'Hawaii Standard Time',
    value: 'Hawaii',
  },
  {
    label: 'UK,Ireland,Lisbon Time',
    value: 'Europe/London',
  },
  {
    label: 'South African Standard Time',
    value: 'Africa/Johannesburg',
  },
  {
    label: 'Central European Time(CET) ',
    value: 'CET',
  },
  {
    label: 'Eastern European Time (EET)',
    value: 'EET',
  },
  {
    label: 'New Zealand Standard Time',
    value: 'Pacific/Auckland',
  },
  {
    label: 'Arabian Standard Time',
    value: 'Asia/Kuwait',
  },
  {
    label: 'Ecuador Timezone',
    value: 'America/Lima',
  },
  {
    label: 'Pakistan Standard Time (PKT)',
    value: 'Asia/Karachi',
  },
  {
    label: 'Indian Standard Time (IST)',
    value: 'Asia/Kolkata',
  },
  {
    label: 'Japan Standard Time',
    value: 'Asia/Tokyo',
  },
  {
    label: 'Singapore Time',
    value: 'Singapore',
  },
  {
    label: 'UAE Standard Time',
    value: 'Asia/Dubai',
  },
  {
    label: 'Coordinated Universal Time (UTC)',
    value: 'UTC',
  },
  {
    label: 'Australia/Adelaide',
    value: 'Australia/Adelaide',
  },
  {
    label: 'Hong Kong Time',
    value: 'Asia/Hong_Kong',
  },
  {
    label: 'Australia/Brisbane',
    value: 'Australia/Brisbane',
  },
  {
    label: 'Australia/Darwin',
    value: 'Australia/Darwin',
  },
  {
    label: 'Australia/Perth',
    value: 'Australia/Perth',
  },
  {
    label: 'Australia/Sydney',
    value: 'Australia/Sydney',
  },
  {
    label: 'Chile Standard Time',
    value: 'America/Santiago',
  },
  {
    label: 'America/Sao_Paulo',
    value: 'America/Sao_Paulo',
  },
]

export const DefaultTimeSlots = [
  {
    weekday: 'monday',
    slots: [{ start_time: '09:00AM', end_time: '05:00PM' }],
  },
  {
    weekday: 'tuesday',
    slots: [{ start_time: '09:00AM', end_time: '05:00PM' }],
  },
  {
    weekday: 'wednesday',
    slots: [{ start_time: '09:00AM', end_time: '05:00PM' }],
  },
  {
    weekday: 'thursday',
    slots: [{ start_time: '09:00AM', end_time: '05:00PM' }],
  },
  {
    weekday: 'friday',
    slots: [{ start_time: '09:00AM', end_time: '05:00PM' }],
  },
]

export const DefaultTimeSlot = { start_time: '09:00AM', end_time: '05:00PM' }

export const generateTimeOptions = (duration) => {
  var times = [] // time array
  var tt = 0 // start time
  var ap = ['AM', 'PM'] // AM-PM

  //loop to increment the time and push results in array
  for (var i = 0; tt < 24 * 60; i++) {
    var hh = Math.floor(tt / 60) // getting hours of day in 0-24 format
    var mm = tt % 60 // getting minutes of the hour in 0-55 format
    times[i] =
      ('0' + (hh % 12)).slice(-2) +
      ':' +
      ('0' + mm).slice(-2) +
      ap[Math.floor(hh / 12)] // pushing data in array in [00:00 - 12:00 AM/PM format]
    tt = tt + parseInt(duration, 10)
  }

  return times.map((t) => {
    switch (t) {
      case '00:00PM':
        return { value: t, label: '12:00PM' }

      case '00:15PM':
        return { value: t, label: '12:15PM' }

      case '00:30PM':
        return { value: t, label: '12:30PM' }

      case '00:45PM':
        return { value: t, label: '12:45PM' }

      default:
        return { value: t, label: t }
    }
  })
}

export function getParameterByName(name, url = window.location.href) {
  name = name.replace(/[\[\]]/g, '\\$&')
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

export const weekdays = ['SUN', 'MON', 'TUE', 'WED', 'THUR', 'FRI', 'SAT']

export const custWeekDays = ['DIM', 'LUN', 'MAR', 'MER', 'JEU', 'VEN', 'SAM']

export const logo_url =
  'https://appointment-booking-shopify.s3.ap-south-1.amazonaws.com/appointo_logo_no_bg.svg'

export const sumo_logo_url =
  'https://appointment-booking-shopify.s3.ap-south-1.amazonaws.com/appointo-sumo-logo.svg'

export const getInitials = () => {
  let userData = store.get('userData')
  let name = userData.name || 'Appointo'

  let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu')

  let initials = [...name.matchAll(rgx)] || []

  initials = (
    (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
  ).toUpperCase()

  return initials
}

export const getPublicUrl = () => {
  let userData = store.get('userData')
  if (!!userData) {
    console.log(
      'process.env.REACT_APP_PUBLIC_LINK',
      process.env.REACT_APP_PUBLIC_LINK
    )
    // if (!!process.env.REACT_APP_PUBLIC_LINK) {
    //   let publicLink = process.env.REACT_APP_PUBLIC_LINK
    //   let parsedUrl = publicLink.replace(
    //     '{{subdomain}}',
    //     userData.shop.subdomain
    //   )
    //   return parsedUrl
    // }
    return `${window.location.origin}/shop/${userData.shop.subdomain}`
  }
  return ''
}

export const bundleUrl = `${process.env.REACT_APP_BASE_URL}/appointo_bundle.js`

export const calendarColors = [
  '#0A1931',
  '#5F939A',
  '#343A40',
  '#DDDDDD',
  '#99154E',
  '#480032',
  '#F55C47',
  '#FAF2DA',
  '#FFC996',
  '#999B84',
  '#325288',
  '#1F441E',
  '#693C72',
  '#351F39',
  '#295939',
  '#C62A88',
  '#1AA6B7',
  '#9A1F40',
  '#9F1E49',
  '#B2085D',
  '#FB9300',
  '#A0937D',
  '#EEB76B',
  '#536162',
]

export const variablesList = [
  {
    key: '{{product_name}}',
    value: 'Service Name',
  },
  {
    key: '{{product_variant}}',
    value: 'Variant Name',
  },
  {
    key: '{{appointment_time}}',
    value: 'Appointment Time',
  },
  {
    key: '{{duration}}',
    value: 'Appointment duration',
  },
  {
    key: '{{meeting_detail}}',
    value: 'Optional Field Meeting Details',
  },
  {
    key: '{{note}}',
    value: 'Optional Field Note',
  },
  {
    key: '{{customer_name}}',
    value: 'Customer Name',
  },
  {
    key: '{{customer_email}}',
    value: 'Customer Email',
  },
  {
    key: '{{team_member_name}}',
    value: 'Team Member Name',
  },
  {
    key: '{{shipping_address}}',
    value: 'Shipping Address',
  },
  {
    key: '{{location}}',
    value: 'Location',
  },
  {
    key: '{{reschedule_link}}',
    value: 'Rescheduling link',
  },
  {
    key: '{{cancel_link}}',
    value: 'Cancellation link',
  },
  {
    key: '{{custom_fields}}',
    value: 'Custom Fields',
  },
]

export const trailExpired = () => {
  let userData = store.get('userData')
  if (userData.setting.plan !== 'free') {
    return false
  }
  return moment().isAfter(userData.shop.trial_expires_at)
}

export const locationOptions = [
  {
    label: 'None',
    value: null,
  },
  {
    label: 'Address',
    value: 'address',
  },
  {
    label: 'Phone',
    value: 'phone',
  },
  {
    label: 'Zoom',
    value: 'zoom',
  },
  {
    label: 'Google Meet',
    value: 'google_meet',
  },
]

export const TrackEvent = (eventName, properties = {}) => {
  posthog.capture(eventName, properties)
}

export function getStats(slot, style = '') {
  return typeof slot.customers_count === 'number' &&
    slot.max_capacity > 1 &&
    !slot.is_group_apt ? (
    <div
      style={{
        color: '#868383',
        fontSize: '13px',
        position: 'relative',
        top: '-4px',
        textAlign: 'center',
        ...style,
      }}
    >
      {slot.max_capacity - slot.customers_count}/{slot.max_capacity} slots
      remaining
    </div>
  ) : null
}

export const CANCELLED_SCENARIO = {
  booking_cancelled_by_admin: 'Admin',
  booking_cancelled_by_team_member: 'Team Member',
  booking_cancelled_by_customer: 'Customer',
}

export const AWS_URL =
  'https://appointment-booking-shopify.s3.ap-south-1.amazonaws.com'

var isSafari =
  /constructor/i.test(window.HTMLElement) ||
  (function (p) {
    return p.toString() === '[object SafariRemoteNotification]'
  })(
    !window['safari'] ||
      (typeof safari !== 'undefined' && window['safari'].pushNotification)
  )
function iOS() {
  return (
    [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod',
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
  )
}

export const isApple = isSafari || iOS()

export const convertTime = (num) => {
  let totalTime = parseInt(num || 10, 10)
  let d = Math.floor(totalTime / 1440)
  let h = Math.floor((totalTime - d * 1440) / 60)
  let m = Math.round(totalTime % 60)

  return `${d > 0 ? `${d} day${d > 1 ? 's' : ''}` : ''} ${
    h > 0 ? `${h} hours` : ''
  } ${m > 0 ? `${m} minutes` : ''}`
}

export const generateDateRange = () => {
  let startDate = '2023-02-01'
  let endDate = '2025-01-01'
  var start = startDate.split('-')
  var end = endDate.split('-')
  var startYear = parseInt(start[0])
  var endYear = parseInt(end[0])
  var dates = []

  for (var i = startYear; i <= endYear; i++) {
    var endMonth = i != endYear ? 11 : parseInt(end[1]) - 1
    var startMon = i === startYear ? parseInt(start[1]) - 1 : 0
    for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
      var month = j + 1
      var displayMonth = month < 10 ? '0' + month : month
      dates.push([i, displayMonth].join('-'))
    }
  }
  return dates.map((d) => ({ label: d, value: d }))
}
export const logsMap = {
  online_booking_confirmed: {
    header: 'Booking Confirmed (Online Store)',
    body: 'Booking with {{customer_email}} was confirmed.',
  },
  manual_booking_confirmed: {
    header: 'Booking Confirmed (Manual Booking)',
    body: 'Booking with {{customer_email}} was confirmed.',
  },
  skip_pay_booking_confirmed: {
    header: 'Booking Confirmed (Skip Payment)',
    body: 'Booking with {{customer_email}} was confirmed.',
  },
  admin_confirmation_email: {
    header: 'Confirmation Email Sent (Admin)',
    body: 'Confirmation email was sent successfully to {{sender_mail}}.',
  },
  team_member_confirmation_email: {
    header: 'Confirmation Email Sent (Team Member)',
    body: 'Confirmation email was sent successfully to {{sender_mail}}.',
  },
  customer_confirmation_email: {
    header: 'Confirmation Email Sent (Customer)',
    body: 'Confirmation email was sent successfully to {{sender_mail}}.',
  },
  admin_rescheduling_email: {
    header: 'Rescheduling Email Sent (Admin)',
    body: 'Rescheduling email was sent successfully to {{sender_mail}}.',
  },
  team_member_rescheduling_email: {
    header: 'Rescheduling Email Sent (Team Member)',
    body: 'Rescheduling email was sent successfully to {{sender_mail}}.',
  },
  customer_rescheduling_email: {
    header: 'Rescheduling Email Sent (Customer)',
    body: 'Rescheduling email was sent successfully to {{sender_mail}}.',
  },
  admin_reminder_email: {
    header: 'Reminder Email Sent (Admin)',
    body: 'Reminder email was sent successfully to {{sender_mail}}.',
  },
  team_member_reminder_email: {
    header: 'Reminder Email Sent (Team Member)',
    body: 'Reminder email was sent successfully to {{sender_mail}}.',
  },
  customer_reminder_email: {
    header: 'Reminder Email Sent (Customer)',
    body: 'Reminder email was sent successfully to {{sender_mail}}.',
  },
  admin_confirmation_text: {
    header: 'Confirmation Text Sent (Admin)',
    body: 'Confirmation text was sent successfully to {{phone_number}}.',
  },
  team_member_confirmation_text: {
    header: 'Confirmation Text Sent (Team Member)',
    body: 'Confirmation text was sent successfully to  {{phone_number}}.',
  },
  customer_confirmation_text: {
    header: 'Confirmation Text Sent (Customer)',
    body: 'Confirmation text was sent successfully to {{phone_number}}.',
  },
  admin_rescheduling_text: {
    header: 'Rescheduling Text Sent (Admim)',
    body: 'Rescheduling text was sent successfully to {{phone_number}}.',
  },
  team_member_rescheduling_text: {
    header: 'Rescheduling Text Sent (Team Member)',
    body: 'Rescheduling text was sent successfully to {{phone_number}}.',
  },
  customer_rescheduling_text: {
    header: 'Rescheduling Text Sent (Customer)',
    body: 'Rescheduling text was sent successfully to {{phone_number}}.',
  },
  admin_reminder_text: {
    header: 'Reminder Text Sent (Admin)',
    body: 'Reminder text was sent successfully to {{phone_number}}.',
  },
  team_member_reminder_text: {
    header: 'Reminder Text Sent (Team Member)',
    body: 'Reminder text was sent successfully to {{phone_number}}.',
  },
  customer_reminder_text: {
    header: 'Reminder Text Sent (Customer)',
    body: 'Reminder text was sent successfully to {{phone_number}}.',
  },
  admin_confirmation_whatsapp: {
    header: 'Confirmation Whatsapp Sent (Admin)',
    body: 'Confirmation whatsapp was sent successfully to {{phone_number}}.',
  },
  team_member_confirmation_whatsapp: {
    header: 'Confirmation Whatsapp Sent (Team Member)',
    body: 'Confirmation whatsapp was sent successfully to {{phone_number}}.',
  },
  customer_confirmation_whatsapp: {
    header: 'Confirmation Whatsapp Sent (Customer)',
    body: 'Confirmation whatsapp was sent successfully to {{phone_number}}.',
  },
  admin_rescheduling_whatsapp: {
    header: 'Rescheduling Whatsapp Sent (Admin)',
    body: 'Rescheduling whatsapp was sent successfully to {{phone_number}}.',
  },
  team_member_rescheduling_whatsapp: {
    header: 'Rescheduling Whatsapp Sent (Team Member)',
    body: 'Rescheduling whatsapp was sent successfully to {{phone_number}}.',
  },
  customer_rescheduling_whatsapp: {
    header: 'Rescheduling Whatsapp Sent (Customer)',
    body: 'Rescheduling whatsapp was sent successfully to {{phone_number}}.',
  },
  admin_reminder_whatsapp: {
    header: 'Reminder Whatsapp Sent (Admin)',
    body: 'Reminder Whatsapp was sent successfully to {{phone_number}}.',
  },
  team_member_reminder_whatsapp: {
    header: 'Reminder Whatsapp Sent (Team Member)',
    body: 'Reminder Whatsapp was sent successfully to {{phone_number}}.',
  },
  customer_reminder_whatsapp: {
    header: 'Reminder Whatsapp Sent (Customer)',
    body: 'Reminder Whatsapp was sent successfully to {{phone_number}}.',
  },
  booking_cancelled_by_admin: {
    header: 'Booking Cancelled By Admin',
    body: 'Booking was cancelled by admin.',
  },
  booking_cancelled_by_team_member: {
    header: 'Booking Cancelled By Team member',
    body: 'Booking was cancelled by Team member.',
  },
  booking_cancelled_by_customer: {
    header: 'Booking Cancelled By Customer',
    body: 'Booking was cancelled by customer.',
  },
  booking_team_member_reassigned: {
    header: 'Booking Team member reassigned',
    body: 'Booking was reassigned from {{previous_team_member}} to  {{team_member_name}}.',
  },
  booking_service_reassigned: {
    header: 'Booking Service reassigned',
    body: 'Booking service was reassigned from {{previous_service}}.',
  },
  calendar_confirmed: {
    header: 'Calendar Event Added',
    body: 'Event was added to the calendar link: {{calendar_url}}.',
  },
  calendar_error: {
    header: 'Calendar Error',
    body: 'Event was not added to the calendar due to an error.',
  },
  calendar_update: {
    header: 'Calendar Updated',
    body: 'Event was updated in the calendar link: {{calendar_url}}.',
  },
  booking_rescheduled_by_admin: {
    header: 'Booking Rescheduled By Admin',
    body: 'Booking was rescheduled by Admin.',
  },
  booking_rescheduled_by_team_member: {
    header: 'Booking Rescheduled By Team member',
    body: 'Booking was rescheduled by Team member.',
  },
  booking_rescheduled_by_customer: {
    header: 'Booking Rescheduled By Customer',
    body: 'Booking was rescheduled by Customer.',
  },
  booking_google_meet_error: {
    header: 'Google meet error',
    body: 'Google meet link was not created due to an error.',
  },
  zoom_confirmed: {
    header: 'Zoom link confirmed',
    body: 'Zoom link was created with link: {{location_url}}.',
  },
  zoom_error: {
    header: 'Zoom error',
    body: 'Zoom link was not created due to an error.',
  },
  booking_google_meet: {
    header: 'Google meet confirmed',
    body: 'Google meet link was created with link: {{location_url}}.',
  },
  manual_email: {
    header: 'Manual email sent',
    body: 'Manual email was sent to the customer with email {{sender_mail}}.',
  },
}
