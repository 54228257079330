import React, { useState, useCallback, useEffect } from 'react'
import Input from 'Components/Input'
import Modal from 'Components/Modal'
import { variablesList } from 'Themes/constants'

function AddTemplateModal(props) {
  const [subject, setSubject] = useState(props.template.subject || '')
  const [body, setBody] = useState(props.template.note || '')

  const onAction = () => {
    props.onClick(subject, body, props.template.index)
  }

  const isText = () => {
    return (
      props.action.label === 'Send text to customer' ||
      props.action.label === 'Send whatsapp alert to customer'
    )
  }

  return (
    <Modal
      showModal={props.showModal}
      onClose={props.handleClose}
      title="Set Email Template"
      primaryButton={{
        title: 'Done',
        onClick: onAction,
      }}
    >
      <div>
        <p style={{ marginBottom: 20 }}>
          Action:{' '}
          <span style={{ fontWeight: 'bold' }}>
            {props.action ? props.action.label : ''}
          </span>
        </p>
        {!isText() && (
          <Input
            label="Subject"
            onChange={(value) => setSubject(value)}
            value={subject}
          />
        )}

        <div style={{ marginTop: 20 }}>
          <Input
            label="Body"
            onChange={(value) => {
              if (isText()) {
                if (value.length < 100) {
                  setBody(value)
                }
              } else {
                setBody(value)
              }
            }}
            value={body}
            lines={10}
            helpText={
              isText()
                ? 'Text notifications has a character limit of 100 characters'
                : ''
            }
          />
        </div>

        <p style={{ marginTop: 20 }}>
          List of variables that can be used for this email template
        </p>
        <div
          style={{
            background: 'gainsboro',
            marginTop: 10,
            border: '1px solid gray',
            padding: 10,
            borderRadius: 5,
          }}
        >
          {variablesList.map((v) => {
            return (
              <p key={v.key} style={{ margin: '4px 0px' }}>
                <strong>{v.key}</strong> - {v.value}
              </p>
            )
          })}
        </div>
      </div>
    </Modal>
  )
}

export default AddTemplateModal
