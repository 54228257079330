/* This example requires Tailwind CSS v2.0+ */
import React, { useEffect, useState } from 'react'

export default function Translations() {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-24 px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:flex-col sm:align-center">
          <h1 className="text-5xl font-extrabold text-gray-900 sm:text-center">
            Translations
          </h1>
          <p className="mt-5 text-l text-gray-500 sm:text-center">
            Appointo supports translations in different languages. To have your
            public link translated just add ?locale=de to translate in german
            translations and also add other language codes accordingly for
            different languages
          </p>
          <p className="mt-5">
            Example url (German):
            https://dashboard.appointo.me/appointments/2ab78e7a80ce02dc3fac9185f54bbc34/product/7f6d2d5ff608958cffb55229?locale=de
          </p>
          <p className="mt-5">
            Example url (English):
            https://dashboard.appointo.me/appointments/2ab78e7a80ce02dc3fac9185f54bbc34/product/7f6d2d5ff608958cffb55229?locale=en
          </p>
          <p className="mt-4">Here is a list of Supported Codes</p>
          <ul>
            <li>de: German</li>
            <li>en: English</li>
          </ul>
        </div>
      </div>
    </div>
  )
}
