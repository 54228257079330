import PropTypes from 'prop-types'
import classNames from 'classnames'
import React from 'react'

export default function Label(props) {
  const ctClass = classNames(
    'block text-sm font-medium text-gray-700',
    props.labelClass
  )

  return (
    <label htmlFor={props.title} className={ctClass}>
      {props.title}
    </label>
  )
}

Label.propTypes = {
  labelClass: PropTypes.string,
  title: PropTypes.string,
}
