import Button from 'Components/Button'
import Input from 'Components/Input'
import Modal from 'Components/Modal'
import Select from 'Components/Select'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import store from 'store'
import styles from 'Styles/Integrations.module.css'
import axios from 'axios'
import SettingsActions from 'Redux/SettingsRedux'
import Toast from 'Components/Toast'

export const selectOptions = [
  { label: 'Australia', value: 'AU' },
  { label: 'Austria', value: 'AT' },
  { label: 'Belgium', value: 'BE' },
  { label: 'Brazil', value: 'BR' },
  { label: 'Bulgaria', value: 'BG' },
  { label: 'Canada', value: 'CA' },
  { label: 'Croatia', value: 'HR' },
  { label: 'Cyprus', value: 'CY' },
  { label: 'Czech Republic', value: 'CZ' },
  { label: 'Denmark', value: 'DK' },
  { label: 'Estonia', value: 'EE' },
  { label: 'Finland', value: 'FI' },
  { label: 'France', value: 'FR' },
  { label: 'Germany', value: 'DE' },
  { label: 'Gibraltar', value: 'GI' },
  { label: 'Greece', value: 'GR' },
  { label: 'Hong Kong', value: 'HK' },
  { label: 'Hungary', value: 'HU' },
  { label: 'India', value: 'IN' },
  { label: 'Indonesia', value: 'ID' },
  { label: 'Ireland', value: 'IE' },
  { label: 'Italy', value: 'IT' },
  { label: 'Japan', value: 'JP' },
  { label: 'Latvia', value: 'LV' },
  { label: 'Liechtenstein', value: 'LI' },
  { label: 'Lithuania', value: 'LT' },
  { label: 'Luxembourg', value: 'LU' },
  { label: 'Malaysia', value: 'MY' },
  { label: 'Malta', value: 'MT' },
  { label: 'Mexico ', value: 'MX' },
  { label: 'Netherlands', value: 'NL' },
  { label: 'New Zealand', value: 'NZ' },
  { label: 'Norway', value: 'NO' },
  { label: 'Poland', value: 'PL' },
  { label: 'Portugal', value: 'PT' },
  { label: 'Romania', value: 'RO' },
  { label: 'Singapore', value: 'SG' },
  { label: 'Slovakia', value: 'SK' },
  { label: 'Slovenia', value: 'SI' },
  { label: 'Spain', value: 'ES' },
  { label: 'Sweden', value: 'SE' },
  { label: 'Switzerland', value: 'CH' },
  { label: 'Thailand', value: 'TH' },
  { label: 'United Arab Emirates', value: 'AE' },
  { label: 'United Kingdom', value: 'GB' },
  { label: 'United States', value: 'US' },
]

const StripeModal = (props) => {
  const userData = store.get('userData')
  const [country, setCountry] = useState('US')
  const [stripeLoading, setStripeLoading] = useState(false)
  const [stripeAccountId, setStripeAccountId] = useState('')
  const [showToast, setShowToast] = useState(false)
  const [content, setContent] = useState('')

  const dispatch = useDispatch()

  const onClickStripe = async () => {
    let authData = store.get('authData')
    let url = `${process.env.REACT_APP_BASE_URL}`

    try {
      let resp = await axios.get(
        `${url}/dashboard/settings/connect_stripe_account?country=${country}`,
        {
          headers: {
            ...authData,
          },
        }
      )
      window.location.href = resp.data.url
    } catch (error) {
      setShowToast(true)
      setContent(
        'There was an error connecting stripe account. Please contact us'
      )
    }
  }

  const onDisconnectStripe = () => {
    setStripeLoading(true)
    dispatch(
      SettingsActions.disconnectStripeAccount(() => {
        store.set('userData', {
          ...userData,
          setting: {
            ...userData.setting,
            stripe_account_uuid: null,
          },
        })
        setStripeAccountId('')
      })
    )
    setStripeLoading(false)
  }

  const getStripeTitle = () => {
    let userData = store.get('userData')
    if (!userData.setting.stripe_account_uuid) {
      return 'Connect Stripe'
    }

    if (userData.setting.stripe_account_verified) {
      return 'Stripe Account Connected'
    } else {
      return 'Continue Stripe Account Connect'
    }
  }

  useEffect(() => {
    let userData = store.get('userData')
    setStripeAccountId(userData.setting.stripe_account_uuid)
  }, [])

  return (
    <>
      <Modal
        showModal={props.showModal}
        onClose={() => props.setShowModal(false)}
        title={'Connect Stripe'}
        primaryButton={{
          title: getStripeTitle(),
          onClick: onClickStripe,
          disabled: userData.setting.stripe_account_verified,
        }}
        secondaryButton={
          stripeAccountId && {
            title: 'Disconnect Stripe',
            onClick: onDisconnectStripe,
            type: 'destructive',
            loading: stripeLoading,
          }
        }
      >
        <>
          {!stripeAccountId ? (
            <Select
              label="Country"
              options={selectOptions}
              selected={country}
              setSelected={setCountry}
              containerClass="col-span-full sm:col-span-2"
            />
          ) : null}
        </>
      </Modal>
      {showToast && (
        <Toast content={content} onDismiss={() => setShowToast(false)} />
      )}
    </>
  )
}

export default StripeModal
