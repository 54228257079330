import Button from 'Components/Button'
import Heading from 'Components/Heading'
import Toast from 'Components/Toast'
import RemoveModal from 'Components/Modals/RemoveModal'
import Spinner from 'Components/Spinner'
import Table from 'Components/Table'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import TeamActions from 'Redux/TeamRedux'
import { CREATE_SERVICE, EDIT_SERVICE } from 'Routes/routes'
import AddTeamMemberModal from 'Components/Modals/AddTeamMemberModal'
import RemoveTeamMemberModal from 'Components/Modals/RemoveTeamMemberModal'
import store from 'store'
import PurchseSeatsModal from 'Components/Modals/PurchaseSeatsModal'

const Teams = (props) => {
  const dispatch = useDispatch()
  const [teams, setTeams] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [showRemoveModal, setShowRemoveModal] = useState(false)
  const [selectedMember, setSelectedMember] = useState({})
  const [showToast, setShowToast] = useState(false)
  const [showPaidToast, setShowPaidToast] = useState(false)
  const [showPurchaseModal, setShowPurchaseModal] = useState(false)
  const history = useHistory()
  const onCreate = () => {
    setShowModal(true)
  }

  let userData = store.get('userData')
  const role = userData.role

  const teamsFetching = useSelector((state) => state.team.listFetching)
  const teamsDeleteFetching = useSelector((state) => state.team.deleteFetching)
  const teamsList = useSelector((state) => state.team.list)

  useEffect(() => {
    console.log('hit here')
    dispatch(TeamActions.teamsListRequest())
  }, [])

  //   const removeService = () => {
  //     dispatch(
  //       ServiceActions.servicesDeleteRequest(selectedProductId, () => {
  //         setShowModal(false)
  //         dispatch(ServiceActions.servicesListRequest())
  //         setShowToast(true)
  //       })
  //     )
  //   }

  const getRole = (t) => {
    switch (t.role) {
      case 'admin':
        return 'Admin'
      default:
        return 'Member'
    }
  }

  const resendEmail = (t) => {
    dispatch(TeamActions.sendVerificationEmail(t.id))
    setShowToast(true)
  }

  useEffect(() => {
    let team = teamsList.map((t) => {
      return [
        t.name,
        t.email,
        getRole(t),
        {
          value: t.confirmed ? (
            <p style={{ color: 'green' }}>Verified</p>
          ) : (
            <p style={{ color: 'red' }}>Not Verified</p>
          ),

          type: '',
        },
        t.role !== 'admin' && role === 'admin'
          ? {
              value: (
                <>
                  {!t.confirmed && (
                    <Button
                      title="Resend Email"
                      onClick={() => {
                        resendEmail(t)
                      }}
                      type="text"
                      className="mr-4"
                    />
                  )}

                  <Button
                    title="Remove"
                    onClick={() => {
                      setSelectedMember(t)
                      setShowRemoveModal(true)
                    }}
                    type="destructiveText"
                  />
                </>
              ),
              type: 'Button',
            }
          : '',
      ]
    })
    setTeams(team)
  }, [teamsList])

  const purchaseSeats = () => {
    setShowPurchaseModal(true)
  }

  return (
    <div>
      <Toast
        show={showToast}
        setShow={setShowToast}
        title="Email has been sent successfully"
      />

      <Toast
        show={showPaidToast}
        setShow={setShowPaidToast}
        title="Please upgrade your plan to use this feature"
        type="info"
      />
      <Heading
        title={`Teams`}
        rightSection={
          role === 'admin' && (
            <>
              {userData.setting.plan !== 'lifetime' && (
                <div className="mr-4 mt-2">
                  Seats: {userData.shop.team_members_count} /{' '}
                  {userData.shop.seats}
                </div>
              )}

              <Button
                title="+ Purchase More Seats"
                onClick={purchaseSeats}
                type="primary"
                className="mr-4"
              />
              <Button
                title="+ Add Team Member"
                onClick={onCreate}
                type="primary"
              />
            </>
          )
        }
      />

      {teamsFetching ? (
        <Spinner />
      ) : (
        <Table
          headers={['Name', 'Email', 'Role', 'Status', '']}
          data={teams}
          containerClass="my-6"
        />
      )}

      {showModal && (
        <AddTeamMemberModal
          showModal={showModal}
          handleClose={() => {
            setShowModal(false)
          }}
          title="Add Team Member"
        />
      )}

      {showPurchaseModal && (
        <PurchseSeatsModal
          showModal={showPurchaseModal}
          handleClose={() => {
            setShowPurchaseModal(false)
          }}
          title="Purchase Seats"
        />
      )}

      {showRemoveModal && (
        <RemoveTeamMemberModal
          showModal={showRemoveModal}
          handleClose={() => {
            setShowRemoveModal(false)
          }}
          title="Remove Team Member"
          selectedMember={selectedMember}
        />
      )}
    </div>
  )
}

export default Teams
