import React from 'react'
import Select from 'Components/Select'

function SelectTeamMember(props) {
  return (
    <div className="py-2 px-1">
      <p className="mb-4">
        Please select one of the team member from the dropdown to create booking
      </p>

      <Select
        label=""
        multiple
        options={props.teamMembers}
        selected={props.selectedTeamMembers}
        setSelected={props.setSelectedTeamMembers}
      />
    </div>
  )
}

export default SelectTeamMember
