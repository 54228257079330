import Button from 'Components/Button'
import Card from 'Components/Card'
import Spinner from 'Components/Spinner'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ArrowLeftIcon } from '@heroicons/react/solid'
import CardSection from 'Components/CardSection'
import moment from 'moment'
import ConfirmationModal from 'Components/ConfirmationModal'
import EditBookingCountModal from './BookingCountModal'
import { PencilIcon, TrashIcon } from '@heroicons/react/outline'
import BookingActions from 'Redux/BookingRedux'
import EditSubscriptionModal from './EditSubscriptionModal'
import Input from 'Components/Input'
import EditCustomerPaymentModal from './EditCustomerPaymentModal'
import Table from 'Components/Table'
import Select from 'Components/Select'
import ManualSubscriptionBookingModal from './ManualSubscriptionBookingModal'
import ConnectBookingToSubscriptionModal from './ConnectBookingToSubscriptionModal'
import OrderSchedule from './OrderSchedule'
import BillingHistory from './BillingHistory'

const headings = [
  'Order No.',
  'Team Member',
  'Appointment Name',
  'Booking Time',
  'Assigned Tag',
  'Booked On',
  'Action',
]

const SubscriptionDetails = (props) => {
  const dispatch = useDispatch()
  const [subscription, setSubscription] = useState({})
  let detailsFetching = useSelector((state) => state.booking.detailFetching)
  let subscriptionData = useSelector((state) => state.booking.detailData)
  const [bookings, setBookings] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [showCancelModal, setShowCancelModal] = useState(false)
  const [showPaymentModal, setShowPaymentModal] = useState(false)
  const [showBookingCountModal, setShowBookingCountModal] = useState(false)
  const [showOrderSchedule, setShowOrderSchedule] = useState(false)
  const [showConnectModal, setShowConnectModal] = useState(false)
  const [schedule, setSchedule] = useState([])
  const [showBillingHistory, setShowBillingHistory] = useState(false)
  const [bannerMessage, setBannerMesage] = useState('')
  const [loading, setLoading] = useState(false)
  const [editEmail, setEditEmail] = useState(false)
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false)
  const [showRemoveBookingModal, setShowRemoveBookingModal] = useState(false)
  let metadata = subscriptionData.metadata
  const [currentEmail, setCurrentEmail] = useState('')
  const [customerId, setCustomerId] = useState(null)
  const [action, setAction] = useState('more_actions')

  const editBookingCount = () => {
    setShowBookingCountModal(true)
  }

  const retryPayment = () => {
    setLoading(true)
    dispatch(
      BookingActions.retrySubscriptionBilling(
        { id: subscriptionData.uuid },
        () => {
          setLoading(false)
          setBannerMesage(
            'We are retying the payment. The status will update in the next 30 minutes if the payment is sucessful. We will try to charge the card multiple times but if still fails we request you to contact customer to update their payment method'
          )
        }
      )
    )
  }

  const editSubscriptionStatus = () => {
    setShowSubscriptionModal(true)
  }

  const editCustomerEmail = () => {
    setEditEmail(true)
  }

  const saveCustomerEmail = () => {
    let params = {
      id: subscriptionData.uuid,
      email: currentEmail,
    }
    dispatch(
      BookingActions.updateSubscriptionDetails(params, () => {
        setEditEmail(false)
        dispatch(BookingActions.bookingsSubscriptionRequest(params.id))
      })
    )
  }

  const editCustomerPayment = () => {
    setShowPaymentModal(true)
  }

  const deleteBooking = (id) => {
    const customersData = subscriptionData.customers
    for (let item of customersData) {
      if (item.booking_id === id) {
        setCustomerId(item.id)
      }
    }
    setShowRemoveBookingModal(true)
  }

  const removeBooking = () => {
    if (customerId) {
      let params = {
        id: subscriptionData.uuid,
        disconnect_customer_id: customerId,
      }
      dispatch(
        BookingActions.updateSubscriptionDetails(params, () => {
          dispatch(
            BookingActions.bookingsSubscriptionRequest(subscriptionData.uuid)
          )
          setShowRemoveBookingModal(false)
        })
      )
    }
  }

  const cancelSubscription = () => {
    setLoading(true)
    dispatch(
      BookingActions.bookingsSubscriptionCancelRequest(
        subscriptionData.id,
        () => {
          setLoading(false)
          setShowCancelModal(false)
          dispatch(BookingActions.bookingsSubscriptionsListRequest())
          dispatch(
            BookingActions.bookingsSubscriptionRequest(subscriptionData.uuid)
          )
        }
      )
    )
  }

  useEffect(() => {
    if (subscriptionData.id) {
      setSubscription(subscriptionData)
      setCurrentEmail(
        !!subscriptionData.metadata && subscriptionData.metadata.customer
          ? subscriptionData.metadata.customer.email
          : ''
      )
      const bookingsData = subscriptionData.bookings
      let s = bookingsData?.map((b, i) => {
        return [
          b.order_name,
          b.team_member ? b.team_member.email : 'Admin',
          {
            value: (
              <div className="flex flex-col gap-1">
                <p className="text-sm">{b.product_name}</p>
                {b.variant_name && (
                  <p className="text-xs font-gray-300">{b.variant_name}</p>
                )}
              </div>
            ),
          },
          {
            value: (
              <>
                {b.scenario === 'multi_day' ? (
                  <div className="exclude-translation notranslate">
                    <p>{`${moment(b.start_date).format(
                      'MMM Do YYYY'
                    )} - ${moment(b.end_date).format('MMM Do YYYY')}`}</p>
                    <p className="text-xs font-gray-300">
                      Number of days: {b.quantity} days
                    </p>
                  </div>
                ) : (
                  <div className="exclude-translation notranslate">
                    <p>{moment(b.selected_time).format('llll')}</p>
                    <p className="text-xs font-gray-300">
                      Duration: {b.duration} mins
                    </p>
                  </div>
                )}
              </>
            ),
          },
          b.group ? b.group.name : '-',
          {
            value: (
              <>
                {b.created_at ? (
                  <p>{moment(b.created_at).format('ll')}</p>
                ) : (
                  '-'
                )}
              </>
            ),
          },
          {
            value: (
              <div className="w-5 h-5" onClick={() => deleteBooking(b.id)}>
                <TrashIcon className="cursor-pointer" />
              </div>
            ),
          },
        ]
      })
      setBookings(s)
    }
  }, [subscriptionData])

  useEffect(() => {
    switch (action) {
      case 'exist_booking':
        setShowConnectModal(true)
        break
      case 'manual_booking':
        setShowModal(true)
        break
      case 'order_schedule':
        setSchedule(subscriptionData.schedule)
        setShowOrderSchedule(true)
        break
      case 'billing_history':
        setShowBillingHistory(true)
        break
    }
  }, [action])

  return (
    <>
      <div className="bg-white py-10 px-5 rounded-md">
        <div className="flex sm:items-center justify-between mb-5">
          <div className="flex gap-3">
            <button
              onClick={props.handleBack}
              className="flex items-center justify-center h-5 w-5"
            >
              <ArrowLeftIcon className="h-5 w-5" />
            </button>
          </div>
          <div className="flex gap-2 flex-col sm:flex-row items-center">
            <Select
              label=""
              options={[
                { label: 'Manual Booking', value: 'manual_booking' },
                {
                  label: 'Connect Existing Booking',
                  value: 'exist_booking',
                },
                // {
                //   label: 'Order Schedule',
                //   value: 'order_schedule',
                // },
                // {
                //   label: 'Billing History',
                //   value: 'billing_history',
                // },
              ]}
              selected={action}
              setSelected={(value) => {
                setAction(value)
              }}
            />
            {(subscriptionData.status === 'ACTIVE' ||
              subscriptionData.status === 'PAYMENT_DUE') && (
              <Button
                type="destructive"
                title="Cancel Subscription"
                onClick={() => setShowCancelModal(true)}
              />
            )}
          </div>
        </div>
        {detailsFetching ? (
          <Spinner />
        ) : (
          <>
            <div className="flex flex-col gap-5">
              <div className="grid grid-cols-12 gap-4 justify-between mb-4">
                <div className="col-span-12 sm:col-span-6">
                  <Card title="Subscription Details">
                    <div className="flex flex-col gap-3">
                      <CardSection title="Service Name">
                        <p className="text-sm">
                          {!!metadata &&
                          metadata.lines &&
                          metadata.lines.length > 0
                            ? `${metadata.lines[0].title} - ${
                                metadata.lines[0].variantTitle || ''
                              }`
                            : ''}
                        </p>
                      </CardSection>
                      <CardSection title="Frequency">
                        <p>
                          {!!metadata && metadata.billingPolicy
                            ? `Every ${metadata.billingPolicy.intervalCount} ${metadata.billingPolicy.interval}`
                            : 'Fixed'}
                        </p>
                      </CardSection>
                      <CardSection title="Booking Count">
                        <p>
                          <span style={{ display: 'flex' }}>
                            {subscriptionData.curr_booking_count}/
                            {subscriptionData.max_booking_count}{' '}
                            <div
                              style={{
                                display: 'flex',
                                width: 14,
                                marginLeft: 8,
                                cursor: 'pointer',
                                marginTop: 3,
                              }}
                              onClick={editBookingCount}
                            >
                              <PencilIcon />
                            </div>
                          </span>
                        </p>
                      </CardSection>
                      <CardSection title="Quantity">
                        {!!metadata && metadata.lines
                          ? metadata.lines.length
                          : 1}
                      </CardSection>
                      <CardSection title="Billing Period">
                        <p>
                          {!!subscriptionData &&
                            !!subscriptionData.prev_billing_date &&
                            `${moment(
                              subscriptionData.prev_billing_date
                            ).format('LLL')} - `}
                          {!!metadata && !!metadata.nextBillingDate
                            ? moment(subscriptionData.next_billing_date).format(
                                'LLL'
                              )
                            : '-'}
                        </p>
                      </CardSection>
                      {subscriptionData?.subscription_type === 'fixed' && (
                        <CardSection title="Expires on">
                          <p>
                            {!!subscriptionData &&
                            !!subscriptionData.expiration_date
                              ? moment(subscriptionData.expiration_date).format(
                                  'DD MMM YYYY'
                                )
                              : 'Never'}
                          </p>
                        </CardSection>
                      )}
                      <CardSection title="Status">
                        <p
                          style={{
                            color: subscriptionData.archived
                              ? 'black'
                              : subscriptionData.status === 'ACTIVE'
                              ? 'green'
                              : 'red',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          {subscriptionData.archived ? (
                            'ARCHIVED'
                          ) : subscriptionData.status !== 'PAYMENT_DUE' ? (
                            subscriptionData.status
                          ) : (
                            <span>
                              {subscriptionData.status}{' '}
                              <span style={{ marginLeft: 6 }}>
                                <Button
                                  loading={loading}
                                  onClick={retryPayment}
                                  variant="tertiary"
                                >
                                  Retry Payment
                                </Button>
                              </span>
                            </span>
                          )}
                          {(subscriptionData.status === 'ACTIVE' ||
                            subscriptionData.status === 'ARCHIVED') && (
                            <span
                              style={{
                                display: 'flex',
                                width: 14,
                                marginLeft: 8,
                                cursor: 'pointer',
                                marginTop: 3,
                              }}
                              onClick={editSubscriptionStatus}
                            >
                              <PencilIcon />
                            </span>
                          )}
                        </p>
                      </CardSection>
                    </div>
                  </Card>
                </div>
                <div className="col-span-12 sm:col-span-6">
                  <Card title="Customer Details">
                    <div className="flex flex-col gap-3">
                      <CardSection title="Customer Name">
                        <p>
                          {!!metadata && metadata.customer
                            ? `${metadata.customer.firstName} - ${metadata.customer.lastName}`
                            : ''}
                        </p>
                      </CardSection>
                      <CardSection title="Customer Email">
                        {editEmail ? (
                          <div className="flex gap-2">
                            <div className="w-full">
                              <Input
                                label=""
                                onChange={(value) => setCurrentEmail(value)}
                                value={currentEmail}
                              />
                            </div>
                            <Button
                              title="Save"
                              type="primary"
                              onClick={saveCustomerEmail}
                            />
                          </div>
                        ) : (
                          <span style={{ display: 'flex' }}>
                            {!!metadata && metadata.customer
                              ? `${metadata.customer.email}`
                              : ''}
                            {!!currentEmail && (
                              <div
                                style={{
                                  display: 'flex',
                                  width: 14,
                                  marginLeft: 10,
                                  cursor: 'pointer',
                                }}
                                onClick={editCustomerEmail}
                              >
                                <PencilIcon />
                              </div>
                            )}
                          </span>
                        )}
                      </CardSection>
                      <CardSection title="Payment Method">
                        <p>
                          {!!metadata &&
                          metadata.customerPaymentMethod &&
                          metadata.customerPaymentMethod.instrument ? (
                            <span style={{ display: 'flex' }}>
                              {
                                metadata.customerPaymentMethod.instrument
                                  .maskedNumber
                              }
                              <div
                                style={{
                                  display: 'flex',
                                  width: 14,
                                  marginLeft: 10,
                                  cursor: 'pointer',
                                }}
                                onClick={editCustomerPayment}
                              >
                                <PencilIcon />
                              </div>
                            </span>
                          ) : (
                            ''
                          )}
                        </p>
                      </CardSection>
                      {subscriptionData.customer_portal_link && (
                        <CardSection title="Customer Portal Link">
                          <a
                            className="text-sm text-blue-700 underline"
                            href={subscriptionData.customer_portal_link}
                            target="_blank"
                          >
                            View
                          </a>
                        </CardSection>
                      )}
                    </div>
                  </Card>
                </div>
              </div>
              <Card title="Booking Details">
                <Table headers={headings} data={bookings} />
              </Card>
            </div>
          </>
        )}
      </div>
      {showCancelModal && (
        <ConfirmationModal
          title={'Cancel Subscription'}
          showModal={showCancelModal}
          onClose={() => setShowCancelModal(false)}
          buttonText="Yes, Cancel"
          loading={loading}
          onClick={cancelSubscription}
          text="Are you sure, you want to cancel subscription? This cannot be undone!"
        />
      )}
      {showBookingCountModal && (
        <EditBookingCountModal
          showModal={showBookingCountModal}
          setShowModal={setShowBookingCountModal}
          handleClose={() => {
            setShowBookingCountModal(false)
          }}
          id={subscriptionData.uuid}
          bookingCount={subscriptionData.max_booking_count}
          subscriptionType={subscriptionData.subscription_type}
        />
      )}
      {showSubscriptionModal && (
        <EditSubscriptionModal
          showModal={showSubscriptionModal}
          setShowModal={setShowSubscriptionModal}
          handleClose={() => {
            setShowSubscriptionModal(false)
          }}
          id={subscriptionData.uuid}
          subscriptionType={subscriptionData.subscription_type}
          subscriptionStatus={subscriptionData.status}
        />
      )}
      {showPaymentModal && (
        <EditCustomerPaymentModal
          showModal={showPaymentModal}
          setShowModal={setShowPaymentModal}
          handleClose={() => {
            setShowPaymentModal(false)
          }}
          uuid={subscriptionData.id}
        />
      )}
      {showRemoveBookingModal && (
        <ConfirmationModal
          title="Delete Booking"
          showModal={showRemoveBookingModal}
          onClose={() => setShowRemoveBookingModal(false)}
          loading={loading}
          onClick={removeBooking}
          text="Are you sure you want to delete this booking?"
          buttonText="Yes, Delete"
        />
      )}
      {showModal && (
        <ManualSubscriptionBookingModal
          showModal={showModal}
          setShowModal={setShowModal}
          title="Book Time"
          connectedVariants={
            subscription && subscription.appointments
              ? subscription.appointments
              : []
          }
          shop={props.shop}
          id={subscription && subscription.id ? subscription.id : ''}
        />
      )}
      {showConnectModal && (
        <ConnectBookingToSubscriptionModal
          showModal={showConnectModal}
          setShowModal={setShowConnectModal}
          title="Connect booking"
          email={
            !!metadata && metadata.customer ? `${metadata.customer.email}` : ''
          }
          id={subscriptionData.uuid}
        />
      )}
      {showOrderSchedule && (
        <OrderSchedule
          showModal={showOrderSchedule}
          setShowModal={setShowOrderSchedule}
          schedule={!schedule ? schedule : []}
        />
      )}
      {showBillingHistory && (
        <BillingHistory
          showModal={showBillingHistory}
          setShowModal={setShowBillingHistory}
          billingHistory={
            !!subscriptionData.sub_billing_histories
              ? subscriptionData.sub_billing_histories
              : []
          }
        />
      )}
    </>
  )
}

export default SubscriptionDetails
