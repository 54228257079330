import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import Button from 'Components/Button'
import PropTypes from 'prop-types'
import React, { Fragment, useRef } from 'react'

export default function Modal(props) {
  const cancelButtonRef = useRef(null)
  return (
    <Transition.Root show={props.showModal} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={props.showModal}
        onClose={props.onClose}
      >
        <div
          className="flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center"
          id="modal-example-regular"
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={`relative w-auto my-6 mx-auto w-5/6 sm:${
                props.fullWidth ? 'w-full' : 'w-3/6'
              }`}
              style={{
                maxHeight: `${props.fullWidth ? '100vh' : '90vh'}`,
                height: props.fullWidth ? '100vh' : 'auto',
              }}
            >
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <div className="flex items-start justify-between p-5 border-b border-solid border-gray-200 rounded-t">
                  <h3 className="text-3xl font-semibold">{props.title}</h3>
                  {!!props.headerSection ? props.headerSection : null}
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-gray-300 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={props.onClose}
                    ref={cancelButtonRef}
                  >
                    <span className="bg-transparent h-6 w-6 text-2xl block outline-none focus:outline-none">
                      <XIcon className="w-6" />
                    </span>
                  </button>
                </div>

                <div
                  className={`${
                    props.childrenClassName || 'p-6'
                  } relative flex-auto `}
                >
                  {props.children}
                </div>

                {(props.primaryButton || props.secondaryButton) && (
                  <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse rounded-b border-t border-solid border-gray-200">
                    {props.primaryButton && (
                      <Button
                        title={props.primaryButton.title}
                        onClick={props.primaryButton.onClick}
                        type={props.primaryButton.type || 'primary'}
                        disabled={props.primaryButton.disabled}
                        loading={props.primaryButton.loading}
                      />
                    )}
                    {props.secondaryButton && (
                      <div className="mr-4">
                        <Button
                          title={props.secondaryButton.title}
                          onClick={props.secondaryButton.onClick}
                          type={props.secondaryButton.type || 'default'}
                          disabled={props.secondaryButton.disabled}
                          loading={props.secondaryButton.loading}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </Transition.Child>
        </div>

        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  )
}

Modal.propTypes = {
  children: PropTypes.any,
  onClose: PropTypes.func,
  primaryButton: PropTypes.shape({
    onClick: PropTypes.func,
    title: PropTypes.string,
    type: PropTypes.string,
  }),
  secondaryButton: PropTypes.shape({
    onClick: PropTypes.func,
    title: PropTypes.string,
    type: PropTypes.string,
  }),
  title: PropTypes.string,
}
