import React, { useEffect, useRef } from 'react'
import Litepicker from 'litepicker'
import 'litepicker/dist/css/litepicker.css'
import Button from 'Components/Button'

const RangeDatePicker = ({
  selected,
  onChange,
  startDate,
  endDate,
  nextMonthButtonLabel,
  previousMonthButtonLabel,
  inline = true,
  submit = false,
  onSubmit = {},
}) => {
  const pickerRef = useRef(null)

  useEffect(() => {
    const picker = new Litepicker({
      element: pickerRef.current,
      startDate: startDate,
      endDate: endDate,
      inlineMode: inline,
      singleMode: false,
      buttonText: {
        nextMonth: nextMonthButtonLabel,
        previousMonth: previousMonthButtonLabel,
      },
      setup: (picker) => {
        picker.on('selected', (date1, date2) => {
          const dates = [date1.dateInstance, date2 ? date2.dateInstance : null]
          onChange(dates)
        })
      },
    })

    return () => picker.destroy()
  }, [
    startDate,
    endDate,
    onChange,
    nextMonthButtonLabel,
    previousMonthButtonLabel,
    inline,
  ])

  return (
    <div className={submit ? 'bg-white' : ''}>
      <div id="litepicker" ref={pickerRef}></div>

      {submit && <Button title="Apply" onClick={onSubmit} type="default" />}
    </div>
  )
}

export default RangeDatePicker
