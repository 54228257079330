import React, { useState, useCallback, useEffect } from 'react'
import { weekdays } from 'Themes/constants'
import CheckBoxGroup from 'Components/ChecboxGroup'
import Modal from 'Components/Modal'

let filteredWeekday = weekdays.map((w, i) => {
  return { value: i, name: w }
})
function CopyHoursModal(props) {
  const [selectedDays, setSelectedDays] = useState([])

  const handleClose = useCallback(() => {
    props.setShowModal(false)
  }, [props])

  const onClick = () => {
    let days = filteredWeekday.filter((f) => selectedDays.includes(f.value))
    props.onCopy(days)
  }

  useEffect(() => {
    setSelectedDays([])
  }, [props.showModal])

  return (
    <div>
      <Modal
        showModal={props.showModal}
        onClose={handleClose}
        title={props.title}
        primaryButton={{
          title: 'Copy',
          onClick: onClick,
        }}
        childrenClassName="p-0"
      >
        <div style={{ maxHeight: '60vh' }}>
          <CheckBoxGroup
            selectedValues={selectedDays}
            options={filteredWeekday.map((p) => {
              return { value: p.value, label: p.name }
            })}
            onChange={(values) => {
              setSelectedDays(values)
            }}
          />
        </div>
      </Modal>
    </div>
  )
}

export default CopyHoursModal
