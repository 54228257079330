import React, { useState, useCallback, useEffect } from 'react'
import Modal from 'Components/Modal'
import TeamActions from 'Redux/TeamRedux'
import { useDispatch, useSelector } from 'react-redux'

function RemoveTeamMemberModal(props) {
  const dispatch = useDispatch()

  const deleteFetching = useSelector((state) => state.team.deleteFetching)

  const confirmRemove = () => {
    dispatch(
      TeamActions.teamDeleteRequest(props.selectedMember.id, () => {
        props.handleClose()
        dispatch(TeamActions.teamsListRequest())
      })
    )
  }

  return (
    <div>
      <Modal
        showModal={props.showModal}
        onClose={props.handleClose}
        title={props.title}
        primaryButton={{
          title: 'Yes, Remove',
          type: 'destructive',
          onClick: confirmRemove,
          loading: deleteFetching,
        }}
      >
        Are you sure you want to remove this team member? You cannot undo this
        action
      </Modal>
    </div>
  )
}

export default RemoveTeamMemberModal
