import React from 'react'
import styles from 'Styles/ManualBookingModal.module.css'
import Checkbox from 'Components/Checkbox'

function SelectConnectedVariants({
  selectedVariant,
  setSelectedVariant,
  variants,
  setSelectedProduct,
}) {
  const onSelectionChange = (item) => (value) => {
    if (value) {
      setSelectedVariant(item)
      setSelectedProduct(item.product_detail)
    } else {
      setSelectedVariant({})
      setSelectedProduct({})
    }
  }

  return (
    <div style={{ maxHeight: '60vh' }}>
      <div className="rounded-md p-4 flex flex-col gap-4 border">
        <p className="font-gray-200 text-sm">
          Select one of the connected variant for which you want to book
        </p>
        {variants.map((item) => {
          const { id, name } = item
          return (
            <div className={styles.resourceItemContainer}>
              <div className={styles.checkBox}>
                {(!selectedVariant.id || selectedVariant.id === id) && (
                  <Checkbox
                    title=""
                    onClick={onSelectionChange(item)}
                    value={selectedVariant.id === id}
                  />
                )}
              </div>

              <div className={styles.textContainer}>
                <h3 className="text-md font-medium">{name}</h3>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default SelectConnectedVariants
