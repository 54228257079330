import React, { useState, useCallback, useEffect } from 'react'
import Modal from 'Components/Modal'
import Select from 'Components/Select'

function AddActionModal(props) {
  const [selectedAction, setSelectedAction] = useState()
  const [actionOptions, setActionOptions] = useState([])
  const handleSelectChange = (value) => {
    setSelectedAction(value)
  }

  useEffect(() => {
    setActionOptions(props.actionsOptions)
  }, [props.actionsOptions])

  const onAction = () => {
    props.onClick(selectedAction || props.actionsOptions[0].value)
  }

  return (
    <div>
      <Modal
        showModal={props.showModal}
        onClose={props.handleClose}
        title={'Add Action'}
        primaryButton={{
          title: 'Next',
          onClick: onAction,
        }}
      >
        <Select
          label="Select one action to perform"
          options={actionOptions}
          setSelected={handleSelectChange}
          selected={selectedAction}
          containerClass="col-span-full sm:col-span-2"
        />
      </Modal>
    </div>
  )
}

export default AddActionModal
