import React, { useState, useCallback, useEffect } from 'react'
import Modal from 'Components/Modal'
import Input from 'Components/Input'
import { useDispatch, useSelector } from 'react-redux'
import store from 'store'
import SettingsActions from 'Redux/SettingsRedux'

function PurchseSeatsModal(props) {
  const dispatch = useDispatch()
  const [seats, setSeats] = useState('')
  const [loading, setLoading] = useState(false)

  const purchase = () => {
    setLoading(true)
    const settingsData = store.get('userData')
    dispatch(
      SettingsActions.settingsPlanUpgradeRequest(
        { name: settingsData.setting.plan, team_members: seats },
        (data, shop) => {
          window.location.href = data.url
        }
      )
    )
  }

  return (
    <div>
      <Modal
        showModal={props.showModal}
        onClose={props.handleClose}
        title={props.title}
        primaryButton={{
          title: 'Save',
          onClick: purchase,
          loading: loading,
        }}
      >
        <div className=" grid grid-cols-9 gap-y-6 gap-x-4 mb-4">
          <Input
            id={`seats`}
            label="Number of seats"
            onChange={(value) => {
              setSeats(value)
            }}
            value={seats}
            placeholder="Enter seats"
            containerClass="col-span-4"
            type="number"
          />
        </div>
      </Modal>
    </div>
  )
}

export default PurchseSeatsModal
